import React, { useState, useEffect } from "react";
import NavbarPage from "../../home/Navbar/Navbar_Page";
import './Register.css'
import { TextField, Button, Typography } from '@material-ui/core';
import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Spinner } from 'reactstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { getBusinessCategories } from "../../business/BusinessSlice";

import { Helmet } from 'react-helmet' 
import { sendScreenView ,sendEvent} from '../../Utils'


function getSteps() {
  return ['Step 1', 'Step 2', 'Step 3', 'Step 4'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Personal Detail';
    case 1:
      return 'Are you an Employee?';
    case 2:
      return 'Company Details';
    case 3:
      return 'Set Password';
  }
}

const Register = () => {
  const [userInfo, setUserInfo] = useState({
    email: null,
    password: null,
    confirm_password: null,
    abn: null,
    type: 'employee',
    firstName: null,
    lastName: null
  });

  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = React.useState(0);
  const [errors, setErrors] = React.useState({});
  const [isLoading, setLoading] = React.useState(false);
  
  const business = useSelector((state) => state.business);
  const { categoryList, loading, error } = business;
  const [ tempCategoryList, setTempCategoryList] = useState([]);

  const [ category, setCategory ] = useState('')

  useEffect(() => {
    dispatch(getBusinessCategories());
  }, []);

  useEffect(() => {
    if(categoryList.length > 0) {
      setTempCategoryList(categoryList)
    }
  }, [categoryList]);

  const steps = getSteps();

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return false;
  };

  const handleAutoCompleteTyping = (e) => {
    const { id, value } = e.target;
    if(value && value.length > 0 && !categoryList.find(f => f.name.toLowerCase().startsWith(value))){
      setTempCategoryList([{id: -1, name: value}].concat(categoryList))
    }
    setCategory(value)
  };

  const handleAutoCompleteCategoryPicked = (e, value) => {
    setCategory(value)
  }

  const handleNext = async () => {
    setErrors({})
    setLoading(true)
    sendScreenView(`Register - ${activeStep}`)
    let nErrors = await validate(activeStep)
    setErrors(nErrors)
    
    if(!Object.keys(nErrors).length){
      var shouldProceed = true
      if( activeStep === 3 ) {
        shouldProceed = await register()
      }
      if(shouldProceed) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setLoading(false)
  };

  const register = async ()=> {
    try {
      let response = await Axios.post("/api/auth/register", {...userInfo, 'category' : category});
      sendEvent('sign_up')
      return response.data != null
    } catch (error) {
      setErrors({ ...errors, 'register' : error.response && error.response.data && error.response.data.length > 0 ? error.response.data[0].msg :  error.message })
      return false
    }
  }

  const validate = async (step) => {
    //console.log(step)
    //console.log(userInfo)
    let errors = {}
    if(step === 0){
      if(!userInfo.firstName || userInfo.firstName.length === 0){
        errors['firstName'] = "Invalid first name"
      }
      if(!userInfo.lastName || userInfo.lastName.length === 0){
        errors['lastName'] = "Invalid last name"
      }
      if( !userInfo.email || !(isEmail(userInfo.email))) {
        errors['email'] = "Invalid email"
      }
    }

    if(step === 2){
      if(userInfo.type === 'owner'){
        if(!category || category.length == 0){
          errors['category'] = "Invalid Category"
        }

          if(!userInfo.abn || userInfo.abn.trim().length === 0 || !isABN(userInfo.abn)){
            errors['abn'] = "Invalid ABN"
          }else {
            try {
              let response = await Axios.get("/api/public/checkForABN", {
                params: { abn : userInfo.abn}
              });
              if(response && response.data && response.data.exists != null){
                if(response.data.exists){
                  if(response.data.ownerExists){
                    errors['abn'] = "Company is already registered. Please register as admin or employee"
                  }
                }
              } else {
                errors['abn'] = "ABN or Company ID Verification failed"
              }
            } catch (error) {
              if(error.response && error.response.data){
                errors['abn'] = error.response.data.error
              } else {
                errors['abn'] = "ABN or Company ID Verification failed"
              }
            }
          }
      } else {
        try {
          if(!userInfo.abn || userInfo.abn.trim().length === 0){
            errors['abn'] = "Invalid ABN"
          }else {
            let response = await Axios.get("/api/public/checkForABN", {
              params: { abn : userInfo.abn}
            });
            if(!response || !response.data.exists) {
              errors['abn'] = "Could not find your Company. Make sure your employer is registered with us."
            } else if (!response.data.ownerExists){
              errors['abn'] = "Could not find your Company. Make sure your employer is registered with us."
            }
          }
        } catch (error) {
            console.log(error)
            errors['abn'] = "Could not find your Company. Make sure your employer is registered with us."
        }
      } 
    }

    if( step === 3 ){
      if(!userInfo.password || userInfo.password.length === 0){
        errors['password'] = "Invalid Password"
      }
      if(!userInfo.confirm_password || userInfo.confirm_password.length === 0){
        errors['confirm_password'] = "Invalid Password"
      }
      console.log(`${userInfo.confirm_password} != ${userInfo.password} = ${userInfo.confirm_password != userInfo.password}`)
      if( userInfo.confirm_password != userInfo.password ){
        errors['confirm_password'] = "Password don't match"
      }
    }
    return errors
  }

  const isABN = (value) => {

    var weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19],
      abn = value.replace(/[^\d]/g, ''),
      result = false;
  
    // check length is 11 digits
    if (abn.length === 11) {
  
      // apply ato check method
      var sum = 0,
        weight;
  
      for (var index = 0; index <= weights.length - 1; index++) {
        weight = weights[index];
        var digit = abn[index] - (index ? 0 : 1);
        sum += weight * digit;
      }
  
      result = sum % 89 === 0;
    }
    return result;
  }

  const isEmail = (email)=>{
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const infoChanged = (e) => {
    const { id, value } = e.target;

    setUserInfo((currentState) => ({
      ...currentState,
      [id]: value,
    }));
    setErrors({})
  }

  const onTypeChanged = (e) => {
    setUserInfo({ ...userInfo, type: e.target.value})
  }

  return (    
  <React.Fragment>
    <Helmet>
      <title>Register</title>
    </Helmet>
    <NavbarPage navItems={[
            { id: 1, idnm: "home", navheading: "Home", link: "/", isSelected: false}
        ]} navClass={document.documentElement.scrollTop} imglight={false}/>
        <div>
          <div className="cb-container">
            <Stepper activeStep={activeStep} id="register-stepper" className='mb-4'>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = <Typography variant="caption">Optional</Typography>;
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Grid container justify="center" spacing={2} className="mt-4 mb-4">
              {activeStep === 0 && <div className="register-vertical">
                <Typography variant="h6" component="h6" className="mb-2">1. You Details</Typography>
                <Paper className="register-paper">
                  <div className="register-vertical ml-5 mr-5 pt-5">
                    <TextField value={userInfo.firstName} error={errors.firstName != null} helperText={errors.firstName} className="mt-3" id="firstName" label="First Name" variant="outlined" onChange={infoChanged} />
                    <TextField value={userInfo.lastName} error={errors.lastName != null} helperText={errors.lastName} className="mt-3"  id="lastName" label="Last Name" variant="outlined" onChange={infoChanged} />
                    <TextField value={userInfo.email} error={errors.email != null} helperText={errors.email} className="mt-3"  id="email" label="Business Email" variant="outlined" onChange={infoChanged} />
                  </div>
                </Paper>
              </div> }
              {activeStep === 1 && <div className="register-vertical">
              <Typography variant="h6" component="h6" className="mb-2">2. You are registering as</Typography>
                <Paper className="register-paper">
                    <div className="paper-center">
                      <RadioGroup aria-label="gender" name="gender1" value={userInfo.type} onChange={onTypeChanged}>
                        <FormControlLabel value="owner" control={<Radio />} label="Owner of a Company" />
                        <FormControlLabel value="admin" control={<Radio />} label="Admin of a Company" />
                        <FormControlLabel value="employee" control={<Radio />} label="Employee of a Company" />
                      </RadioGroup>
                    </div>
                  </Paper>
              </div> }
              {activeStep === 2 && <div className="register-vertical">
                <Typography variant="h6" component="h6" className="mb-2">3. You Company Details</Typography>
                <Paper className="register-paper">
                    <div className="register-vertical  ml-5 mr-5">
                      <TextField error={errors.abn != null} helperText={errors.abn} className="mt-5" id="abn" label={userInfo.type === 'owner' ? "ABN"  : "ABN / Company ID"} variant="outlined" value={userInfo.abn} onChange={infoChanged} />
                      {userInfo.type != 'owner' && <p className="small-font mt-2">If your Employer is registerd with Invoice Scanner, they can provide you with Company Id</p> }
                      { userInfo.type === 'owner' &&
                      <Autocomplete
                      className="pt-4"
                      id="combo-box-demo"
                      options={tempCategoryList}
                      getOptionLabel={(option) => option.name}
                      onChange={handleAutoCompleteCategoryPicked}
                      renderInput={(params) => 
                        <TextField  
                        error={errors.category != null}
                        helperText={errors.category}
                        onChange={handleAutoCompleteTyping} 
                        {...params} 
                        label="Business Category" 
                        variant="outlined" />
                      }
                    />
                  }
                  {userInfo.type === 'owner' && <p className="small-font mt-2">You can enter a category that is not listed as well.</p> }

                    </div>
                  </Paper>
              </div> }
              {activeStep === 3 && <div className="register-vertical">
                <Typography variant="h6" component="h6" className="mb-2">4. Set Passowrd</Typography>
                  <Paper className="register-paper">
                    <div className="register-vertical  ml-5 mr-5">
                      <TextField error={errors.password != null} helperText={errors.password} className="mt-5" id="password" label="Password" variant="outlined" type="password" onChange={infoChanged} />
                      <TextField error={errors.confirm_password != null} helperText={errors.confirm_password} className="mt-5" id="confirm_password" label="Confirm Password" variant="outlined" type="password" onChange={infoChanged} />
                      {errors.register && <div><p className='text-danger mt-4'>{errors.register}</p></div>}
                   </div>
                  </Paper>
              </div> }
              {activeStep === 4 && <div className="register-vertical">
                <Typography variant="h6" component="h6" className="mb-2">Almost Complete</Typography>
                <Paper className="register-paper">
                    <div className="register-vertical  ml-5 mr-5">
                      <p className='mt-4'>An email has been sent to you to verify your email address. Please click the link on it.</p>
                    </div>
                </Paper>
              </div> }
            </Grid>
            {activeStep < 4 && <div className="mt-4">
              <Button disabled={activeStep === 0} onClick={handleBack} className="register-button mr-2"> Back </Button>
              <div className="loaderHolder">
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading}
                onClick={handleNext}
                className="register-button ml-2"
              > {activeStep === steps.length - 1 ? 'Register' : 'Next'} </Button>
              { isLoading && <Spinner className="floating-spinner" size="sm" color="light" />}
              </div>
            </div>}
          </div>
        </div>
  </React.Fragment>
        )
};

export default Register;