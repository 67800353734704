import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col, CardBody, Button } from "reactstrap";


class PricingBox extends Component {
    render() {
        return (
            <React.Fragment>
                {
                    this.props.pricings.map((pricing, key) =>
                        <Col lg={4} key={key}>
                            <div className="pricing-plan card text-center">
                                <CardBody className="p-4">
                                    <h5 className="mt-2 mb-5">{pricing.title}</h5>

                                    { this.props.coupon && <h1>{<span><strike><sup><small>$</small></sup> {pricing.price}</strike>     </span> }<sup><small>$</small></sup>{pricing.price * (100 - this.props.coupon.percentage) / 100 }/<span className="font-16">{pricing.duration}</span></h1>}
                                    { !this.props.coupon && <h1><sup><small>$</small></sup>{pricing.price}/<span className="font-16">{pricing.duration}</span></h1>}

                                    { this.props.coupon && <p className="mb-5">${pricing.value* (100 - this.props.coupon.percentage) / 100} {pricing.frequency}</p>}
                                    { !this.props.coupon && <p className="mb-5">${pricing.value} {pricing.frequency}</p>}

                                    <div>
                                        <Button onClick={e => this.props.isInDashboard ? this.props.onProductSelected(pricing.product, pricing.title, pricing.frequency, pricing.value) : window.location.href='/signin/coupon=NONE'} className="btn btn-primary">{this.props.isInDashboard ? 'Upgrade' : 'Join now'}</Button>
                                    </div>
                                    <div className="plan-features mt-5">
                                        {
                                            pricing.features.map((feature, key) =>
                                                <p key={key}>{feature.title} : <span className="text-success">{feature.value} {feature.sup && <sup>{feature.sup}</sup>}</span></p>
                                            )
                                        }
                                    </div>
                                </CardBody>
                            </div>
                        </Col>
                    )
                }
            </React.Fragment>
        );
    }
}

export default PricingBox;