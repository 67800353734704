import React, { useEffect, useState }  from "react";
import { useHistory } from "react-router-dom";
import DWT from '../dwt/DWT'
import Loading from 'react-fullscreen-loading';
import { useDispatch, useSelector } from "react-redux";
import JSZip from 'jszip';
import Axios from "axios";
import {useParams} from "react-router-dom";
import queryString from 'query-string';
import { logoutUser } from "../../auth/UserSlice";
import { useToasts } from 'react-toast-notifications'
import { Helmet } from 'react-helmet'

import NavbarPage from "../../home/Navbar/Navbar_Page";
import { Button } from 'reactstrap';

import Attachmemts from "../../dashboard/Attachments"


import "./AddEmails.css"

const AddEmails = (hasEmails) => {
    const userState = useSelector((state) => state.user);
    const { loggedInUser } = userState;
    const history = useHistory();
    const dispatch = useDispatch();
    const { addToast } = useToasts()
  
    const [ isLoading, setIsLoading ] = useState(false)

    let { locSearch } = useParams()
    const params = queryString.parse(locSearch)

    const onLoadingStateChange = (isLoading) => {
        setIsLoading(isLoading)
      };

    const openFileSelector = (event) => {
        event.preventDefault();
        history.push(`/scan/files/${locSearch}`);
    };

    const openDwtScan = (event) => {
        event.preventDefault();
        history.push(`/scan/add/${locSearch}`);
    };

    return (
        <React.Fragment>
          {/* Importing Navbar */}
            <Helmet>
              <title>Add Scan</title>
            </Helmet>
            <NavbarPage navItems={[
                  { id: 1, idnm: "home", navheading: "Home", link: "/"}
              ]} navClass={document.documentElement.scrollTop} imglight={false} isDashboard={true}/>
            {isLoading && <Loading loading background="#e1f5f2" loaderColor="#68cebf" />}
            
              <div className="emails-container">
                <Attachmemts  firmId={params.firmId} clientId={params.clientId}/>
              </div>
              
        </React.Fragment>
      );
}

export default AddEmails;