import React, { useEffect, useState }  from "react";
import { useHistory } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import queryString from 'query-string';
import {useParams} from "react-router-dom";
import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { logoutUser } from "../../auth/UserSlice";
import { useSelector, useDispatch } from "react-redux";

import { SegmentedControl } from 'segmented-control'

import "./BusinessDetails.css"

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css fil
import { DateRange, DateRangePicker, DefinedRange } from 'react-date-range'

import moment from 'moment';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEdit, faSave } from '@fortawesome/free-regular-svg-icons';

import NavbarPage from "../../home/Navbar/Navbar_Page";

import scanner from "../assets/images/scanner.png";
import trashBin from "../assets/images/trash_bin_flat.png";

import Loading from 'react-fullscreen-loading';

import CalenderPage from './CalenderPage'
import {CalenderPageMini} from './CalenderPage'

import { Helmet } from 'react-helmet'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: 20,
    marginTop: 5
  },
  paper: {
    padding: theme.spacing(1),
    width: 300,
    padding: 10
  },
  paperError: {
    padding: theme.spacing(1),
    width: 300,
    padding: 10,
    backgroundColor: '#ffbbee'
  }
}));


const BusinessDetails = () => { 
  const userState = useSelector((state) => state.user);
  const { loggedInUser } = userState;
  const history = useHistory();

  let { locSearch } = useParams()
  const params = queryString.parse(locSearch)

  const dispatch = useDispatch();

  const [scanGroups, setScanGroups] = useState([]);

  const [range, setRange] = useState([
    {
      startDate: moment().add(-3,"M").toDate(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [ isLoading, setIsLoading ] = useState(false)
  const [quarters, setQuarters] = useState([]);

  const [type, setType] = useState('scan');
  const [client, setClient] = useState('Business');
  const [currentlyEditing, setCurrentlyEditing] = useState(-1);
  const [currentName, setCurrentName] = useState(null);

  const classes = useStyles();

  const fetchScanGroups = async (range) => {
    setIsLoading(true)
    try {

      var dates = {}

      if(range && range.length > 0){
        let selectedRange = range[0]
        if(selectedRange.startDate || selectedRange.endDate){
          if(selectedRange.startDate){
            dates[`startDate`] = Math.floor(selectedRange.startDate.getTime())
          }
          if(selectedRange.endDate){
            dates[`endDate`] = Math.floor(selectedRange.endDate.getTime())
          }
        }
      }
      
      let response = await Axios.get("/api/accountant/scanGroups", {
        params: {...params, ...dates},
        headers: { Authorization: "Bearer " + loggedInUser.token }
      });
      let {groups, client} = response.data
      setScanGroups(groups)
      setClient(client.name)

    } catch (err) {
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log(err.response)
    } finally {
      setIsLoading(false)
    }
  };
  
  useEffect(() => {
    if (!loggedInUser) {
      history.push("/");
    }else {
      fetchScanGroups(range)
    }
  }, [loggedInUser, history]);

  const onAddInvoices = event => {
      event.preventDefault();
      history.push(`/scan/files/${locSearch}`);
  }

  const onOpenScanGroup = (e, scanGroupId) => {
    e.preventDefault()
    e.stopPropagation();
    history.push(`/scan/detail/groupId=${scanGroupId}`);
  }

  const onOpenKidScanGroup = (e, scanGroupId) => {
    e.preventDefault()
    e.stopPropagation();
    history.push(`/scan/detail/groupId=${scanGroupId}&noChild=true`);
  }

  const onEdit = (e, scanGroupId) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentName(null)
    setCurrentlyEditing(scanGroupId)
  }

  const onEditted = (scanGroupId, newName) => {
    setCurrentName(newName)
  }

  const onSave = async (e, scanGroupId) => {
    e.preventDefault();
    if(currentName){
      try {
        await Axios.post("/api/accountant/scanGroup/update", {name: currentName, id: scanGroupId}, {
          headers: { Authorization: "Bearer " + loggedInUser.token },
        });
      } catch (err) {
        if(err && err.response && err.response.status == 401 ){
          dispatch(logoutUser())
        }
      }
    }
    setCurrentlyEditing(-1)
    setCurrentName(null)
  }

  const onOpenQuarter= (quarter) => {
    history.push(`/scan/detail/quarter=${quarter}&${locSearch}`);
  }

  const onGroupTypeChanged = (type) => {
    if(type === 'scan'){
      setRange([
        {
          startDate: moment().add(-3,"M").toDate(),
          endDate: new Date(),
          key: 'selection'
        }
      ])
    } else { // By Quarter
      var quarterAdjustment= (moment().month() % 3) + 1;
      var lastQuarterEndDate = moment().subtract({ months: quarterAdjustment }).endOf('month');
      var lastQuarterStartDate = lastQuarterEndDate.clone().subtract({ months: 2 }).startOf('month');

      onDateRangeChanged(
        {
          startDate: lastQuarterStartDate.toDate(),
          endDate: lastQuarterEndDate.toDate(),
          key: 'selection'
        }
      )
    }
    setType(type)
  }

  const onDateRangeChanged = async (range) => {
    let startDate = moment(range.startDate)
    let endDate = moment(range.endDate)
    let startMonth = startDate.month()
    let startYear = startDate.year()
    let endMonth = endDate.month()
    let endYear = endDate.year()
    let startQuarter = (startYear - 2000) * 4 + Math.floor(startMonth/3)
    let endQuarter = (endYear - 2000) * 4 + Math.floor(endMonth/3)

    let qs = []
    for (let index = startQuarter; index <= endQuarter; index++) {
      qs.push({index: index, quarter: index%4 + 1, year :  Math.floor(index/4) + 2000 })
    }
    setQuarters(qs)
    setRange([range])

    if(type === 'scan'){
      await fetchScanGroups([range])
    }
  }

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  const onDragEnd = (result) => {
    console.log(result)
    if (result.combine) {
      let sourceIndex = result.source.index
      let destinationIndex = parseInt(result.combine.droppableId.replace("droppable_",""))
  
      confirmAlert({
        title: 'Confirm to Merge',
        message: 'Are you sure you want to merge the scans together?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => mergeScanGroups(sourceIndex, destinationIndex)
          },
          {
            label: 'No',
          }
        ]
      });
    } else if(result.destination && result.destination.droppableId === "recycle_bin"){
      confirmAlert({
        title: 'Confirm to Delete',
        message: 'Are you sure you want to delete this scan batch?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => deleteScanGroup(result.source.index)
          },
          {
            label: 'No',
          }
        ]
      });
    }

   
  }

  const mergeScanGroups = async (child, parent) => {
    console.log(`Merge ${child} into ${parent}`)
    setIsLoading(true)
    try {
      await Axios.post("/api/accountant/scans/merge", {child_id: child, parent_id: parent}, {
        headers: { Authorization: "Bearer " + loggedInUser.token },
      });
    } catch (err) {
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log(err)
    }
    setIsLoading(false)
    window.location.reload()
  }
  

  const deleteScanGroup = async (scangroupId) => {
    console.log(`Delete ${scangroupId}`)
    setIsLoading(true)
    try {
      await Axios.post("/api/accountant/scans/delete", {group_id: scangroupId}, {
        headers: { Authorization: "Bearer " + loggedInUser.token },
      });
    } catch (err) {
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
    }
    setIsLoading(false)
    window.location.reload()
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Business Details</title>
      </Helmet>
      
      {/* Importing Navbar */}
      <NavbarPage navItems={[
              { id: 1, idnm: "home", navheading: "Home", link:"/" }
          ]} navClass={document.documentElement.scrollTop} imglight={false} />
      {isLoading && <Loading loading background="#e1f5f2" loaderColor="#68cebf" />}
      <div className="bd-container">
          <div id="business-detail-left">
            <SegmentedControl
              name="oneDisabled"
              options={[
                { label: "By Scans", value: "scan", default: true},
                { label: "By Quarter", value: "quarter" }
              ]}
              setValue= { newValue => onGroupTypeChanged(newValue)}
              style={{ width: 352, color: '#007bff' }} // purple400
            />
            <DateRange
              editableDateInputs={true}
              onChange={item => onDateRangeChanged(item.selection)}
              moveRangeOnFirstSelection={false}
              ranges={range}
            />
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <div id="business-detail-right">
              <div id="client-name"><h1>{client}</h1></div>

              <Grid container className={classes.root} justify="flex-start" spacing={2} alignItems="stretch">
                  <Grid key="add" item>
                      <Paper className={classes.paper} onClick={onAddInvoices} >
                        <div id="scan-business-detail" className="p-2">
                          <img src={scanner} alt="" className="logo-light" height="100" width="auto" />
                          <p className="mt-4">Scan or Upload New Invoices</p>
                        </div>
                      </Paper>
                  </Grid>    
              </Grid>
    
              <Grid container className={classes.root} justify="flex-start" spacing={2} alignItems="stretch">
                  { type ==='scan' && scanGroups && scanGroups.map((value) => (
                    <Grid key={value.id} item>
                      <Droppable droppableId={`droppable_${value.id}`} isCombineEnabled>
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}>
                                <Draggable key={value.id} draggableId={`draggable_${value.id}`} index={value.id}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Paper className={ value.status == 'FAILED' ? classes.paperError : classes.paper }  onClick={e => currentlyEditing != value.id && onOpenScanGroup(e, value.id)} >
                                        <div className="horizontal">
                                          <CalenderPage date={value.createdAt}/>
                                          { (value.status != 'COMPLETE' && value.status != 'FAILED') &&
                                              <div class="spinner-border text-info spinner-border-sm mr-2" role="status">
                                              <span class="sr-only">Loading...</span>
                                            </div>
                                          }
                                          <div className="vertival">
                                            <div className="horizontal-center mb-1">
                                              <p className="anti-boorstrap-p" suppressContentEditableWarning={true} contentEditable={ currentlyEditing === value.id ? 'true' : 'false'} onInput={e => onEditted(value.id, e.target.textContent)} >{value.name}</p>
                                              { currentlyEditing != value.id && <FontAwesomeIcon className="ml-2" icon={faEdit} onClick={ e => onEdit(e, value.id) }/>}
                                              { currentlyEditing === value.id && <FontAwesomeIcon className="ml-2" icon={faSave} onClick={ e => onSave(e, value.id) }/>}
                                            </div>
                                            <Grid container justify="flex-start" spacing={1} alignItems="stretch">
                                              {
                                                value.children && value.children.length > 0 && 
                                                <Grid item onClick={ e => currentlyEditing != value.id && onOpenKidScanGroup(e, value.id)}>
                                                  <CalenderPageMini date={value.createdAt}/>
                                                </Grid>
                                              }
                                              {
                                                value.children && value.children.length > 0 && value.children.map( m => 
                                                <Grid item onClick={ e => currentlyEditing != value.id && onOpenKidScanGroup(e, m.id)}>
                                                  <CalenderPageMini date={m.createdAt}/>
                                                </Grid> )
                                              }
                                            </Grid>
                                          </div>
                                        </div>
                                      </Paper>
                                    </div>
                                  )}
                                </Draggable>
                                  
                              </div>
                          )}
                      </Droppable>
                    </Grid>
                  ))}
                  { type ==='quarter' && quarters && quarters.map((value) => (
                    <Grid key={value.index} item>
                      <Paper className={classes.paper}  onClick={e => onOpenQuarter(value.index)} >
                        <div>Quarter: {value.quarter}<br/>Year: {value.year}</div>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
            </div>
            <Droppable droppableId={`recycle_bin`} isCombineEnabled>
              {(provided, snapshot) => (
                <div  {...provided.droppableProps}
                ref={provided.innerRef} id="recycle_bin_container" className="p-2">
                  <img src={trashBin} alt=""  height="100" width="auto" />
                </div>
              )}
            </Droppable>
        </DragDropContext>
      </div>
    </React.Fragment>
  );
};

export default BusinessDetails;