import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
} from "reactstrap";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

//Import Section Title
import SectionTitle from "../common/section-title";
import Counter from "../Counter/counter";
import ClientBox from "./client-box";

//Import Images
import clinet1 from "../assets/images/clients/1.png";
import clinet3 from "../assets/images/clients/3.png";
import clinet4 from "../assets/images/clients/4.png";
import clinet6 from "../assets/images/clients/6.png";

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: [
                { name: "Cameron", post: "Invoice Scanner User", desc: "I was introduced to this software when it was in beta. And they were very receptive to the changes I suggested. Glad to see how it has turned out now." },
                { name: "Zheeni", post: "Invoice Scanner User", desc: "Love Invoice scanner. It used to take 2 days and a load of coffee to enter this client. Now I was done in 20 mins. Manual review process is a breeze." },
                { name: "Laavanya", post: "Invoice Scanner User", desc: "Hand written invoices are still around 60% accurate. They sometimes miss the decimal point in totals and GST. But, Invoice scanner team has promised that the system will learn." },
                { name: "Mahi", post: "Invoice Scanner User", desc: "I own BJ Accounting and Invoice Scanner is a solid addition to our set of useful tools." },
                { name: "Ruban", post: "Invoice Scanner User", desc: "Better & Cheaper option than anything else out there in the market. We charge $20/m from our clients to keep the Invoices for them and they were happy to pay. We actually have profitted from this tool without doing anything." },
            ],
            clients2: [
                { image: clinet1 },
                { image: clinet3 },
                { image: clinet4 },
                { image: clinet6 },
            ],
            responsive: {
                576: {
                    items: 2
                },
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="clients">
                    <Container>
                        <SectionTitle
                            subtitle="Testimonial"
                            title="What our Users Say"
                            desc="All these are from a few accountants we work with. Let's see what they have to say."
                        />

                        <Row>
                            <Col lg={12}>
                                <h5 className="mb-4"><i className="mdi mdi-format-quote-open text-primary h1 mr-1"></i> 8 Satisfied Clients & Growing</h5>

                                <OwlCarousel
                                    className="owl-theme testi-carousel"
                                    id="testi-carousel"
                                    items={1}
                                    loop={true}
                                    margin={10}
                                    nav={true}
                                    responsive={this.state.responsive}
                                >
                                    <ClientBox clients={this.state.clients} />
                                </OwlCarousel>
                            </Col>
                        </Row>


                        <Row className="mt-5">
                            {
                                this.state.clients2.map((client, key) =>
                                    <Col xl={3} sm={6} key={key} >
                                        <div className="client-images">
                                            <img src={client.image} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>

                    </Container>

                </section>

                <section className="section bg-primary">
                    <Container>
                        <Row className="justify-content-center mb-5">
                            <Col lg={7}>
                                <div className="text-center text-white-50">
                                    <h4 className="text-white">Best Book Keeping Solution for your Business</h4>
                                    <p>We are a family of small team now, but growing at an exponential speed.</p>
                                </div>
                            </Col>
                        </Row>

                        <Counter />
                    </Container>

                </section>
            </React.Fragment>
        );
    }
}

export default Clients;