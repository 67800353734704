import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";

export const getBusinessCategories = createAsyncThunk(
    "business/allCategories",
    async (userInfo, thunkAPI) => {
      try {
        const response = await Axios.get("/api/public/category/all");
        return response.data;
      } catch (error) {
        const { rejectWithValue } = thunkAPI;
        return rejectWithValue(error.response.data);
      }
    }
);

const initialBusinessState = {
    categoryList: [],
    loading: "idle",
    error: null,
};

const businessSlice = createSlice({
    name: "business",
    initialState: initialBusinessState,
    reducers: {},
    extraReducers: {
      [getBusinessCategories.pending]: (state) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      },
      [getBusinessCategories.fulfilled]: (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.categoryList = action.payload;
        }
      },
      [getBusinessCategories.rejected]: (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.error = action.payload;
        }
      },
    },
  });

  export default businessSlice.reducer;