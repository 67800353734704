import React, { useEffect, useState }  from "react";
import { useHistory } from "react-router-dom";
import DWT from '../dwt/DWT'
import Loading from 'react-fullscreen-loading';
import { useDispatch, useSelector } from "react-redux";
import JSZip from 'jszip';
import Axios from "axios";
import {useParams} from "react-router-dom";
import queryString from 'query-string';
import { logoutUser } from "../../auth/UserSlice";
import { useToasts } from 'react-toast-notifications'
import { Helmet } from 'react-helmet'

import NavbarPage from "../../home/Navbar/Navbar_Page";

import "./AddScan.css"

const AddScan = () => {
  const userState = useSelector((state) => state.user);
  const { loggedInUser } = userState;
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts()

  const [ isLoading, setIsLoading ] = useState(true)

  var timer = null

  useEffect(() => {
    if (!loggedInUser) {
      history.push("/");
    }else {
    }
    return () => {
      if(timer){
        clearTimeout(timer)
      }
    }
  }, [loggedInUser, history]);

  let { locSearch } = useParams()
  const { firmId, clientId, parentGroup, vid } = queryString.parse(locSearch)

  const uploadZippedImages = async (zippedBlob) => {
    try {
      const formData = new FormData();
      formData.append('images',zippedBlob)

      if(clientId && firmId){
        formData.append('clientId',clientId)
        formData.append('accountingFirmId',firmId)
      }

      if(parentGroup){
        formData.append('parentGroupId',parentGroup)
      }
      
      const config = {
        headers: {
            Authorization: "Bearer " + loggedInUser.token,
            'content-type': 'multipart/form-data'
        }
      }
      let response = await Axios.post("/api/image/scans/upload",formData,config)
      let group = response.data.scanGroup
      if(group != undefined && group != null){
        return response.data.scanGroup.id
      } else {
        throw("Wrong data")
      }
    } catch(err){
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
    }  
  }

  const toImageBlob = async (url) => {
    let response = await fetch(url)
    let data = await response.blob()
    return data
  }

  const onImagesReady = async (images) => {
    setIsLoading(true)

    var zip = new JSZip()

    try {
      for (let index = 0; index < images.length; index++) {
        const element = images[index];
        let blob = await toImageBlob(element)
        zip.file(`scan_${index}.png`,blob)
      }
  
      let zippedBlobs = await zip.generateAsync({type:"blob"})

      let groupId = await uploadZippedImages(zippedBlobs)
  
      history.push(`/scan/detail/groupId=${groupId}`);
    } catch(e){
      alert("Something went wrong")
    }
    setIsLoading(false)
    
  };

  const onLoadingStateChange = (isLoading) => {
    setIsLoading(isLoading)
    if(timer){
      clearTimeout(timer)
    }
    if(isLoading === true) {
      timer = setTimeout(() => {
        setIsLoading(false)
        onError('Something went wrong')
      }, 180000);
    }
  };

  const onError = (message) => {
    addToast(message, {
      appearance: 'error',
      autoDismissTimeout: 2000,
      autoDismiss: true,
    })
  };

  return (
    <React.Fragment>
      {/* Importing Navbar */}
        <Helmet>
          <title>Add Scan</title>
        </Helmet>
        <NavbarPage navItems={[
              { id: 1, idnm: "home", navheading: "Home", link: "/"}
          ]} navClass={document.documentElement.scrollTop} imglight={false} isDashboard={true}/>
        {isLoading && <Loading loading background="#e1f5f2" loaderColor="#68cebf" />}
        
          <div className="add-scan-container">
              <DWT 
                hasEmails={true}
                forVideo={vid}
                onLoadingStateChange={onLoadingStateChange}
                onError={onError}
                done={onImagesReady}
                productKey={'t0151KQMAAJjHu9VvwFhFsWaLAqH40L2XJNz0Q21C3f/mXMF8CGcqSWCMvJWhnsPqdZum2UFOF6PSAUrjGrnT4NG4Pc1UmUuG1DcMw2AtQnOnVObG8GW2YqQavPT5mzLrzfxwfXT6NIYHjPim7KdvzMz3DtnO3BoeMOIbM3Nj7D1rGjIfAtprZuoZHjDim5r5aVhDAKKcMASiow=='}/>
          </div>
    </React.Fragment>
  );

};

export default AddScan;