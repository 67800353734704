import React, { Component, useState } from 'react';
import { useHistory } from "react-router-dom";
import {
    Navbar,
    Nav,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Container,
    Collapse,
    Button
} from "reactstrap";

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import ScrollspyNav from "./scrollSpy";

import { logoutUser } from "../../auth/UserSlice";
import { useDispatch, useSelector } from "react-redux";

//Import Images
import logolight from "../assets/images/logo-light.png";
import logodark from "../assets/images/logo-dark.png";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'


const NavbarPage = (props) => {
    
    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const [searchText, setSearchText] = useState("")

    const userState = useSelector((state) => state.user);
    const { loggedInUser } = userState;
    const dispatch = useDispatch();
    const history = useHistory();
    
    const handleLogout = () => {
        dispatch(logoutUser());
    };

    const goToDashboard = () => {
        history.push("/dashboard");
    };

    const goToSignIn = () => {
        history.push("/signin");
    };

    const goToRegister = () => {
        history.push("/register");
    };

    const toggle = () => {
        setIsOpenMenu(!isOpenMenu)
    }

    const clearSearch = () => {
        setSearchText("")
        props.onSearchTextChange('')
    }

    const onSearchTextChange = (e) => {
        setSearchText(e.target.value)
        props.onSearchTextChange(e.target.value)
    }

    return (
        <React.Fragment>
            <Navbar expand="lg" fixed="top" className={"navbar-custom sticky sticky-dark " + props.navClass} style={ props.hasBorder === true ? {borderBottom:'1px solid black'} : {}}>
                <Container fluid className="mx-2">
                    <NavbarBrand className="logo text-uppercase" href="/">
                        {
                            props.imglight === true ?
                                <img src={logolight} alt="" className="logo-light" height="40" width="auto" />
                                : <img src={logodark} alt="" className="logo-dark" height="40" width="auto" />
                        }
                    </NavbarBrand>
                    { props.onSearchTextChange && <TextField
                        id="input-with-icon-textfield"
                        variant="outlined"
                        size="small"
                        label="Search Invoices"
                        onChange={onSearchTextChange}
                        onFocus={props.onSearchFocus}
                        onBlur={props.onSearchBlur}
                        value={searchText}
                        style ={{width: '30%', marginLeft : '10px'}}
                        InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FontAwesomeIcon  icon={faSearch} />
                            </InputAdornment>
                        ),
                        endAdornment: searchText.length > 0 && (
                            <InputAdornment position="end" onClick={clearSearch}>
                                <FontAwesomeIcon  icon={faTimesCircle} />
                            </InputAdornment>
                        )
                        }}
                    />}
                    <NavbarToggler onClick={toggle} ><i className="mdi mdi-menu"></i></NavbarToggler>

                    { props.isHome && <Collapse id="navbarCollapse" isOpen={isOpenMenu} navbar>
                        <ScrollspyNav
                            scrollTargetIds={props.navItems.map((item) => {
                                return (
                                    item.idnm
                                )
                            })}
                            scrollDuration="200"
                            headerBackground="true"
                            activeNavClass="active"
                            className="navbar-collapse"
                        >
                            <Nav navbar className="ml-auto navbar-center" id="mySidenav">
                                {props.navItems.map((item, key) => (
                                    <NavItem key={key} className={item.navheading === "Home" ? "active" : ""}>
                                        <NavLink href={"#" + item.idnm} > {item.navheading}</NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                            { loggedInUser && <Button onClick={e => goToDashboard() } color="success" className="btn-rounded navbar-btn">Dashboard</Button>}
                            { !loggedInUser && !props.isSignIn && !props.isDashboard && <Button onClick={ e => goToSignIn() } color="success" className="btn-rounded navbar-btn">Sign In</Button>}
                            { !loggedInUser && props.isSignIn && <Button onClick={ e => goToRegister() } color="success" className="btn-rounded navbar-btn">Register</Button>}
                        </ScrollspyNav>
                    </Collapse>
                    }
                    { !props.isHome && 
                        <div className="collapse navbar-collapse">
                            <Nav navbar className="ml-auto navbar-center" id="mySidenav">
                                {props.navItems.map((item, key) => (
                                    <NavItem key={key} className={item.isSelected ? "active" : ""}>
                                        <NavLink href={item.link} > {item.navheading}</NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                            {loggedInUser && <Button onClick={e => goToDashboard() } color="success" className="btn-rounded navbar-btn">Dashboard</Button>}
                            {!loggedInUser && !props.isSignIn && !props.isDashboard && <Button onClick={ e => goToSignIn() } color="success" className="btn-rounded navbar-btn">Sign In</Button>}
                            {!loggedInUser && props.isSignIn && <Button onClick={ e => goToRegister() } color="success" className="btn-rounded navbar-btn">Register</Button>}
                        </div>
                    }
                </Container>
            </Navbar>
        </React.Fragment>
    );
}

export default NavbarPage;