import React, { useEffect, useState }  from "react";
import { useHistory, useParams } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Axios from "axios";
import "./Dashboard.css"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import { logoutUser } from "../auth/UserSlice";
import { useSelector, useDispatch } from "react-redux";

import NavbarPage from "../home/Navbar/Navbar_Page";

import { Table, Button } from 'reactstrap';
import Loading from 'react-fullscreen-loading';

import Subscription from './stripe/Subscription'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import queryString from 'query-string';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Typography from '@material-ui/core/Typography';

import { Helmet } from 'react-helmet'

import { sendPageView, sendScreenView } from '../Utils'

import Attachmemts from "./Attachments";
import ClientEmails from "./ClientEmails";
import PickUpSchedule from "./PickUpSchedule";


const useStyles = makeStyles((theme) => ({
  paper: {
    height: 100,
    width: 200,
    padding: 10
  }
}));


const Dashboard = () => {

  const userState = useSelector((state) => state.user);
  const { loggedInUser } = userState;
  const history = useHistory();
  const [ employmentRoles, setEmploymentRoles ] = useState([])
  const [ employmentRole, setEmploymentRole] = useState(null)
  const [ businesses, setBusinesses ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)
  const classes = useStyles();
  const dispatch = useDispatch();
  const [subscription, setSubscription] = useState(null)
  const [team, setTeam] = useState([])
  const [reviewRequests, setReviewRequests] = useState([])
  const [pickupRequests, setPickupRequests] = useState([])
  const [couponObject, setCouponObject] = useState(null)
  const [consumption, setConsumption] = useState({
    "allowed": 100,
    "consumed": 0
})

  let { coupon } = useParams()
  const params = queryString.parse(coupon)

  const [selectedTab, setSelectedTab] = useState(params && params.coupon ? 'subscription' : null)

  const fetchConsumption = async (token, firmId) => {
    try {
      const response = await Axios.get(`/api/business/consumption?firm_id=${firmId}`, {
        headers: { Authorization: "Bearer " + token },
      });
       console.log(response.data)
      setConsumption(response.data)
    } catch (err){
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log(err.response)
    }
  }

  const fetchBusinesses = async (token, firmId) => {
    setIsLoading(true)
    try {
      const response = await Axios.get(`/api/accountant/clients?firmId=${firmId}`, {
        headers: { Authorization: "Bearer " + token },
      });
      setBusinesses(response.data)
      if(!params.coupon){
        setSelectedTab('clients')
      }
    } catch (err){
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log(err.response)
    } finally {
      setIsLoading(false)
    }
  };

  const fetchReviewRequests = async (token)=>{
    try {
      const response = await Axios.get(`/api/accountant/requests`, {
        headers: { Authorization: "Bearer " + token },
      });
      setReviewRequests(response.data)
    } catch (err){
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log(err.response)
    } 
  }

  const fetchPickUpRequests = async (token)=>{
    try {
      const response = await Axios.get(`/api/accountant/mypickups`, {
        headers: { Authorization: "Bearer " + token },
      });
      setPickupRequests(response.data)
    } catch (err){
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log(err.response)
    } 
  }

  const fetchCouponCode = async (token, code)=>{
    try {
      const response = await Axios.get(`/api/subscription/coupon`, {
        params: { code : code},
        headers: { Authorization: "Bearer " + token },
      });
      if(response.data && response.data.valid) {
        setCouponObject(response.data)
      }
    } catch (err){
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log(err.response)
    } 
  }

  const groupBy = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }), 
    {},
  );

  const fetchTeam = async (token, firmId) => {
    try {
      const response = await Axios.get(`/api/business/team?firmId=${firmId}`, {
        headers: { Authorization: "Bearer " + token },
      });
      setTeam(groupBy(response.data, 'userId'))
    } catch (err){
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log(err.response)
    }
  };

  const fetchSubscription  = async (token, firmId) => {
    try {
      const response = await Axios.get("/api/subscription/retrieve", {
        params: { firm_id : firmId},
        headers: { Authorization: "Bearer " + token },
      });
      setSubscription(response.data)
    } catch (err){
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log(err.response)
    }
  }

  const fetchEmployers = async (token) => {
    setIsLoading(true)
    try {
      const response = await Axios.get("/api/accountant/employers", {
        headers: { Authorization: "Bearer " + token },
      });
      setEmploymentRoles(response.data)
      if(response.data.length > 0){
        setEmploymentRole(response.data[0])
      }
      /*setEmployers(response.data.map( r => r.business))
      if(response.data.length > 0){
        setEmployer(response.data[0].business)
      }*/
    } catch (err){
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log(err.response)
    } finally {
      setIsLoading(false)
    }
  };

  const cancelSubscription = async () => {
    try {
      const response = await Axios.post("/api/subscription/delete", 
      {
        sub_db_id: subscription.id
      },
      {
        headers: { Authorization: "Bearer " + loggedInUser.token },
      });
      setSubscription(response.data)
    } catch (err){
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log(err.response)
    }
  }

  const onAutoRenewalCancelClicked = async () => {

    confirmAlert({
      title: 'Are you sure?',
      message: 'Unless you are on a Premium or Platinum annual plan, your invoices will be deleted in the near future.',
      buttons: [
        {
          label: 'Unsubscribe',
          onClick: () => cancelSubscription()
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const approveOrDeclineRequest = async (id, approved) => {
    setReviewRequests(reviewRequests.map(m => {
      if(m.id === id){
        return {...m, status: approved? "approved" : "declined" }
      } else return m
    }))
    try {
      const response = await Axios.post("/api/accountant/processRequest", {id: id, status: approved? "approved" : "declined"}, {
        headers: { Authorization: "Bearer " + loggedInUser.token },
      });
    } catch (error) {
      console.log(error.response)
    }
  }

  useEffect(() => {
    if (!loggedInUser) {
      history.push("/");
    }else {
      fetchEmployers(loggedInUser.token)
      fetchReviewRequests(loggedInUser.token)
      fetchPickUpRequests(loggedInUser.token)
      if(params.coupon){
        fetchCouponCode(loggedInUser.token, params.coupon)
      }
    }
  }, [loggedInUser, history]);

  useEffect(() => {
    if (loggedInUser && employmentRole) {
      fetchBusinesses(loggedInUser.token, employmentRole.business.id)
      fetchSubscription(loggedInUser.token, employmentRole.business.id)
      fetchTeam(loggedInUser.token, employmentRole.business.id)
      fetchConsumption(loggedInUser.token, employmentRole.business.id)
    }
  }, [employmentRole]);
  
  const onEmployerChange = (event, newValue) => {
      event.preventDefault();
      if(newValue){
        setEmploymentRole(newValue);
      }
  }

  const onAddClient = event => {
      event.preventDefault();
      history.push(`/business/create/${employmentRole.business.id}`);
  }

  const onAddEmployee = event => {
      event.preventDefault();
  }

  const onOpenClient = (clientId) => {
      history.push(`/business/details/clientId=${clientId}&firmId=${employmentRole.business.id}`);
  }

  const onLeftSideSelected = (id) => {
    sendScreenView(id)
    if(id === 'sign_out') {
      dispatch(logoutUser())
    } else {
      setSelectedTab(id)
    }
  }

  const covertRquest = (request) => {
    var desc = ""
    var shouldHaveApproveButton = ""
    if(request.type === 'owner' || request.type === 'admin' || request.type === 'employee'){

      var firm = (<b>{request.firm.name}</b>)

      if(request.type === 'owner' && request.commentFromRequester){
        let obj = JSON.parse(request.commentFromRequester)
        if(obj && obj.abn){
          firm = <p>a business with ABN <b>{obj.abn}</b></p>
        }
      }


      let status = request.requester.status === 'verified' ? <span className="text-success">{request.requester.status.replace(/[_]/g, ' ')}</span> : <span className="text-danger">{request.requester.status.replace(/[_]/g, ' ')}</span>

    
      desc = (<p>Add <b>{request.requester.firstName} {request.requester.lastName} </b>(<i>{request.requester.email} - {status} </i>) as an <b>{request.type}</b> to {firm}</p>)
      shouldHaveApproveButton = loggedInUser && request.status === 'pending' && request.assignee.id === loggedInUser.user_id
    }

    var from = loggedInUser && request.requester.id ===  loggedInUser.user_id ? "You" : `${request.requester.firstName} ${request.requester.lastName}`
    var to = loggedInUser &&  request.assignee.id === loggedInUser.user_id ? "You" : `${request.assignee.firstName} ${request.assignee.lastName}`
    
    return {id:request.id, description: desc, from: from, to: to, status:request.status,   showButton:  shouldHaveApproveButton}
  }


  return (
    <React.Fragment>
        <Helmet>
            <title>Dashboard</title>
        </Helmet>
        {/* Importing Navbar */}
        <NavbarPage navItems={[
                { id: 1, idnm: "home", navheading: "Home", link: "/", isSelected: false}
            ]} navClass={document.documentElement.scrollTop} imglight={false} isDashboard={true}/>
        {isLoading && <Loading loading background="#e1f5f2" loaderColor="#68cebf" />}
        <div className="dashboard-container">
            <div id="db-left-container">

            { !isLoading && (!employmentRoles || employmentRoles.length === 0) &&
                <div>Please request your empolyer to add you to the platform</div>
              }
              { isLoading &&
                <div>Loading Employers</div>
            }

            { !isLoading && employmentRoles && employmentRoles.length > 0 &&
            <Table hover bordered>
              <tbody>
                <tr>
                  <td >
                    <div>
                      <Autocomplete
                        id="combo-box-demo"
                        disableClearable
                        options={employmentRoles}
                        value={employmentRole}
                        onChange={onEmployerChange}
                        getOptionLabel={(option) => option.business.name}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Employer" variant="outlined" />}
                      />
                    </div> 
                  </td>
                </tr>
                <tr>
                  <td className={selectedTab === 'clients' ? "db-left-selected" : "db-left-normal"} onClick={e => onLeftSideSelected('clients')}>Clients</td>
                </tr>
                <tr>
                  <td className={selectedTab === 'team' ? "db-left-selected" : "db-left-normal"} onClick={e => onLeftSideSelected('team')}>Team</td>
                </tr>
                <tr>
                  <td className={selectedTab === 'client_emails' ? "db-left-selected" : "db-left-normal"} onClick={e => onLeftSideSelected('client_emails')}>Client email addresses</td>
                </tr>
                <tr>
                  <td className={selectedTab === 'attachments' ? "db-left-selected" : "db-left-normal"} onClick={e => onLeftSideSelected('attachments')}>Email attachments</td>
                </tr>
                <tr>
                  <td className={selectedTab === 'pickups' ? "db-left-selected" : "db-left-normal"} onClick={e => onLeftSideSelected('pickups')}>Schedule document pickup</td>
                </tr>
                 <tr>
                  <td className={selectedTab === 'subscription' ? "db-left-selected" : "db-left-normal"} onClick={e =>  employmentRole && (employmentRole.type === 'su' || employmentRole.type === 'owner') && onLeftSideSelected('subscription') }>
                    <div className="center-vertical">
                      <span className="mr-4">Subscription</span>
                      { consumption && (consumption.allowed - consumption.consumed) <= 0 && <Typography variant="caption" component="div" color="error"> {`${consumption.consumed}/${consumption.allowed}. Please upgrade`} </Typography> }
                      { consumption && (consumption.allowed - consumption.consumed) > 0 && <Typography variant="caption" component="div" color="textSecondary"> {`${consumption.allowed - consumption.consumed} scans left for this quarter`} </Typography> }
                    </div>
                    </td>
                </tr>
               
              </tbody>
            </Table>
            } 

            { !isLoading &&
            <Table hover bordered>
              <tbody>
              <tr>
                  <td className={selectedTab === 'reviewRequests' ? "db-left-selected" : "db-left-normal"} onClick={e => onLeftSideSelected('reviewRequests')}><div>Requests 
                    { reviewRequests && reviewRequests.find( f => f.status === 'pending') && <div className="circle-icon"><FontAwesomeIcon  icon={faExclamation} /></div> }
                    </div></td>
                </tr>
                <tr>
                  <td className={selectedTab === 'settings' ? "db-left-selected" : "db-left-normal"} onClick={e => onLeftSideSelected('settings')}>Settings</td>
                </tr>
                <tr>
                  <td className={selectedTab === 'sign_out' ? "db-left-selected" : "db-left-normal"} onClick={e => onLeftSideSelected('sign_out')}>Sign Out</td>
                </tr>
              </tbody>
            </Table>}
            </div>

            <div id="db-right-container">
                { !isLoading && employmentRoles && employmentRoles.length > 0  && selectedTab === 'clients' /*&& ['su','accountant' ,'owner' ].find( r => employmentRole.type === r )*/ &&
                <Grid className="mt-2" container justify="center" spacing={2}>
                  <Grid key='add' item>
                    <Paper className={classes.paper} onClick={onAddClient} ><div><b>+<br/>Add<br/>a<br/>Client</b></div></Paper>
                  </Grid>
                  { businesses && businesses.length > 0 && businesses.map((value) => (
                    <Grid key={value.id} item>
                      <Paper className={classes.paper}  onClick={e => onOpenClient(value.id)} >{value.name}</Paper>
                    </Grid>
                  ))}
                </Grid>
              }
              { !isLoading && selectedTab === 'attachments' && <Attachmemts  firmId={employmentRole.business.id} clients={businesses}/>}
              { !isLoading && selectedTab === 'pickups' && <PickUpSchedule  firmId={employmentRole.business.id} clients={businesses}/>}
              { !isLoading && selectedTab === 'client_emails' && employmentRole && businesses &&
                <ClientEmails firmId={employmentRole.business.id} clients={businesses}/>
              }
              { !isLoading && team && selectedTab === 'team' &&
                <Grid  className="mt-2" container justify="center" spacing={2}>
                  { (employmentRole.type === 'su' ||  employmentRole.type === 'owner') && <Grid key='add' item>
                    <Paper className={classes.paper} onClick={onAddEmployee} ><div><b>+<br/>Add<br/>an<br/>Employee</b></div></Paper>
                  </Grid>}
                  { Object.keys(team).map((key, index) => (
                    <Grid key={index} item>
                      <Paper className={classes.paper}>
                        <div>
                            <h6>{`${team[key][0].user.firstName} ${team[key][0].user.lastName}`}</h6>
                            <ul>
                            {
                              team[key].map(role => <li>{role.type}</li>)
                            }
                            </ul>
                        </div>
                        </Paper>
                    </Grid>
                  ))}
                </Grid>
              }
              { !isLoading && employmentRoles && employmentRoles.length > 0  && selectedTab === 'subscription' && <Subscription coupon={couponObject} firmId={employmentRole.business.id} subscription={subscription} onCancel={onAutoRenewalCancelClicked}/> }
              { !isLoading && reviewRequests && selectedTab === 'reviewRequests' &&
                <div id="request-table-container">
                  <Table hover bordered>
                  <thead>
                        <tr>
                          <th>#</th>
                          <th>Description</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                    <tbody>

                      {
                        reviewRequests.map( o => covertRquest(o) ).map( (r, index) => <tr>
                          <td>{index+1}</td>
                          <td>{r.description}</td>
                          <td>{r.from}</td>
                          <td>{r.to}</td>
                          <td>{r.status}</td>
                          <td>
                            { r.showButton && <div>
                            <Button color="primary" size="sm" onClick={e => approveOrDeclineRequest(r.id, true)}>Approve</Button>
                            <Button className='ml-2' color="secondary" size="sm" onClick={e => approveOrDeclineRequest(r.id, false)}>Decline</Button>
                            </div>}
                          </td>
                        </tr>)
                      }
                      {
                        pickupRequests.map( (r, index) => <tr>
                          <td>{pickupRequests.length+index+1}</td>
                          <td>{r.deadline}</td>
                          <td>{r.firm && r.firm.name}</td>
                          <td>{r.business && r.business.name}</td>
                          <td>{r.status}</td>
                        </tr>)
                      }
                    </tbody>
                  </Table>
                </div>
              }
            </div>
         </div>
    </React.Fragment>
    );

};

export default Dashboard;