import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import userReducer from "../components/auth/UserSlice";
import businessReducer from "../components/business/BusinessSlice";
import { composeWithDevTools } from 'redux-devtools-extension';


export default configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    business: businessReducer
  },
},  composeWithDevTools());
