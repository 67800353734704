import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Media } from "reactstrap";
import FeatherIcon from 'feather-icons-react';

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
    state = {
        links1 : [
            { link : "/blog/about-us", title : "About Us" },
            //{ link : "#", title : "Media & Press" },
            { link : "/blog/career", title : "Career" },
            { link : "/blogs", title : "Blog" },
        ],
        links2 : [
            { link : "/blog/pricing-explained", title : "Pricing" },
            { link : "/blog/for-accounting-firms", title : "For Accounting Firms" },
            { link : "/blog/for-book-keepers", title : "For Bookkeepers" },
            { link : "/blog/for-smbs", title : "For Suppliers/SMBs" },
        ],
    }
    render() {
        return (
            <React.Fragment>
        <footer className="footer">
            <Container>
                <Row>
                    <Col lg={4} sm={6} >
                        <div>
                            <h5 className="mb-4 footer-list-title">About Invoice Scanner</h5>
                            <p>Invoice Scanner is an Australian based SAAS, that provides Invoice Retaining services to Small Businesses and Accounting Firms.</p>
                        </div>
                    </Col>
                    <Col lg={{size:2, offset:1}} sm={6}>
                        <div>
                            <h5 className="mb-4 footer-list-title">Company</h5>
                            <ul className="list-unstyled footer-list-menu">
                                {
                                    this.state.links1.map((fLink, key) =>
                                        <li key={key}><Link to={fLink.link}>{fLink.title}</Link></li>
                                    )
                                }
                            </ul>
                        </div>
                    </Col>
                    <Col lg={2} sm={6} >
                        <div>
                            <h5 className="mb-4 footer-list-title">More Info</h5>
                            <ul className="list-unstyled footer-list-menu">
                                {
                                    this.state.links2.map((fLink, key) =>
                                        <li key={key}><Link to={fLink.link}>{fLink.title}</Link></li>
                                    )
                                }
                            </ul>
                        </div>
                    </Col>

                    <Col lg={3} sm={6}>
                        <div>
                            <h5 className="mb-4 footer-list-title">Contact</h5>

                            <div>
                                {
                                    /*
                                    <Media>
                                        <i>
                                        <FeatherIcon icon="map-pin" className="icon-dual-light icons-sm mt-1 mr-2" />
                                        </i>
                                        <Media body>
                                            <p>BL Tech, Quakers Road, Marayong, NSW 2148.</p>
                                        </Media>
                                    </Media>
                                    */
                                }
                                <Media>
                                    <i>
                                    <FeatherIcon icon="mail" className="icon-dual-light icons-sm mt-1 mr-2" />
                                    </i>
                                    <Media body>
                                        <p>support@invoicescanner.com.au</p>
                                    </Media>
                                </Media>
                                <Media>
                                    <i>
                                    <FeatherIcon icon="phone" className="icon-dual-light icons-sm mt-1 mr-2" />
                                    </i>
                                    <Media body>
                                        <p>2 9 8 3 7 0 4 6 3</p>
                                    </Media>
                                </Media>    
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
                {/* Render footer links */}
                <FooterLinks/>
            </React.Fragment>
        );
    }
}

export default Footer;