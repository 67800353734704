import React, { useEffect, useState }  from "react";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TextField, Button, Typography, Select, MenuItem, FormControl, InputLabel} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faSort, faSave } from '@fortawesome/free-solid-svg-icons'

import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../auth/UserSlice";
import Iframe from 'react-iframe'


import "./Attachments.css"
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
    },
    scrollable: {
      overflow: 'scroll',
      height: '100%'
    }
  }));

const columns = [
    { id: 'index', label: '', minWidth: 10 },
    { id: 'date', label: 'Email Date', minWidth: 40 , isSortable: true },
    { id: 'email', label: 'From', minWidth: 40, isSortable: true },
    { id: 'clientId', label: 'Client', minWidth: 60 },
    {
      id: 'delete',
      label: '',
      minWidth: 20,
      align: 'center'
    },
    {
        id: 'done',
        label: '',
        minWidth: 20,
        align: 'center'
    }
];

const columnsSingleCLients = [
    { id: 'index', label: '', minWidth: 10 },
    { id: 'date', label: 'Email Date', minWidth: 40 , isSortable: true },
    { id: 'email', label: 'From', minWidth: 40, isSortable: true },
    { id: 'clientId', label: 'Client', minWidth: 60 },
    {
      id: 'selected',
      label: 'Select',
      minWidth: 20,
      align: 'center',
      hasSelectAll: true
    }
];


const Attachmemts = ({firmId, clients, clientId}) => {

    const classes = useStyles();
    const [ selectedInvoice, setSelectedInvoice ] = useState(0)
    const [ attachments, setAttachments ] = useState([])
    const [client, setClient] = useState('Business');

    const dispatch = useDispatch();

    const userState = useSelector((state) => state.user);
    const { loggedInUser } = userState;

    useEffect(() => {
        fetchAttachments(firmId)
        downloadEmails()
    }, []);

    const onInvoiceSelected = async (index)=>{
        setSelectedInvoice(index)
    }

    const onDeleteSelected = async (index)=>{
        let data = {
            'entryId': attachments[index].id,
        }
        try {
            await Axios.post("/api/accountant/attachment/delete", data, {
              headers: { Authorization: "Bearer " + loggedInUser.token },
            });
            fetchAttachments()
        } catch (err) {
            if(err && err.response && err.response.status == 401 ){
              dispatch(logoutUser())
            }
        }
    }

    const onSave = async (index)=>{
        let data = {
            'entryIds': [attachments[index].id],
            'clientId' : attachments[index].clientId
        }
        try {
            await Axios.post("/api/accountant/attachments/update", data, {
              headers: { Authorization: "Bearer " + loggedInUser.token },
            });
            fetchAttachments()
        } catch (err) {
            if(err && err.response && err.response.status == 401 ){
              dispatch(logoutUser())
            }
            console.log(err)
        }
    }

    const onSortSelected = async (id)=>{
        if(id === 'emailDate'){
          
        }else if(id === 'email') {
          
        }
      }

    const onColumnCheckBoxClicked = (id, value) => {
        setAttachments(attachments.map(function (a) {
            var newA = { ...a }
            newA['selected'] = value
            return newA;
        }))
    }

    const onSelectedChanged = (index, value) => {
        let newSet = [...attachments]
        let attachment = attachments[index]
        attachment['selected'] = value
        newSet[index] = attachment
        setAttachments(newSet)
    }

    const onClientChange = async (index, value) => {
        attachments[index].clientId = value
        setAttachments([...attachments])
    }

    const fetchAttachments = async () => {
        try {
            let url = clientId ? `/api/accountant/attachments?firmId=${firmId}&clientId=${clientId}` : `/api/accountant/attachments?firmId=${firmId}`
          const response = await Axios.get(url, {
            headers: { Authorization: "Bearer " + loggedInUser.token },
          });
          let {attachments, client} = response.data
          setAttachments(attachments)
          if(client){
            setClient(client.name)
          }
        } catch (err){
          if(err && err.response && err.response.status == 401 ){
            dispatch(logoutUser())
          }
          console.log(err.response)
        }
    };

    const downloadEmails = async () => {
        try {
          await Axios.post(`/api/accountant/downloadEmail`, {}, {
            headers: { Authorization: "Bearer " + loggedInUser.token },
          });
          fetchAttachments()
        } catch (err){
          if(err && err.response && err.response.status == 401 ){
            //dispatch(logoutUser())
          }
          console.log(err.response)
        }
    };

    const handleProcess = async () => {

    }

    return (
    <div className="left-right-flex">
        <div className={ clientId ? "attachment-table" : "attachment-table-no-bottom"}>
            <TableContainer className={classes.scrollable}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            { (clientId ? columnsSingleCLients : columns).map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                >
                                    {!column.hasSelectAll && column.label}
                                    {column.isSortable && <FontAwesomeIcon className="ml-2" icon={faSort} onClick={ e => onSortSelected(column.id) }/>}
                                    {column.hasSelectAll && <input type="checkbox" onChange={e => onColumnCheckBoxClicked(column.id, e.target.checked)} />}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    {   attachments && attachments.map((row, index) => (
                            <TableRow key={row.index} className={selectedInvoice == index ? 'bg-selected-sc-group' : 'bg-normal-sc-group'} tabIndex={-1}  onClick={ e => onInvoiceSelected(index)}>
                                {(clientId ? columnsSingleCLients : columns).map((column) => {
                                    const value = row[column.id];
                                    return (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        >
                                            {
                                                column.id == 'index' && index+1
                                            }
                                            {
                                                column.id == 'date' && moment(value).format('DD MMM yyyy')
                                            }
                                            {
                                                (column.id == 'email') && value
                                            }
                                            {
                                                column.id === 'delete' && <FontAwesomeIcon id={`${index}_${column.id}`} icon={faTrashAlt} onClick={ e => onDeleteSelected(index) }/>
                                            }
                                            {
                                                column.id === 'done' && <FontAwesomeIcon id={`${index}_${column.id}`} icon={faSave} onClick={ e => onSave(index) }/>
                                            }
                                            {
                                                column.id === 'selected' && <input id={`${index}_${column.id}`} type="checkbox" checked={value} onChange={e => onSelectedChanged(index, e.target.checked)}/>
                                            }
                                            {
                                                clients && column.id === 'clientId' && 
                                                <select name="client" id="client" value={row['clientId']} onChange={ e => onClientChange(index, e.target.value) }>
                                                { clients && clients.length > 0 && clients.map((value) => (
                                                    <option value={value.id}>{value.name}</option>
                                                ))}
                                                </select>
                                            }
                                            { client && clientId && column.id === 'clientId' && client}
                                    </TableCell>)
                                })}
                            </TableRow>
                        ))
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            { clientId && <div className="button-container"><Button size="large" variant="contained" color="primary"  disabled={false} onClick={handleProcess}>Process</Button></div>}
        </div>
        <div id="attachment-grand-parent">
            <div className="attachment-image-group">
                <div id="image-holder">
                    { attachments && selectedInvoice != null && attachments[selectedInvoice] && <Iframe url={attachments[selectedInvoice].publicUrl}
                        id={clientId ? "attachment-iframe-with-bottom" :"attachment-iframe"}
                        className="myClassname"
                        display="initial"
                        position="relative"/> 
                    }
                </div>
            </div>
        </div>
    </div>
    )
}

export default Attachmemts;