import React, { useEffect, useState }  from "react";
import { Helmet } from 'react-helmet'
import NavbarPage from "../home/Navbar/Navbar_Page";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { useHistory, useParams } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EdiText from 'react-editext';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { logoutUser } from "../auth/UserSlice";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { Base64 } from 'js-base64'

import { Container, Row, Col, Form, Input, Button, Label } from "reactstrap";

import "./Blog.css"

const BlogT = ({blogData}) => { 

  const userState = useSelector((state) => state.user);
  const { loggedInUser } = userState;
  const dispatch = useDispatch();
  const history = useHistory();

  let { id, option } = useParams()
  const [navClass, setNavClass] = useState("");
  const [pos, setPos] = useState(document.documentElement.scrollTop)
  const [hero, setHero] = useState("https://www.mynewcaledonia.com.au/wp-content/uploads/sites/12/2016/12/Beach-Banner.jpg")
  const [emoji, setEmoji] = useState("🖥")
  const [title, setTitle] = useState("Blog")
  const [path, setPath] = useState("fancy-title")
  const [author, setAuthor] = useState("Bavan")
  const [date, setDate] = useState(null)
  const [blogContents, setBlogContents] = useState([])
  const [status, setStatus] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
    fetchBlog(id)
    return function cleanup() {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  }, []);

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > pos) {
      setNavClass("nav-sticky");
    }else {
      setNavClass("");  
    }
};

const addImage = () =>{
  let newArr = [...blogContents];
  newArr.push({type: 'image', content: '', caption: null})
  setBlogContents(newArr)
}

const addVideo = () =>{
  let newArr = [...blogContents];
  newArr.push({type: 'video', content: ''})
  setBlogContents(newArr)
}

const addPara = () =>{
  let newArr = [...blogContents];
  newArr.push({type: 'para', content: EditorState.createEmpty()})
  setBlogContents(newArr)
}

const fetchBlog = async(path) => {
  try {
    const response = await Axios.get(`/api/public/blog?path=${path}`);
    const blog = response.data
    setTitle(blog.title)
    setPath(blog.path)
    try {
      setEmoji(Base64.decode(blog.emoji))
    } catch (err){
      console.log(err.response)
    }
    setAuthor(blog.author)
    setDate(blog.date)
    setHero(blog.hero)
    if(blog.content){
      let contents = JSON.parse(blog.content)
      let mapped = contents.map( item => {
          if(item.type == 'para') {
            return {type : 'para', content : EditorState.createWithContent(convertFromRaw(item.content))}
          } else {
            return item
          }
      })
      setBlogContents(mapped)
    }
  } catch (err){
    console.log(err.response)
  }
}

const sendSubscribeMessage = async () => {
  if(!email) {
    setStatus('ERROR')
    return
  }
  try {
    await Axios.post("/api/public/newsletter/subscribe", {
      email: email
    });
    setStatus('SENT')
  } catch (err) {
    setStatus('ERROR')
  }
}

const save = async() =>{

  try {
    await Axios.post("/api/business/blog/update", {
      path: path,
      title : title,
      hero : hero,
      date: date,
      author: author,
      emoji : Base64.encode(emoji),
      content : JSON.stringify(blogContents.map( item => {
        if(item.type == 'image') {
           return item
        } else if(item.type == 'para') {
          return {type : 'para', content: convertToRaw(item.content.getCurrentContent()) }
        }else if(item.type == 'video') {
          return item
        }
      })) 
  }, {
      headers: { Authorization: "Bearer " + loggedInUser.token },
    });
    history.push(`/blog/${path}`);
  } catch (err) {
    if(err && err.response && err.response.status == 401 ){
      dispatch(logoutUser())
    }
  }
}

const onImageChange = (index, value) => {
  let newArr = [...blogContents];
  newArr[index] = {type: 'image', content: value}
  setBlogContents(newArr)
}

const onVideoChange = (index, value) => {
  let newArr = [...blogContents];
  newArr[index] = {type: 'video', content: value}
  setBlogContents(newArr)
}


const onImageCationChange = (index, value) => {
  let newArr = [...blogContents];
  newArr[index] = {type: 'image', content: newArr[index].content, caption: value}
  setBlogContents(newArr)
}

const onParaChange = (index, value) => {
  let newArr = [...blogContents];
  newArr[index] = {type: 'para', content: value}
  setBlogContents(newArr)
}

    return (
        <React.Fragment>
          <Helmet>
            <title>Blog</title>
          </Helmet>
          
          {/* Importing Navbar */}
          <NavbarPage navItems={[
                  { id: 1, idnm: "home", navheading: "Home", link:"/" }
              ]} navClass={"navbar-custom sticky sticky-dark " + navClass} imglight={false} hasBorder={true}/>
              <div className="side-by-side">
                <div className="container mb-5" style={ { maxWidth:'680px', backgroundColor:'#fefefa'}}>
                  <div id="blog-header">
                    <img className="center-cropped " src={hero} alt="Italian Trulli"></img>
                      <div id="blog-header-emoji">{emoji}</div>
                  </div>
                  <h1>{title}</h1>
                  { blogContents && blogContents.map((item, index) => { 
                      if(item.type === 'image'){
                        return  <figure>
                        <Zoom
                          image={{
                            src: item.content,
                            className: 'img',
                            style: { width: '100%' }
                          }}
                          zoomImage={{
                            src: item.content
                          }}>
                        </Zoom>              
                        { item.caption && <figcaption>{item.caption}</figcaption> }
                      </figure>
                      } else if(item.type === 'para'){
                        return <div className='editor-content' dangerouslySetInnerHTML={{__html: draftToHtml(convertToRaw(item.content.getCurrentContent())) }}></div> 
                      } else if(item.type === 'video'){ 
                        return <div className="video-container mt-5 mb-5" >
                          <iframe width="560" height="315" src={`https://www.youtube.com/embed/${item.content}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      }
                  })}

                  {date &&  <div className="mt-5 newsletter">
                      <Container>
                        <Row className="justify-content-center">
                            <Col xl={10} lg={10}>
                                <div className="text-center">
                                    <div className="subscribe">
                                        <Form>
                                            <Row>
                                              <Col md={12}>
                                                  <Label>Get these articles mailed to you every time we publish.</Label>
                                              </Col>
                                            </Row>
                                            <Row>
                                                { status === "SENT" && <Col md={12}>
                                                    <Label className="text-success">Sent Successfully!</Label>
                                                </Col>}
                                                { status === "ERROR" && <Col md={12}>
                                                    <Label className="text-danger">{error ? error : 'Something went wrong'}</Label>
                                                </Col>
                                                }
                                                <Col md={8}>
                                                    <div>
                                                        <Input onChange={e => setEmail(e.target.value)} type="text" className="form-control" placeholder="Enter your E-mail address" value={email}/>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mt-3 mt-md-0">
                                                        <Button onClick={ m => sendSubscribeMessage()} color="primary" block>Subscribe</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                      </Container>
                    </div> }
                    
                </div>
                { option && <div id='edit-board'>
                  <div id="component-group">
                      <div class="widget-button"><div onClick={addPara}>Para</div></div>
                      <div class="widget-button"><div onClick={addImage}>Image</div></div>
                      <div class="widget-button"><div onClick={addVideo}>Video</div></div>
                      <div class="widget-button"><div onClick={save}>Save</div></div>
                  </div>
                  <div id='editable-components'>
                    <div className="just-flex">
                      <strong>Path:</strong>
                      <EdiText type="text" value={path} onSave={setPath} />
                    </div>
                    <div className="just-flex">
                      <strong>Author:</strong>
                      <EdiText type="text" value={author} onSave={setAuthor} />
                    </div> 
                    <div className="just-flex">
                      <strong>Date:</strong>
                      <EdiText type="date" value={date} onSave={setDate} />
                    </div>
                    <div className="just-flex">
                      <strong>Hero Image:</strong>
                      <EdiText type="url" value={hero} onSave={setHero} />
                      </div>
                    <div className="just-flex">
                      <strong>Emoji:</strong>
                      <EdiText type="text" value={emoji} onSave={setEmoji} /></div>
                    <div className="just-flex">
                      <strong>Title:</strong>
                      <EdiText type="text" value={title} onSave={setTitle} />
                    </div> 
                    { blogContents && blogContents.map((content, index) => { 
                        if(content.type === 'image'){
                          return  <div>
                                    <div className="just-flex">
                                      <strong>Image Url:</strong>
                                      <EdiText type="url" value={blogContents[index].content} onSave={(val) => onImageChange(index, val)}/>
                                    </div> 
                                    <div className="just-flex">
                                      <strong>Caption:</strong>
                                      <EdiText type="text" value={blogContents[index].caption} onSave={(val) => onImageCationChange(index, val)}/>
                                    </div>
                                  </div>
                        } else if(content.type === 'para'){
                          return  <Editor
                                    editorState={blogContents[index].content}
                                    wrapperClassName={'edit-wrapper'}
                                    editorClassName="demo-editor"
                                    onEditorStateChange={(val) => onParaChange(index, val)}/>
                        } else if(content.type === 'video'){
                          return  <div>
                                    <div className="just-flex">
                                      <strong>Video Id:</strong>
                                      <EdiText type="url" value={blogContents[index].content} onSave={(val) => onVideoChange(index, val)}/>
                                    </div> 
                                  </div>
                        }
                     })}
                  </div>
                </div>}
              </div>
        </React.Fragment>
    )
}

export default BlogT;