import React, { useEffect, useState }  from "react";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TextField, Button, Typography, Select, MenuItem, FormControl, InputLabel} from '@material-ui/core';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faSort } from '@fortawesome/free-solid-svg-icons'

import { makeStyles } from '@material-ui/core/styles';

import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../auth/UserSlice";

import DateFnsUtils from '@date-io/date-fns';

import moment from 'moment'


import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import "./PickUpSchedule.css"

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
    },
    scrollable: {
      overflow: 'scroll',
      height: '100%'
    }
  }));

  const columns = [
    { id: 'index', label: '', minWidth: 10 },
    { id: 'clientId', label: 'Client', minWidth: 60 },
    { id: 'accountantMobile', label: 'Mobile Number', minWidth: 40 , isSortable: true },
    { id: 'deadline', label: 'Deadline', minWidth: 40},

    { id: 'pickupAddress', label: 'Pick-up address', minWidth: 40},
    { id: 'pickupDate', label: 'Pickup Date', minWidth: 40},
    { id: 'messages', label: 'Comment', minWidth: 100},
    { id: 'state', label: 'State', minWidth: 60},
    {
      id: 'delete',
      label: '',
      minWidth: 20,
      align: 'center'
    }
];

const PickUpSchedule = ({firmId, clients}) => {

    const classes = useStyles();
    const [ selectedClient, setSelectedClient ] = useState(clients[0].id)
    const [ selectedRow, setSelectedRow] =  useState(0)
    const [ phoneNumber, setPhoneNumber ] = useState(null)
    const [ address, setAddress ] = useState(null)
    let date = new Date();
    date.setDate(date.getDate() + 7);
    const [selectedDate, setSelectedDate] = React.useState(date);

    const dispatch = useDispatch();

    const userState = useSelector((state) => state.user);
    const { loggedInUser } = userState;

    let exampleDate = new Date();
    exampleDate.setDate(exampleDate.getDate() + 8);
    let examplePickupDate = new Date();
    examplePickupDate.setDate(examplePickupDate.getDate() + 4);

    const [ schedules, setSchedules ] = useState([])

    const handleClientChange = (event) => {
        setSelectedClient(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
      };

    const scheduleNewPickup = async () => {
        let data = {
            clientId : selectedClient,
            deadline : moment(selectedDate, 'YYYY-MM-DD'),
            firmId : firmId,
            clientMobile: phoneNumber,
            state: "requested"
        }
        try {
            let result = await Axios.post("/api/accountant/schedule", data, {
              headers: { Authorization: "Bearer " + loggedInUser.token },
            });
            console.log(result)
            fetchScheduledPickups(firmId)
          } catch (err) {
            if(err && err.response && err.response.status == 401 ){
              dispatch(logoutUser())
            }
          }
    };

    const onSortSelected = (id) => {
        
    };

    const onRowSelected = (index) => {
        setSelectedRow(index)
    };

    const onDeleteSelected = async (index) => {
        let data = {
            "scheduleId" : schedules[index].id
        }
        try {
            await Axios.post("/api/accountant/schedule/delete", data, {
              headers: { Authorization: "Bearer " + loggedInUser.token },
            });
            fetchScheduledPickups(firmId)
          } catch (err) {
            if(err && err.response && err.response.status == 401 ){
              dispatch(logoutUser())
            }
          }
          
    };

    const fetchScheduledPickups  = async (firmId) => {
        try {
            const response = await Axios.get(`/api/accountant/schedules?firmId=${firmId}`, {
              headers: { Authorization: "Bearer " + loggedInUser.token },
            });
            setSchedules(response.data)
          } catch (err){
            if(err && err.response && err.response.status == 401 ){
              dispatch(logoutUser())
            }
            console.log(err.response)
          }
    }

    useEffect(() => {
        fetchScheduledPickups(firmId)
    }, []);

    return (
        <div>
            <div id="new_schdule_container">
                <div id="new_schdule_row">
                    <FormControl id="client_field_pickup" size="small" variant="outlined" className={classes.formControl}>
                        <InputLabel>Client</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={selectedClient}
                            onChange={handleClientChange}
                            label="Client"
                            >
                                { clients && clients.length > 0 && clients.map((value) => (
                                    <MenuItem value={value.id}>{value.name}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>

                    <TextField className="phone_field" label="Client Mobile Number" size="small" variant="outlined" onChange={handlePhoneNumberChange}  />

                    <div id="pick_up_date">                        
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className="mt-0 mb-0"
                                disableToolbar
                                size="small"
                                variant="inline"
                                format="dd/MMM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Deadline"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    <Button id="schedule_button"  variant="outlined" color="primary" onClick={scheduleNewPickup}>
                        Schedule
                    </Button>
                </div>
            </div>
            <div id="old_schdules">
                <TableContainer className={classes.scrollable}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                        {column.isSortable && <FontAwesomeIcon className="ml-2" icon={faSort} onClick={ e => onSortSelected(column.id) }/>}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                        {   schedules && schedules.map((row, index) => (
                                <TableRow key={row.index} className={selectedRow == index ? 'bg-selected-sc-group' : 'bg-normal-sc-group'} tabIndex={-1}  onClick={ e => onRowSelected(index)}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            >
                                                {
                                                    (column.id == 'index') && index+1
                                                }
                                                {
                                                    (column.id == 'pickupDate' || column.id == 'deadline') && row[column.id] && moment(row[column.id]).format('YYYY-MM-DD')
                                                }
                                                {
                                                    (column.id != 'clientId' && column.id != 'delete' && column.id != 'index' && column.id != 'pickupDate' && column.id != 'deadline') && value
                                                }
                                                {
                                                    column.id === 'clientId' && clients.find(x => x.id === row['clientId']) && clients.find(x => x.id === row['clientId'])['name']
                                                }
                                                {
                                                    column.id === 'delete' && <FontAwesomeIcon id={`${index}_${column.id}`} icon={faTrashAlt} onClick={ e => onDeleteSelected(index) }/>
                                                }
                                        </TableCell>)
                                    })}
                                </TableRow>
                            ))
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>)
}


export default PickUpSchedule;