import React, { useMemo, useEffect, useState } from "react";
import { logoutUser } from "../../auth/UserSlice";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { Spinner } from 'reactstrap';

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";

import "./SplitForm.css"

const useOptions = () => {
  const fontSize = "16px";
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const SplitForm = ({ productId, coupon, firmId, onSuccess, onError, value }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const userState = useSelector((state) => state.user);
  const { loggedInUser } = userState;
  const [isButtonEnabled, setIsButtonEnabled] = useState(true)

  const handleSubmit = async event => {
    event.preventDefault();


    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setIsButtonEnabled(false)


    try {
      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement)
      });
  
      if ( payload.error || !payload.paymentMethod ) {
          onError(payload.error.message)
      } else {
        let params = {  'payment_method': payload.paymentMethod.id, 'firm_id': firmId, 'product_id':productId, 'value' : value}
        if(coupon){
          params['coupon_code'] = coupon.code
        }
          const res = await Axios.post('/api/subscription/create', params , {
              headers: { Authorization: "Bearer " + loggedInUser.token },
          });
          const {client_secret, status, db_id} = res.data;
          console.log(`${db_id} - ${status} ${client_secret}`)
  
          if (status === 'requires_action' || status === 'requires_source_action') {
              let confirmation = await stripe.confirmCardPayment(client_secret)
              console.log(confirmation)
              const confirmRes = await Axios.post('/api/subscription/confirm', {sub_db_id: db_id}, {
                headers: { Authorization: "Bearer " + loggedInUser.token },
              });
              if(confirmRes.data.status == 'active'){
                onSuccess()
              } else {
                onError('Something went wrong')
              }
          } else if (status === 'active') {
             onSuccess()
          } else {
            onError('Something went wrong')
          }
      }
    } catch (error) {
      onError(error.message)
    }
    setIsButtonEnabled(true)
  };
  

  return (
    <form onSubmit={handleSubmit}>
        <div id="card-combo-vert">
            <label className="str-label">
                Card number
                <CardNumberElement
                    options={options}/>
            </label>
            <label className="str-label">
                Expiration date
                <CardExpiryElement
                    options={options}/>
            </label>
            <label className="str-label">
                CVC
                <CardCvcElement
                    options={options}/>
            </label>
            <div id="button-holder">
              <button className="str-button" disabled={!stripe || !isButtonEnabled} onClick={ e => handleSubmit(e)}>
                  Subscribe
              </button>  
              {!isButtonEnabled && <div id="spinner-holder"><Spinner color="success"/> </div>}
            </div>
        </div>
    </form>
  );
};

export default SplitForm;
