import React, { Component } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';

//Import Section Title
import SectionTitle from "../common/section-title";

import ISSteps from "./Steps/issteps"

//Import Pricing
import PricingBox from './pricing-box';

const freeMonth =  { 
                title : "Free", 
                price : 0, 
                group: "",
                duration : "Quarter",
                frequency: "Upgrade anytime",
                value: 0,
                product: "",
                features : [
                    { title : "Invoices", value : "100/month" },
                    { title : "Invoices Kept For", value : "3 months" },
                    { title : "24x7 Support", value : "No" },
                    { title : "Invoice Pick up", value : "No" },
                    { title : "Processor Accuracy", value : "Good" },
                ]
            }
            const basicMonth =  { 
                title : "Small Firms", 
                price : 180, 
                duration : "Quarter (AUD)",
                group: "Basic",
                value: 180,
                frequency: "charged quarterly",
                product: "price_1JRU1vA2wFZ5bSyJXRIolX0w",
                features : [
                    { title : "Invoices", value : "3000/quarter" },
                    { title : "Invoices Kept For", value : "5 years"},
                    { title : "24x7 Support", value : "Yes" },
                    { title : "Invoice Pick up", value : "No" },
                    { title : "Processor Accuracy", value : "High" },
                ]
            }
            const premiumMonth =  { 
                title : "Medium Firms", 
                price : 360, 
                value: 360,
                group: "Basic",
                duration : "Quarter (AUD)",
                frequency: "charged quarterly",
                product: "price_1JRU1vA2wFZ5bSyJ4I6m3C43",
                features : [
                    { title : "Invoices", value : "6000/quarter" },
                    { title : "Invoices Kept For ", value : "5 Years"},
                    { title : "24x7 Support", value : "Yes" },
                    { title : "Invoice Pick up", value : "No" },
                    { title : "Processor Accuracy", value : "High" },
                ]
            }
            const platinumMonth =  { title : "Large Firms", 
                price : 600, 
                value: 600,
                group: "Basic",
                duration : "Quarter (AUD)",
                frequency: "charged quarterly",
                product: "price_1JRU1vA2wFZ5bSyJxYHQmMmS",
                features : [
                    { title : "Invoices", value : "10000/quarter" },
                    { title : "Invoices Kept For ", value : "5 Years"},
                    { title : "24x7 Support", value : "Yes" },
                    { title : "Invoice Pick up", value : "No" },
                    { title : "Processor Accuracy", value : "High" },
                ]
            }

            const basicYear = {   title : "Small Firms", 
                price : 970, 
                value: 970,
                duration : "Quarter (AUD)",
                group: "Complete",
                frequency: "charged quarterly",
                product: "price_1JRU1fA2wFZ5bSyJBzYwDtcU",
                features : [
                        { title : "Invoices", value : "3000/quarter" },
                        { title : "Invoices Kept For ", value : "5 years" },
                        { title : "24x7 Support", value : "Yes" },
                        { title : "Invoice Pick ups", value : "10/quarter" },
                        { title : "Processor Accuracy", value : "99.9%" },
                    ]
            }
            const premiumYear = { title : "Medium Firms", 
                price : 1870, 
                value: 1870,
                duration : "Quarter (AUD)",
                group: "Complete",
                frequency: "charged quarterly",
                product: "price_1JRU1fA2wFZ5bSyJaKgkU6sq",
                features : [
                    { title : "Invoices", value : "6000/quarter" },
                    { title : "Invoices Kept For ", value : "5 Years" },
                    { title : "24x7 Support", value : "Yes" },
                    { title : "Invoice Pick ups", value : "20/quarter" },
                    { title : "Accuracy", value : "99.9%" },
                ]
            }
            const platinumYear = { 
                title : "Large Firms", 
                price : 2770, 
                value: 2770,
                group: "Complete",
                duration : "Quarter (AUD)",
                frequency: "charged quarterly",
                product: "price_1JRU1fA2wFZ5bSyJi4s88smq",
                features : [
                    { title : "Invoices", value : "10000/quarter" },
                    { title : "Invoices Kept For ", value : "5 Years" },
                    { title : "24x7 Support", value : "Yes" },
                    { title : "Invoice Pick ups", value : "30/quarter" },
                    { title : "Accuracy", value : "99.9%" },
                ]
            }


class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pricings1 : props.isInDashboard ? [
                basicMonth,
                premiumMonth,
                platinumMonth
            ] : [
                freeMonth,
                basicMonth,
                platinumMonth,
            ],
            pricings2 : [
                basicYear ,
                premiumYear ,
                platinumYear
             ],
            activeTab: '1',
        }
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    
    render() {
        return (
            <React.Fragment>
        <section className={this.props.isInDashboard ? "pt-3 section bg-light" :  "section bg-light"} id="pricing">
            <Container>
            {!this.props.isInDashboard && <SectionTitle
                subtitle="Pricing"
                title="Choose your Plan"
                desc="Evaluate our product with the Free plan and then upgrade anytime."
            />}
                { !this.props.coupon && <Row>
                    <Col lg={12}>
                        <div className={this.props.isInDashboard ? "text-center mb-0" : "text-center mb-4"}>
                            <Nav pills className={this.props.isInDashboard ? "pricing-nav-tabs mb-2" : "pricing-nav-tabs mb-4"}>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} >Basic</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>Complete</NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </Col>
                </Row>}

                <ISSteps indexes={this.state.activeTab == 1 ? [2,4] : [0,1,2,3,4,5]}/>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <PricingBox coupon={this.props.coupon} pricings={this.state.pricings1} isInDashboard={this.props.isInDashboard} onProductSelected={this.props.onProductSelected}/>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <PricingBox coupon={this.props.coupon} pricings={this.state.pricings2} isInDashboard={this.props.isInDashboard}  onProductSelected={this.props.onProductSelected}/>
                        </Row>
                    </TabPane>
                </TabContent>
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default Pricing;
export const pricings = [freeMonth, basicMonth, premiumMonth, platinumMonth, basicYear, premiumYear, platinumYear].reduce(function(map, obj) {
    map[obj.product] = obj;
    return map;
}, {})
