import React from 'react';
import './CalenderPage.css'
import moment from 'moment'

const CalenderPage = ({date}) => { 

    let mom = moment(date)

    return (
        <div className="calendar-base">
            <div className="calender-year">
                {mom.format('YYYY MMM').toUpperCase()}
            </div>
            <div className="calender-divider"/>
            <div className="calender-date">
            {mom.date()}
            </div>
            <div className="calender-day">{mom.format('ddd hh:mm').toUpperCase()}</div>
        </div>
    )
};

export default CalenderPage;

export const CalenderPageMini = ({date}) => { 

    let mom = moment(date)

    return (
        <div className="calendar-base-mini">
            <div className="calender-year-mini">
                {mom.format('YYYY MMM').toUpperCase()}
            </div>
            <div className="calender-divider-mini"/>
            <div className="calender-date-mini">
            {mom.date()}
            </div>
            <div className="calender-day-mini">{mom.format('ddd hh:mm').toUpperCase()}</div>
        </div>
    )
};

