
import React, { useEffect, useState }  from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import deliveryMan from "./delivery-man.png";
import scanner from "./scanner.png";
import processor from "./processor.png";
import review from "./review.png";
import store from "./store.png";
import Tooltip from '@material-ui/core/Tooltip';

import "./issteps.css"

const ISSteps = ({indexes}) =>  {

        return (
                <div className="mt-4" id="isstep_progress">
                        <div id="isstep_row">
                                <div className="isstep_col">
                                        <Tooltip title={indexes.includes(0) ? "We will visit your clients and pick up the invoices." : "You will collect invoices from clients" } placement="top">
                                                <img className={indexes.includes(0) ? "" : "image-grayscale"} src={deliveryMan}></img>
                                        </Tooltip><br/>
                                        <FontAwesomeIcon className={indexes.includes(0) ? "isi-green" : "isi"} icon={ indexes.includes(0) ? faCheck : faTimes}/>
                                        <p className={indexes.includes(0) ? "p-colored" : ""}>Pick Up</p>
                                </div>
                                <div className="isstep_col">
                                        <Tooltip title={indexes.includes(1) ? "We will scan and upload the invoices to invoice scanner." : "You will scan and upload the invoices to invoice scanner" } placement="top">
                                                <img className={indexes.includes(1) ? "" : "image-grayscale"}  src={scanner}></img>
                                        </Tooltip><br/>
                                        <FontAwesomeIcon className={indexes.includes(1) ? "isi-green" : "isi"} icon={indexes.includes(1) ? faCheck : faTimes}/>
                                        <p className={indexes.includes(1) ? "p-colored" : ""}>Scan</p>
                                </div>
                                <div className="isstep_col">
                                        <Tooltip title="Our OCR engines and AI models will process the invoices and create highly accurate GST worksheet." placement="top">
                                                <img className={indexes.includes(2) ? "" : "image-grayscale"}  src={processor}></img>
                                        </Tooltip><br/>
                                        <FontAwesomeIcon className={indexes.includes(2) ? "isi-green" : "isi"} icon={indexes.includes(2) ? faCheck : faTimes}/>
                                        <p className={indexes.includes(2) ? "p-colored" : ""}>Process</p>
                                </div>
                                <div className="isstep_col">
                                        <Tooltip title={indexes.includes(3) ? "We will go through the GST worksheet to find inaccuracies and correct them." : "You will review the GST worksheet to find inaccuracies and correct them."} placement="top">
                                                <img className={indexes.includes(3) ? "" : "image-grayscale"}  src={review}></img>
                                        </Tooltip><br/>
                                        <FontAwesomeIcon className={indexes.includes(3) ? "isi-green" : "isi"} icon={indexes.includes(3) ? faCheck : faTimes}/>
                                        <p className={indexes.includes(3) ? "p-colored" : ""}>Review</p>
                                </div>
                                <div className="isstep_col">
                                        <Tooltip title="We will store the scanned copies of the invoices in the cloud for five years. You can access them within seconds." placement="top">
                                                <img className={indexes.includes(4) ? "" : "image-grayscale"}  src={store}></img>
                                        </Tooltip><br/>
                                        <FontAwesomeIcon className={indexes.includes(4) ? "isi-green" : "isi"}  icon={indexes.includes(4) ? faCheck : faTimes}/>
                                        <p className={indexes.includes(4) ? "p-colored" : ""}>Store</p>
                                </div>
                                <div className="isstep_col">
                                        <Tooltip title={indexes.includes(5) ? "We will return the invoices back to the client." : "You will return the invoices back to the client."} placement="top">
                                                <img className={indexes.includes(5) ? "" : "image-grayscale"}  src={deliveryMan}></img>
                                        </Tooltip><br/>
                                        <FontAwesomeIcon className={indexes.includes(5) ? "isi-green" : "isi"} icon={indexes.includes(5) ? faCheck : faTimes}/>
                                        <p className={indexes.includes(5) ? "p-colored" : ""}>Return</p>
                                </div>
                        </div>
                </div>
        )
}

export default ISSteps;