import React, { useEffect, useState, useCallback, useRef }  from "react";
import NavBar from '../../nav/NavigationBar'
import { useHistory } from "react-router-dom";
import {useParams} from "react-router-dom";
import Axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import "./ScanGroup.css"

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Autocomplete from 'react-autocomplete'

import { logoutUser } from "../../auth/UserSlice";
import { useSelector, useDispatch } from "react-redux";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faSort } from '@fortawesome/free-solid-svg-icons'

import JSZip from 'jszip';
import queryString from 'query-string';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { Button, Spinner, Label } from 'reactstrap';

import NavbarPage from "../../home/Navbar/Navbar_Page";

import ScanGroupInvoiceViewer from "./ScanGroupInvoiceViewer"

import $ from 'jquery'

import { useToasts } from 'react-toast-notifications'
import Loading from 'react-fullscreen-loading';

import ContentEditable from 'react-contenteditable'

import { Helmet } from 'react-helmet' 

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const UI_FAILED = "   "

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  scrollable: {
    overflow: 'scroll',
    height: '100%'
  },
  formControl: {
    minWidth: 220,
    fontSize: '.875rem'
  },
  selectEmpty: {
  },
  selectMenu:{
    fontSize: '.875rem',
    padding: '.6rem',
    paddingTop: '.42rem',
    paddingBottom: '.34rem'

  },
}));

const pdfsPerSet = 10

function createData(index, paymentDate, invoiceNumber, abn, supplier, category, total, gst) {
  return {index, paymentDate, invoiceNumber, abn, supplier, category , total , gst };
}

const columns = [
  { id: 'index', label: '', minWidth: 10 },
  { id: 'paymentDate', label: 'Date', minWidth: 40 , isSortable: true },
  { id: 'invoiceNumber', label: 'Inv No.', minWidth: 60 },
  { id: 'abn', label: 'ABN', minWidth: 40 },
  { id: 'supplier', label: 'Supplier', minWidth: 100, isSortable: true },
  { id: 'category', label: 'Category', minWidth: 60, isSortable: true },
  {
    id: 'total',
    label: 'Total',
    minWidth: 40,
    align: 'right',
    format: (value) => Math.max(0,value).toFixed(2),
    isSortable: true
  },
  {
    id: 'gst',
    label: 'GST',
    minWidth: 40,
    align: 'right',
    format: (value) => Math.max(0,value).toFixed(2),
    isSortable: true
  },
  {
    id: 'delete',
    label: '',
    minWidth: 20,
    align: 'center'
  },
];

const selectableColumnsInEditMode = ['paymentDate','invoiceNumber', 'abn', 'supplier', 'category','total', 'gst']
const selectableColumnsInNormalMode = ['paymentDate','invoiceNumber', 'supplier', 'category','total', 'gst']

function useStateWithCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // mutable ref to store current callback

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store passed callback to ref
    setState(state);
  }, []);

  useEffect(() => {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}

const ScanGroup = () => {
  const userState = useSelector((state) => state.user);
  const { loggedInUser } = userState;
  const history = useHistory();
  const [ status, setStatus ] = useState(null)
  const [ scanGroup, setScanGroup ] = useState(null)
  const [ invoices, setInvoices ] = useState(null)
  const [ categories, setCategories ] = useState([])
  const [ selectedImage, setSelectedImage ] = useState(null)
  const [ selectedInvoice, setSelectedInvoice ] = useState(0)
  const [ selectedColumn, setSelectedColumn ] = useState('paymentDate')
  const [ loadingText, setLoadingText ] = useState('loading...')
  const [ isEditing, setIsEditing ] = useStateWithCallback(false)
  const [ anomalies, setAnomalies] = useState([])
  const [ focusedElement, setFocusedElement] = useState(null)
  const dispatch = useDispatch();
  var timerId = 0;
  const [progress, setProgress] = useState(0);
  const [highlighterEnabled, setHighlighterEnabled] = useState(true);
  const [timerTrigger, triggerTimer] = useState(null)

  const [ dirtyValues, setDirtyValues] = useState({})
  const [ gstLoopCount, setGstLoopCount ] = useState(0)

  const [ isDownloading, setDownloading ] = useState(false)

  const [ searchFocused, setSearchFocused ] = useState(null)

  const { addToast } = useToasts()
  const classes = useStyles();

  let { groupIdOrQuarter } = useParams()
  const queryParams = queryString.parse(groupIdOrQuarter)

  const [ isSaving, setIsSaving ] = useState(false)
  const [ isRetrying, setIsRetrying ] = useState(false)


  var lastStatus = "NOT_STARTED"

  const fetchBusinessCategories = async () => {
    try {
      let response = await Axios.get("/api/business/category/all", {
        params: { canInvoice : 1 } ,
        headers: { Authorization: "Bearer " + loggedInUser.token }
      });
      let cats = response.data
      setCategories(cats)
    } catch (error) {
      console.log(error.message)
    }
  }


  const loadWithDelayTest = (invoices, index)=> {
    setTimeout(function() { 
      console.log(invoices.length)
      if(index >= invoices.length) return
      setInvoices(invoices.filter((invoice,idx) => idx <= index))
      setSelectedInvoice(index)
      setSelectedImage(invoices[index].signedImage)
      console.log(index)
      loadWithDelayTest(invoices, index+1)
    }, index == 0 ? 8000 : 2000);
    if(index == 0){
      loadProgressTest(0)
    }
  }

  const loadProgressTest = (count)=>{
    if(count <= 100){
      setProgress(count)
      setTimeout(function() {
        loadProgressTest(count+1)
      },500)
    }
  }

  /*
  includeRaw means the Raw Json file link for test case purposes.
  */
  const fetchScanDetails = async (data, includeImages, includeRaw) => {
    console.log("Fetch Scan Details ...")
    try {

      let params = { ...data,
        includeImages: includeImages,
        noChild: queryParams.noChild,
        includeRaw: includeRaw } 
      let response = await Axios.get("/api/accountant/scans/detail", {
        params: params,
        headers: { Authorization: "Bearer " + loggedInUser.token }
      });
      let group = response.data
      setScanGroup(group)
      setAnomalies(group.anomalies)

      let currentlySelectedInvoice = selectedInvoice != null ? selectedInvoice : 0
      if(group && group.invoices && group.invoices.length > currentlySelectedInvoice && group.invoices[currentlySelectedInvoice].signedImage){
        setSelectedImage(group.invoices[currentlySelectedInvoice].signedImage)
      }
      
      setLoadingText(group.status === 'COMPLETE' ? UI_FAILED : "loading...")

      var completedPercentage = group.percentage
      
      if(group.status === 'CROPPED_IMAGES_UPLOADED'){
        let updatedAtDate = Date.parse(group.updatedAt)
        let secondsElapsed = Math.floor((new Date() - updatedAtDate) / 1000)
        let timePerInvoice = 18
        let timeExpectedToComplete = Math.ceil(group.total/pdfsPerSet) * timePerInvoice * pdfsPerSet
        let percentage = Math.max(secondsElapsed/timeExpectedToComplete) * 80
        completedPercentage += percentage
      }

      if(group.status === "COMPLETE" && queryParams.delay){
        loadWithDelayTest(group.invoices,0)
        setStatus(null)
      } else {
        setProgress(completedPercentage)
        setInvoices(group.invoices)
        setStatus(group.status)
      }

      /*
      setStatus(group.status)
      setInvoices(group.invoices)
      setProgress(completedPercentage)*/
      

      if(group.status === "COMPLETE" || group.status === "FAILED"){
        clearInterval(timerId);
      } else {
        lastStatus = group.status
      }
      return group
    } catch(err) {
      clearInterval(timerId);
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log(err)
    }
  }

  const addMore = async () => {
    if(scanGroup && scanGroup.id){
      if(scanGroup.parent){
        history.push(`/scan/files/parentGroup=${scanGroup.parent}`);
        return 
      }
    }
    history.push(`/scan/files/parentGroup=${queryParams.groupId}`);
  } 

  function makeid(length) {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() *  charactersLength)));
    }
    return result.join('');
  }

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const invoiceToUrl = (invoice) => {
    let randomString = makeid(14)
    let insertionPoint = getRandomInt(9)
    let digits = `${invoice.id}`.length
   //console.log(`${randomString.substring(0, insertionPoint)}  vs  ${randomString}`)
    
    let magicLink = `${insertionPoint}${digits}${randomString.substring(0, insertionPoint)}${invoice.id}${randomString.substring(insertionPoint)}`
    return `https://www.invoicescanner.com.au/invoiceimage/${magicLink}`
  }

  const restart = async () => {
    setIsRetrying(true)
    try {
      await Axios.post("/api/accountant/scans/restart", { scanGroupId: scanGroup.id}, {
        headers: { Authorization: "Bearer " + loggedInUser.token },
      });
      fetchScanDetails(queryParams)
    } catch (err) {
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log("Update failed")
      setIsRetrying(false)
    } finally {
      setIsRetrying(false)
    }
    
  }

  const downloadCSV = async () => {
    setDownloading(true)
    let group = await fetchScanDetails(queryParams, true)
    var rows = []
    let herderRow = columns.map( c => c.label)
    herderRow.pop()
    herderRow.push("Invoice")
    rows.push(herderRow)

    if(group.invoices){
      let data = group.invoices.map( (o, index) => 
        [ 
          index+1,
          o.paymentDate ? (new Date(o.paymentDate)).toLocaleString("en-AU").split(',')[0] : loadingText, 
          o.invoiceNumber ? o.invoiceNumber : loadingText, 
          o.supplier ? o.supplier.abn : loadingText, 
          o.supplier ? (o.supplier.name ? o.supplier.name : (o.tempSupplierName ? o.tempSupplierName : loadingText)) : (o.tempSupplierName ? o.tempSupplierName : loadingText), 
          o.cat && o.cat.name ? o.cat.name : "",
          o.total ? Math.max(o.total,0) : loadingText, 
          o.gst === null ? loadingText : Math.max(0,o.gst),
          //o.signedImage ? `=HYPERLINK("${o.signedImage}", "Image")` : loadingText] )
          o.signedImage ? `=HYPERLINK("${invoiceToUrl(o)}", "Image")` : loadingText] )
      rows = rows.concat(data)
    }

    let csvBlob = convertToCsv(rows)

    var zip = new JSZip()
    zip.file(`scangroup_${group.id}.csv`,csvBlob)
    /*try {
      for (let index = 0; index < group.invoices.length; index++) {
        const invoice = group.invoices[index];
        let blob = await downloadFile(invoice.signedImage)
        zip.file(`image_${index+1}.jpeg`,blob)
      }
    } catch(err) {
      console.log()
    }*/
    let zippedBlobs = await zip.generateAsync({type:"blob"})
    saveBlobToDisk(zippedBlobs,`invoices_${group.id}.zip`)
    setDownloading(false)
  }

  const editInvoices = async () => {
    setIsEditing(true)
  }

  const cancelEditing = async () => {
    setIsEditing(false)

    let wasChanged = Object.keys(dirtyValues).length != 0

    setDirtyValues({})

    //Show loading
    if(wasChanged){
      window.location.reload();
    }
    //Hide loading

  }

  const saveInvoices = async () => {

    setIsEditing(false)

    let wasChanged = Object.keys(dirtyValues).length != 0
    if(!wasChanged){
      return
    }

    setIsSaving(true)


    var modifiedInvoices = []
    let invoiceMap = invoices.reduce((a,x) => ({...a, [x.id]: x}), {})


    for (const index in dirtyValues) {
      if (dirtyValues.hasOwnProperty(index)) {
        let invoice = invoiceMap[index]
        let invoiceInfo = {
          invoice_id : invoice.id,
          invoice_data : dirtyValues[index]
        }
        invoiceInfo['invoice_data']['wasModified'] = true
        if(invoiceInfo['invoice_data']['paymentDate']){
          var match = invoiceInfo['invoice_data']['paymentDate'].match(`([\\d]+)\\s*[\\s./-]([a-zA-Z0-9]+)\\s*[\\s./-]([\\d]+)`);
          if(match && match.length == 3) {
            invoiceInfo['invoice_data']['paymentDate'] = `${match[2]}/${match[1]}`
          }else if(match && match.length == 4) {
            invoiceInfo['invoice_data']['paymentDate'] = `${match[2]}/${match[1]}/${match[3]}`
          } else {
            invoiceInfo['invoice_data']['paymentDate'] = null
          }
        }
        if( dirtyValues[index]['supplier'] || dirtyValues[index]['abn']){
          let invoice_supplier = {
            abn: dirtyValues[index]['abn'] ? dirtyValues[index]['abn'].replace(/<[^>]*>?/gm, '') : null,
            name : dirtyValues[index]['supplier'].replace(/<[^>]*>?/gm, '')
          }
          invoiceInfo['invoice_supplier'] = invoice_supplier
        }
        modifiedInvoices.push(invoiceInfo)
      }
    }

    try {
      await Axios.post("/api/accountant/invoices/update", { invoices: modifiedInvoices}, {
        headers: { Authorization: "Bearer " + loggedInUser.token },
      });
      
      //window.location.reload();
      //setDirtyValues({})
    } catch (err) {
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log("Update failed")
    } finally {
      setIsSaving(false)
    }

  }

  const onCategoryFocus = (event) => {
    setFocusedElement(event.target.id)
  }

  const onCategoryEdited = (value, index) => {
    /*if(!isEditing){
      setIsEditing(true)
    }*/
    setInvoices(invoices.map( (k, i) => {
      if( i === index){
        var newInvoice = {...k}
        let cat = {name: value}

        if(!dirtyValues[newInvoice.id]){
          dirtyValues[newInvoice.id] = {}
        }
        dirtyValues[newInvoice.id]['cat'] = cat

        newInvoice.cat = cat
        return newInvoice
      } else{
        return k
      }
    }))
  }

  const onCategorySelected = ( value, index) => {
    /*if(!isEditing){
      setIsEditing(true)
    }*/
    setInvoices(invoices.map( (k, i) => {
      if( i === index){
        var newInvoice = {...k}
        let selectedCategory = categories.find(f => f.name.toLowerCase() === value.toLowerCase())
        var cat = {name: value}

        if(!dirtyValues[newInvoice.id]){
          dirtyValues[newInvoice.id] = {}
        }

        if(selectedCategory){
          cat = selectedCategory
          dirtyValues[newInvoice.id]['category'] = selectedCategory.id
        }

        dirtyValues[newInvoice.id]['cat'] = cat

        newInvoice.cat = cat
        return newInvoice
      } else{
        return k
      }
    }))
  }


  const deleteInvoice = async (index) => {
    setIsSaving(true)
    try {
      let invoiceId = invoices[index].id
      delete dirtyValues[invoiceId]
      await Axios.post("/api/accountant/invoice/delete", { invoice_id: invoiceId}, {
        headers: { Authorization: "Bearer " + loggedInUser.token },
      });
      setSelectedInvoice(Math.min(selectedInvoice, invoices.length - 2))
      setInvoices(invoices.filter(k => k.id !== invoiceId))
      setScanGroup({...scanGroup, invoices: scanGroup.invoices.filter(k => k.id !== invoiceId) })
      //window.location.reload();
    } catch (err) {
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }
      console.log(`Delete failed ${err.message}`)
    } finally {
      setIsSaving(false)
    }
    
  }

  const onSortSelected = async (id)=>{
    if(id === 'paymentDate'){
      setInvoices([...invoices].sort(function(a,b){
        let aDate = dirtyValues[a.id] && dirtyValues[a.id].paymentDate ? dirtyValues[a.id].paymentDate : a.paymentDate ? a.paymentDate : 0
        let bDate = dirtyValues[b.id] && dirtyValues[b.id].paymentDate ? dirtyValues[b.id].paymentDate : b.paymentDate ? b.paymentDate : 0
        return (new Date(aDate))-(new Date(bDate));
      }))
    }else if(id === 'supplier') {
      setInvoices([...invoices].sort(function(a,b){
        let aName = dirtyValues[a.id] && dirtyValues[a.id].supplier ? dirtyValues[a.id].supplier : (a.supplier ? a.supplier.name : (a.tempSupplierName ? a.tempSupplierName : loadingText))
        let bName = dirtyValues[b.id] && dirtyValues[b.id].supplier ? dirtyValues[b.id].supplier : (b.supplier ? b.supplier.name : (b.tempSupplierName ? b.tempSupplierName : loadingText))
        return aName.localeCompare(bName);
      }))
    }else if(id === 'category') {
      setInvoices([...invoices].sort(function(a,b){
        let aName = dirtyValues[a.id] && dirtyValues[a.id].cat ? dirtyValues[a.id].cat.name : a.cat && a.cat.name ? a.cat.name : ''
        let bName = dirtyValues[b.id] && dirtyValues[b.id].cat ? dirtyValues[b.id].cat.name : b.cat && b.cat.name ? b.cat.name : ''
        return aName.localeCompare(bName);
      }))
    }else if(id === 'total') {
      setInvoices([...invoices].sort(function(a,b){
        let aName = dirtyValues[a.id] && dirtyValues[a.id].total ? dirtyValues[a.id].total : a.total ? a.total : 0
        let bName = dirtyValues[b.id] && dirtyValues[b.id].total ? dirtyValues[b.id].total : b.total ? b.total : 0
        return aName - bName;
      }))
    }else if(id === 'gst') {
      setInvoices([...invoices].sort(function(a,b){
        let aGst = dirtyValues[a.id] && dirtyValues[a.id].gst ? dirtyValues[a.id].gst :  a.gst ? a.gst : 0
        let bGst = dirtyValues[b.id] && dirtyValues[b.id].gst ? dirtyValues[b.id].gst : b.gst ? b.gst : 0
        return aGst - bGst;
      }))
    }
  }

  const onDeleteSelected = async (index)=>{
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteInvoice(index)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const downloadFile = async (url) => {
    let promise = new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
              resolve(xhr.response);
          } else {
              reject({
                  status: this.status,
                  statusText: xhr.statusText
              });
          }
      };
      xhr.onerror = function () {
          reject({
              status: this.status,
              statusText: xhr.statusText
          });
      };
      xhr.send();
    });
    return await promise
}

function selectElementContents(el) {
  var range = document.createRange();
  range.selectNodeContents(el);
  var sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
}

const onTextFocus = (id)=>{
  setFocusedElement(id)
  selectElementContents(document.getElementById(id));
}

  const downloadTestCase = async () => {
    setDownloading(true)
    let group = await fetchScanDetails(queryParams, true, true)
    var zip = new JSZip()
    try {
      let sorted = group.invoices.sort(function(a,b){
        return a.id - b.id
      })
      for (let index = 0; index < sorted.length; index++) {
        const invoice = group.invoices[index];
        let blob = await downloadFile(invoice.signedImage)
        let absoluteIndex = parseInt(invoice.resourcePrefix.replace('scan_',''))
        zip.file(`scangroup_${group.id}_${Math.floor(absoluteIndex/pdfsPerSet)}_${absoluteIndex%pdfsPerSet}.jpeg`,blob)
      }

      if(group.raw){
        for (let index = 0; index < group.raw.length; index++) {
          const url = group.raw[index];
          let blob = await downloadFile(url)
          zip.file(`scangroup_${group.id}_${index}.zip`,blob)
        }
      }

      if(group.raw){
        var allTests = `
var assert = require('assert');

const extractAllInvoices = require("../app/controllers/image_processing").extractAllInvoices
const findDateFromString = require("../app/controllers/image_processing").findDateFromString

const fs = require('fs');
var expect = require('chai').expect


var data;

const jsonForInvoiceCommMulti = async (folder, file) => {
  let fileName = \`/Users/bavan/Documents/Programming/Personal/InvoiceScanner/InvoiceScanner-Backend/\${folder}/\${file}.json\`
  let rawdata =  fs.readFileSync(fileName)
  let invoice = JSON.parse(rawdata);
  return await extractAllInvoices(invoice)
} `
        for (let index = 0; index < group.raw.length; index++) {
            var ts = ""
            for (let k = index*pdfsPerSet; k < Math.min(index*pdfsPerSet + pdfsPerSet, group.total); k++) {
              let message = `scangroup_${group.id}_${index}_${k%pdfsPerSet}:`
              let invoice = group.invoices.find(f => {
                return parseInt(f.resourcePrefix.replace('scan_','')) === k
              })
              //console.log(`Testcase: ${index} - ${k}`)
              if(!invoice){
                continue;
              }
              let invoiceDate = (new Date(invoice.invoiceDate)).toLocaleString("en-US").split(',')[0]
              let paymentDate = (new Date(invoice.paymentDate)).toLocaleString("en-US").split(',')[0]
              let abn = invoice.supplier ? `'${invoice.supplier.abn}'` : null
              let supplier = invoice.supplier ? `'${invoice.supplier.name}'` : null
              let iv = `
    assert.strictEqual(data[${k%pdfsPerSet}].abn, ${abn}, \`${message} ABN Should have been ${abn}, not $\{data[${k%pdfsPerSet}].abn\}\`);
    assert.strictEqual(data[${k%pdfsPerSet}].invoice_id, '${invoice.invoiceNumber}', \`${message} Invoice ID Should have been ${invoice.invoiceNumber}, not $\{data[${k%pdfsPerSet}].invoice_id\}\`);
    assert.strictEqual(data[${k%pdfsPerSet}].supplier_name, ${supplier}, \`${message} Supplier name Should have been ${supplier}, not $\{data[${k%pdfsPerSet}].supplier_name\}\`);
    assert.strictEqual(data[${k%pdfsPerSet}].supplier_id, ${invoice.seller}, \`${message} Supplier ID Should have been ${invoice.seller}, not $\{data[${k%pdfsPerSet}].supplier_id\}\`);
    assert.strictEqual((new Date(data[${k%pdfsPerSet}].invoice_date).toLocaleString("en-AU")).split(',')[0], '${invoiceDate}', \`${message} Invoice Date Should have been ${invoiceDate}, not $\{(new Date(data[${k%pdfsPerSet}].invoice_date).toLocaleString("en-AU")).split(',')[0]\}\`);
    assert.strictEqual((new Date(data[${k%pdfsPerSet}].invoice_paid_date).toLocaleString("en-AU")).split(',')[0], '${paymentDate}', \`${message} Payment Date Should have been ${paymentDate}, not $\{(new Date(data[${k%pdfsPerSet}].invoice_paid_date).toLocaleString("en-AU")).split(',')[0]\}\`);
    assert.strictEqual(data[${k%pdfsPerSet}].total, ${invoice.total}, \`${message} Total Should have been ${invoice.total}, not $\{data[${k%pdfsPerSet}].total\}\`);
    assert.strictEqual(data[${k%pdfsPerSet}].gst, ${invoice.gst}, \`${message} GST Should have been ${invoice.gst}, not $\{data[${k%pdfsPerSet}].gst\}\`);
      
              `
              ts += iv
            }
            let testCase = `
describe(\`#Multi Page Invoice -ScanGroup ${group.id} - ${index}\`, async function() {

  before(async function() {
      console.log("=================== ${group.id} - Set ${index} =======================");
      data =  await jsonForInvoiceCommMulti('test_multi_page','scangroup_${group.id}/scangroup_${group.id}_${index}')
  });

  after(function() {
      console.log("===========================================");
  })

  it(\`${group.id}  - ${index} \`, function() {
      ${ts}
  });

});

            `
            allTests += testCase
        }

        var testCaseBlob = new Blob([allTests], {
          type: 'text/plain'
        });
        zip.file(`test_scan_${group.id}.js`,testCaseBlob)

      }

      let zippedBlobs = await zip.generateAsync({type:"blob"})
      saveBlobToDisk(zippedBlobs,"new_testcase.zip")
    } catch(e){
      console.log(e)
      alert(`Something went wrong ${e.message}`)
    }
    setDownloading(false)
  }

  function convertToCsv(rows) {
    var processRow = function (row) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            };
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    return new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
}

const saveBlobToDisk = (blob, filename)=> {
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
  }
}

  useEffect(() => {
    console.log("Triggering Timer")
    fetchScanDetails(queryParams)
  }, [timerTrigger]);


  const onInvoiceSelected = async (index, newInvoiceSet)=>{
    setSelectedInvoice(index)

    let invoicesInterestedIn = newInvoiceSet ? newInvoiceSet : invoices

    if(invoicesInterestedIn[index].signedImage){
      setSelectedImage(invoicesInterestedIn[index].signedImage)
    } else {
      let response = await Axios.get("/api/accountant/invoice/image", {
        params: { invoice_id : invoicesInterestedIn[index].id } ,
        headers: { Authorization: "Bearer " + loggedInUser.token }
      });
      let image = response.data
      invoicesInterestedIn[index]['signedImage'] = image
      setSelectedImage(image)
    }
  }

  const onCellEditted = async (index, colum, value)=>{
    setSelectedColumn(colum)
    setFocusedElement(`${index}_${colum}`)
    let newInvoice = invoices[index]
    if(!dirtyValues[newInvoice.id]){
      dirtyValues[newInvoice.id] = {}
    }
    dirtyValues[newInvoice.id][colum] = value
    if(colum == 'supplier' && !dirtyValues[newInvoice.id]['abn']){
      setInvoices(invoices.map( (k, i) => {
        if( i === index){
          var newInvoice = {...k}
          if (newInvoice.supplier){
            newInvoice.supplier.abn = ''
          }
          return newInvoice
        } else{
          return k
        }
      }))
    }
  }

  useEffect(() => {
    if (!loggedInUser) {
      history.push("/");
    }else {
      clearInterval(timerId)
      timerId = setInterval(function(){ 
        triggerTimer(Math.random())
      }, 10000);
      fetchBusinessCategories()
    }
  }, [loggedInUser, history]);


  function clearFocus(){
    if(focusedElement){
      document.getElementById(focusedElement).blur()
      setFocusedElement(null)
    }
  }

  const handleUserKeyPress = useCallback(event => {

    

    //const { key, keyCode } = event;
    var keyCode = undefined
    var possible = [ event.key, event.keyIdentifier, event.keyCode, event.which ]
    while (keyCode === undefined && possible.length > 0)
    {
      keyCode = possible.pop()
    }

    if(searchFocused){
      if(keyCode === 27){
        searchFocused.blur()
      }
      return
    } 
    
    //console.log(keyCode)

    let selectableColumns = isEditing ? selectableColumnsInEditMode : selectableColumnsInNormalMode

    if(keyCode === 38){ //Up
        clearFocus()
        event.preventDefault()
        onInvoiceSelected(Math.max(0,selectedInvoice-1))
        document.getElementById(`row_${selectedInvoice}`).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
    }else if(keyCode === 40){ //Down
        clearFocus()
        event.preventDefault()
        onInvoiceSelected(Math.min(invoices.length-1,selectedInvoice+1))
        document.getElementById(`row_${selectedInvoice}`).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
    }if(keyCode === 37  && focusedElement == null){ // Left
      clearFocus()
      event.preventDefault()
      var index = selectableColumns.indexOf(selectedColumn);
      //console.log(`Left ${selectedInvoice} ${index}`)
      if(index > 0){
        setSelectedColumn(selectableColumns[index-1])
      }
    }else if(keyCode === 39 && focusedElement == null){ // Right
      clearFocus()
      event.preventDefault()
      //console.log(`Right ${selectedInvoice} ${index}`)
      var index = selectableColumns.indexOf(selectedColumn);
      if(index < selectableColumns.length - 1){
        setSelectedColumn(selectableColumns[index+1])
      }
    } else if(keyCode === 13){ // enter
      clearFocus() 
      event.preventDefault()
      let newId = `${selectedInvoice}_${selectedColumn}`
      if(isEditing){
        if(focusedElement == newId){
          clearFocus()
        }else{
          let item = document.getElementById(newId)
          if(item){
            item .focus()
            setFocusedElement(newId)
            $(`#${newId}`).select()
          }
        }
      } else {
        setIsEditing(true, () => {
          let item = document.getElementById(newId)
          if(item){
            item .focus()
            setFocusedElement(newId)
            $(`#${newId}`).select()
          }
        })
      }
    } else if(keyCode === 27){ //ESC
        if(focusedElement){
          clearFocus() 
        } else if(isEditing && Object.keys(dirtyValues).length === 0){
          setIsEditing(false)
        } else {
          setDirtyValues({})
        }
    } else if(keyCode === 115){ // f4
        if(selectedColumn === 'gst'){
            //setIsEditing(true)
            let percentages = ['Original','GST Free','10%']
            let current = (gstLoopCount+1)%percentages.length
            setGstLoopCount(current)
            let id_gst = `${selectedInvoice}_${selectedColumn}`
            let id_gst_cat = `${selectedInvoice}_${selectedColumn}_sup`
            let key = percentages[current]
            $(`#${id_gst_cat}`).text(key)
            let gst = Math.max(invoices[selectedInvoice].gst,0)
            var invoice = invoices[selectedInvoice]
            let total = dirtyValues[invoice.id] && dirtyValues[invoice.id]['total'] ? dirtyValues[invoice.id]['total'] : invoice.total

            if(key === 'GST Free') {
              gst = 0
            } else if(key === '10%' && total) {
              gst = total / 11
            }
            if(!dirtyValues[invoice.id]){
              dirtyValues[invoice.id] = {}
            } 
            $(`#${id_gst}`).text(gst.toFixed(2))
            dirtyValues[invoice.id]['gst'] = gst
            dirtyValues[invoice.id]['gst_cat'] = key
        }
    } else if(keyCode === 114){ //f3
      setHighlighterEnabled(!highlighterEnabled)
    } else if (!isEditing){
      if (event.ctrlKey || event.metaKey){
        if(keyCode == '115' || keyCode == '83' ){ 
          saveInvoices()
          event.preventDefault();
        }
      } else {
        if(!focusedElement){
          if(event.altKey && keyCode === 84) { //T
            setSelectedColumn('total')
            event.preventDefault();
          } else if (event.altKey && keyCode === 71){ //G
            setSelectedColumn('gst')
            event.preventDefault();
          } else if (event.altKey && keyCode === 66){ //B
            setSelectedColumn('supplier')
            event.preventDefault();
          } else if (event.altKey && keyCode === 73){ //I
            setSelectedColumn('invoiceNumber')
            event.preventDefault();
          } else if (event.altKey && keyCode === 67){ //C
            setSelectedColumn('category')
            event.preventDefault();
          } else if (event.altKey && keyCode === 68){ //D
            setSelectedColumn('paymentDate')
            event.preventDefault();
          } else if ( 
            ((keyCode >= 48 && keyCode <= 57) /* Numbers */|| (keyCode >= 96 && keyCode <= 105) /* Numbers */ 
            || keyCode === 190 /* . */ || keyCode === 110 /* . */ 
            || keyCode === 191 || keyCode === 111 /* / */ 
            || keyCode === 8 || keyCode === 46
            || ( (selectedColumn==='supplier' || selectedColumn==='category') && (keyCode >= 65 && keyCode <= 90 || keyCode === 32))//Alphabets
            )  
            ){
            let invoiceRow = invoices[selectedInvoice]
            let rowValue = dirtyValues[invoiceRow.id]
            var newColumValue = `${ (rowValue && rowValue[selectedColumn]) ? (selectedColumn === 'category' ? (rowValue['cat'] && rowValue['cat']['name'] ? rowValue['cat']['name'] : '') : rowValue[selectedColumn]) : ''}`

            if(keyCode === 8 || keyCode === 46){ // delete or backspace
              newColumValue = newColumValue.length > 0 ? newColumValue.slice(0, -1) : newColumValue
            }else{
              newColumValue = newColumValue + ( (keyCode === 190 || keyCode === 110 ) ? "." : (keyCode === 191 || keyCode === 111) ? "/" :  (keyCode >= 96 && keyCode <= 105) ? `${keyCode-96}` : String.fromCharCode(keyCode))
            }
            let newRowValue = rowValue ? {...rowValue} : {}
            if(selectedColumn === 'category' ){
              if(!newRowValue[selectedColumn]){
                newRowValue[selectedColumn] = {}
              }
              let selectedCategory = categories.find(f => f.name.toLowerCase() === newColumValue.toLowerCase())
              var cat = { name: newColumValue }
              if(selectedCategory){
                cat = selectedCategory
                newRowValue[selectedColumn] = selectedCategory.id
              }
              newRowValue['cat'] = cat
            }else {
              newRowValue[selectedColumn] = newColumValue
            }
            let id = invoiceRow.id
            let newDirtyValues = {...dirtyValues}
            newDirtyValues[id] = newRowValue
            setDirtyValues(newDirtyValues)
            event.preventDefault();
          }
        }
      }
    }
  

  }, [invoices, selectedInvoice, selectedColumn,focusedElement, isEditing, gstLoopCount, dirtyValues, highlighterEnabled, searchFocused]);


  const onSearchFocus = (e) => {
    setSearchFocused(e.target)
  }

  const onSearchBlur = (e) => {
    setSearchFocused(null)
  }

  const onSearchTextChange = (searchText) => {
    if(!searchText || searchText.length === 0){
      setInvoices(scanGroup.invoices)
      if(scanGroup.invoices && scanGroup.invoices.count > 0){
        onInvoiceSelected(0, scanGroup.invoices)
      }
      
    } else {
      let searchTextLowerCase = searchText.toLowerCase()
      let filtered = scanGroup.invoices.filter(f => {
        if(f.supplier && f.supplier.name && f.supplier.name.toLowerCase().includes(searchTextLowerCase)) {
          return true
        }
        if(f.tempSupplierName && f.tempSupplierName.toLowerCase().includes(searchTextLowerCase)) {
          return true
        }
        if(f.paymentDate && ((new Date(f.paymentDate)).toLocaleString("en-AU").split(',')[0]).includes(searchTextLowerCase) ) {
          return true
        }
        if(f.total && Math.max(0,f.total).toFixed(2).includes(searchTextLowerCase) ) {
          return true
        }
        if(f.total && Math.max(0,f.gst).toFixed(2).includes(searchTextLowerCase) ) {
          return true
        }
        if(f.cat && f.cat.name && f.cat.name.toLowerCase().includes(searchTextLowerCase) ) {
          return true
        }
        return false
      })
      setInvoices(filtered)
      if(filtered && (filtered.length > 0)){
        onInvoiceSelected(0, filtered)
      }
    }
  }

  useEffect(() => {
    let tips = [
      "Focus on a GST cell and Keep tapping F4 to Change GST between Free and 10%. Refresh/F5 for more tips.",
      "Use ARROW keys to navigate. Refresh/F5 for more tips.",
      "Use F3 to toggle Image Guides. Refresh/F5 for more tips.",
      "Use Alt+G Keys to focus on GST and just type a number to change the value of it. Refresh/F5 for more tips.",
      "Use Alt+T Keys to focus on Total and just type a number to change the value of it. Refresh/F5 for more tips.",
      "Use Alt+D Keys to focus on Date and just type a date to change the value of it. Refresh/F5 for more tips.",
      "Use Alt+B Keys to focus on Supplier and just type something to change the value of it. Refresh/F5 for more tips.",
      "Use Alt+I Keys to focus on Invoice Number and just type a number to change the value of it. Refresh/F5 for more tips.",
      "Use Alt+C Keys to focus on Category and just type something change the value of it. Refresh/F5 for more tips.",
      "Cmd+S or Ctrl+S saves your changes. Refresh/F5 for more tips.",
      "When you tap on Edit Button, you will be able to click on any column and edit it.",
      "Tap ESC to undo changes. Refresh/F5 for more tips."
    ]
    if(!queryParams.delay)
    addToast(tips[Math.floor(Math.random() * tips.length)], {
      appearance: 'info',
      autoDismissTimeout: 15000,
      autoDismiss: true,
    })

    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  
  const [selectedReviewer, setSelectedReviewer] = React.useState(0);

  const handleReviewerChange = (event) => {
    setSelectedReviewer(event.target.value);
  };

  return (
    <React.Fragment>
    <Helmet>
      <title>Scan Details</title>
    </Helmet>
    {/* Importing Navbar */}
      <NavbarPage 
        navItems={[
            { id: 1, idnm: "home", navheading: "Home", link: "/"}
        ]} 
        navClass={document.documentElement.scrollTop} 
        imglight={false} 
        isDashboard={true}
        onSearchBlur={onSearchBlur}
        onSearchFocus={onSearchFocus}
        onSearchTextChange={onSearchTextChange}
        />
      {isSaving && <Loading loading background="#e1f5f2" loaderColor="#68cebf" />}
      <div className="container-sc-group  mx-4">
        <section className="container-sc-section">
          { ((status !== 'COMPLETE' && status !== 'FAILED') && progress < 100) && <LinearProgress variant="determinate" value={progress} />}
          <div className="left-right-flex">
            <div className="one-sc-group">
            <TableContainer className={classes.scrollable}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      ( (column.id != 'delete' && column.id != 'abn') || (isEditing && (!focusedElement || focusedElement.endsWith('supplier') ||  focusedElement.endsWith('abn'))) ) && <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                        {column.isSortable && <FontAwesomeIcon className="ml-2" icon={faSort} onClick={ e => onSortSelected(column.id) }/>}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  { invoices
                  && invoices.map( (o, index) => 
                    createData( 
                      index + 1,
                      dirtyValues[o.id] && dirtyValues[o.id].paymentDate ? dirtyValues[o.id].paymentDate : o.paymentDate ? (new Date(o.paymentDate)).toLocaleString("en-AU").split(',')[0] : loadingText, 
                      dirtyValues[o.id] && dirtyValues[o.id].invoiceNumber ? dirtyValues[o.id].invoiceNumber : o.invoiceNumber ? o.invoiceNumber : loadingText, 
                      dirtyValues[o.id] && dirtyValues[o.id].abn ? dirtyValues[o.id].abn : o.supplier ? o.supplier.abn : loadingText, 
                      dirtyValues[o.id] && dirtyValues[o.id].supplier ? dirtyValues[o.id].supplier : o.supplier ? o.supplier.name : (o.tempSupplierName ? o.tempSupplierName : loadingText), 
                      dirtyValues[o.id] && dirtyValues[o.id].cat ? dirtyValues[o.id].cat.name : o.cat && o.cat.name ? o.cat.name : '',
                      dirtyValues[o.id] && dirtyValues[o.id].total ? dirtyValues[o.id].total : o.total ? o.total : loadingText, 
                      dirtyValues[o.id] && dirtyValues[o.id].gst ? dirtyValues[o.id].gst :  o.gst === null ? loadingText : o.gst  ) ).map((row, index) => {
                    return (
                      <TableRow id={"row_"+index} className={selectedInvoice == index ? 'bg-selected-sc-group' : 'bg-normal-sc-group'} tabIndex={-1} key={index} onClick={ e => onInvoiceSelected(index) }>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            ((column.id != 'abn' &&  column.id != 'delete')|| isEditing) &&  
                            <TableCell 
                            key={column.id} 
                            align={column.align} 
                            className={ `${(( value === null 
                              || value === UI_FAILED 
                              || value == '-1' 
                              || (anomalies[index] && anomalies[index][column.id] === true)) 
                            && (column.id != 'invoiceNumber') 
                            ? (selectedInvoice === index && selectedColumn === column.id ? "bg-red-sc-group-selected" : "bg-red-sc-group" )
                            : (selectedInvoice === index && selectedColumn === column.id ? "bg-normal-sc-group-selected" : "bg-normal-sc-group"))}
                               ${(column.id === 'gst') ? ' relative-position' : ""}`} 
                              >
                              { 
                              column.id === 'delete' ? 
                                  isEditing && <FontAwesomeIcon id={`${index}_${column.id}`} icon={faTrashAlt} onClick={ e => onDeleteSelected(index) }/>
                              : column.id === 'category' ? 
                                <Autocomplete
                                  inputProps={{id : `${index}_${column.id}`, onFocus: onCategoryFocus}} 
                                  menuStyle={{
                                    borderRadius: '3px',
                                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                    background: 'rgba(255, 255, 255, 0.9)',
                                    padding: '2px 0',
                                    fontSize: '90%',
                                    position: 'fixed',
                                    overflow: 'auto',
                                    maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                                    zIndex: '998'
                                  }}
                                  shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                  getItemValue={(item) => item.name}
                                  items={categories}
                                  renderItem={(item, isHighlighted) =>
                                    <div 
                                      key={item.id}>
                                      {item.name}
                                    </div>
                                  }
                                  value={value}
                                  onChange={(e) => onCategoryEdited(e.target.value, index)}
                                  onSelect={(val) => onCategorySelected(val, index)}
                                /> 
                            : <ContentEditable
                                id={`${index}_${column.id}`} 
                                  html={`${column.format && typeof value === 'number' ? column.format(value) : value}`}
                                  disabled={ isEditing && column.id != 'index' ? false : true} 
                                  onChange={e => onCellEditted(index, column.id, e.target.value)}
                                  onFocus={e => onTextFocus(`${index}_${column.id}`)} 
                                  style={{ "textDecoration": "none" }}
                              />}

                              { column.id === 'gst' && index === selectedInvoice && <div id={`${index}_${column.id}_sup`} className="gst-top-left">{ dirtyValues[invoices[index].id] && dirtyValues[invoices[index].id]['gst_cat'] ? dirtyValues[invoices[index].id]['gst_cat'] : 'Original'}</div>}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
          </TableContainer>
          </div>
                  { invoices && invoices.length > 0 && selectedInvoice >= 0 && <ScanGroupInvoiceViewer selectedImage={selectedImage} invoice={invoices[selectedInvoice]} highlighterEnabled={highlighterEnabled}/>}
          </div>
          
        </section>
        <div className='footer-sc-group'>
          <div className='footer-left'>
            { !isEditing && (status === 'COMPLETE' || status === 'FAILED') && <Button outline color="primary" size="sm" className="r-m-10-sc-group" onClick={e => editInvoices()}>Edit</Button>}
            { isEditing && (status === 'COMPLETE' || status === 'FAILED') && <Button outline color="primary" size="sm" className="r-m-10-sc-group" onClick={e => cancelEditing()}>Cancel</Button>}
            { isEditing && (status === 'COMPLETE' || status === 'FAILED') && <Button outline color="primary" size="sm" className="r-m-10-sc-group" onClick={e => saveInvoices()}>Save</Button>}

            { (status === 'COMPLETE' || status === 'FAILED') && !isDownloading && <Button color="primary" size="sm" className="r-m-10-sc-group" onClick={e => downloadCSV()} >Download</Button> }
            { (status === 'COMPLETE' || status === 'FAILED') && isDownloading && <div className="spinner-holder"><Button color="primary" size="sm" className="r-m-10-sc-group" disabled>Download</Button> <Spinner className="floating-spinner" size="sm" color="light" /> </div> }

            { loggedInUser && loggedInUser.user_id === 2 && !isDownloading && (status === 'COMPLETE' || status === 'FAILED') && <Button outline color="primary" size="sm" className="r-m-10-sc-group" onClick={e => downloadTestCase()}>Downlod Testcase</Button> }
            { loggedInUser && loggedInUser.user_id === 2 && isDownloading && (status === 'COMPLETE' || status === 'FAILED') && <div className="spinner-holder"><Button outline color="primary" size="sm" className="r-m-10-sc-group">Downlod Testcase</Button> <Spinner className="floating-spinner" size="sm" color="light" /> </div> }

            { (status === 'COMPLETE' || status === 'FAILED') && <Button outline color="success" size="sm" className="r-m-10-sc-group" onClick={e => addMore()}>Add more Invoices</Button> }

            { status === 'FAILED' && !isRetrying && <Label color="error" size="sm" className="r-m-10-sc-group ml-5" >Some Invoices are stuck at preocessing</Label> }
            { status === 'FAILED' && !isRetrying && <Button color="primary" size="sm" className="r-m-10-sc-group" onClick={e => restart()} >Retry</Button> }

          </div>
          <div className='footer-right'>
            { <FormControl variant="outlined" size='small'
              className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Manual review request</InputLabel>
                <Select
                  classes={{
                    selectMenu: classes.selectMenu, // class name, e.g. `classes-nesting-label-x`
                    nativeInput: classes.selectMenu
                  }}
                  size="small" 
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedReviewer}
                  onChange={handleReviewerChange}
                  label="Manual review request"
                >
                  <MenuItem value={0}><em>None</em></MenuItem>
                  <MenuItem value={10}>Invoice Scanner Accountant</MenuItem>
                </Select>
              </FormControl>
            }
            <Button outline color="link" size="sm" className="r-m-10-sc-group" onClick={e => saveInvoices()}>Send</Button>
          </div>
          
        </div>
      </div>        
        
    </React.Fragment>
    );
};



export default ScanGroup;