import React , { useEffect, useState }  from "react";
import './App.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./components/home/Home";
import Register from "./components/auth/register/Register";
import SignIn from "./components/auth/signIn/SignIn";
import AddStatements from "./components/bankStatements/addStatements/AddStatements";
import ProcessStatements from "./components/bankStatements/processStatements/ProcessStatements";
import Dashboard from "./components/dashboard/Dashboard"
import AddScan from "./components/scan/addScan/AddScan"
import AddFiles from "./components/scan/addFiles/AddFiles"
import AddEmails from "./components/scan/addEmails/AddEmails"
import BusinessDetails from "./components/business/businessDetails/BusinessDetails"
import CreateBusiness from "./components/business/createBusiness/CreateBusiness"
import ScanGroup from "./components/scan/scanGroup/ScanGroup"
import NotFound from "./components/NotFound"
import ImageRedirect from "./components/redirect/ImageRedirect"
import VerifyEmail from "./components/redirect/VerifyEmail"
import Blog from "./components/blog/Blog"
import BlogT from "./components/blog/BlogT"
import BlogGrid from "./components/blog/BlogGrid"
import {Helmet} from "react-helmet";


function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Home} exact={true} />
        <Route path="/register" component={Register} exact={true} />
        <Route path="/signin/:flag?" component={SignIn} exact={true} />
        <Route path="/dashboard/:coupon?" component={Dashboard} exact={true} />
        <Route path="/statments/add" component={AddStatements} exact={true} />
        <Route path="/statments/process" component={ProcessStatements} exact={true} />
        <Route path="/scan/add/:locSearch" component={AddScan} exact={true} />
        <Route path="/scan/files/:locSearch" component={AddFiles} exact={true} />
        <Route path="/scan/emails/:locSearch" component={AddEmails} exact={true} />
        <Route path="/scan/detail/:groupIdOrQuarter" component={ScanGroup} exact={true} />
        <Route path="/business/create/:firmId" component={CreateBusiness} exact={true} />
        <Route path="/business/details/:locSearch" component={BusinessDetails} exact={true} />
        <Route path="/invoiceimage/:imageId" component={ImageRedirect} exact={true} />
        <Route path="/verifyEmail/:code" component={VerifyEmail} exact={true} />
        <Route path="/privacyPolicy" component={Blog} exact={true} />
        <Route path="/blog/:id/:option?" component={BlogT} exact={true} />
        <Route path="/blogs" component={BlogGrid} exact={true} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
