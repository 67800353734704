/*
    Powered by Google
    Easy Review Process
    Export Everything as Excel
    Small business friendly
    About Us
    Media and Press
    Career
    Pricing
    For Accounting Firms
    For Book keepers
    For Suppliers/SMBs
    Privacy Policy
*/
import React, { useEffect, useState }  from "react";
import { Helmet } from 'react-helmet'
import NavbarPage from "../home/Navbar/Navbar_Page";

import "./Blog.css"

const Blog = ({blogData}) => { 

    return (
        <React.Fragment>
          <Helmet>
            <title>Business Details</title>
          </Helmet>
          
          {/* Importing Navbar */}
          <NavbarPage navItems={[
                  { id: 1, idnm: "home", navheading: "Home", link:"/" }
              ]} navClass={document.documentElement.scrollTop} imglight={false} />
          <div className="container mt-5 mb-5">
          <h1>Invoice Scanner Privacy Policy</h1>

<i className="mt-5">Last updated May 21 2021</i>

<p className="mt-5">InvoiceScanner.com.au (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile application (the “Application”).   Please read this Privacy Policy carefully.  IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION. 

We reserve the right to make changes to this Privacy Policy at any time and for any reason.  We will alert you about any changes by updating the “Last updated” date of this Privacy Policy.  You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted.  

This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application or make payments, including any in-game virtual items, which may also collect and use data about you.  We are not responsible for any of the data collected by any such third party. 
</p>

<h3 className="mt-5">COLLECTION OF YOUR INFORMATION</h3>

<p>
We collect nothing. Not a single information about you. We have added Google analytics which shows us a summary of how many customers visited our site. Goolgle may collect data from that. Your invoices are only available to you and your team. That's it.
</p>

<h3 className="mt-5">DISCLOSURE OF YOUR INFORMATION</h3>
<p>
We may share information we have collected about you in certain situations. Your information may be disclosed as follows: 
</p>

<p>
By Law or to Protect Rights 
If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.  This includes exchanging information with other entities for fraud protection and credit risk reduction.
</p>

<p>
Sale or Bankruptcy 
If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity.  If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party.  You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy. 

</p>

<p>
We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations.  If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly. 
</p>

<h3 className="mt-5">TRACKING TECHNOLOGIES</h3>

<p>
Cookies and Web Beacons
We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Application to help customize the Application and improve your experience. When you access the Application, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Application. You may not decline web beacons. However, they can be rendered ineffective by declining all cookies or by modifying your web browser’s settings to notify you each time a cookie is tendered, permitting you to accept or decline cookies on an individual basis.
</p>


<h6>Website Analytics </h6>
<p>
We may also partner with selected third-party vendors[, such as [Adobe Analytics,] [Clicktale,] [Clicky,] [Cloudfare,] [Crazy Egg,] [Flurry Analytics,] [Google Analytics,] [Heap Analytics,] [Inspectlet,] [Kissmetrics,] [Mixpanel,] [Piwik,] and others], to allow tracking technologies and remarketing services on the Application through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Application, determine the popularity of certain content, and better understand online activity. By accessing the Application, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. 
</p>

<p>
You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.
</p>

<h3 className="mt-5">SECURITY OF YOUR INFORMATION</h3>
<p>
We use administrative, technical, and physical security measures to help protect your personal information.  While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.  Any information disclosed online is vulnerable to interception and misuse by unauthorized parties.  Therefore, we cannot guarantee complete security if you provide personal information.
</p>

<h3 className="mt-5">CONTACT US</h3>
<p>If you have questions or comments about this Privacy Policy, please contact us at:</p>
<span>ai@invoicescanner.com.au</span>
          </div>
        </React.Fragment>)
};

export default Blog;

