import React ,{useState} from "react";
import SplitForm from './SplitForm'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';

import { sendPageView, sendScreenView } from '../../Utils'



const StripeCombo = ({ heading, coupon, price, firmId, productId, value, onPaymentSuccess }) => {
    const stripePromise = loadStripe("pk_live_OHh0N2ArmJDX7SqGiQmScRyH");

    const [error, setError] = useState(null)
    
    return (
        <div>
            <h5>{`${heading} Plan`}</h5>
            {coupon && <h5 className="mb-4">${value * (100 - coupon.percentage) / 100} {price}</h5>}
            {!coupon && <h5 className="mb-4">${value} {price}</h5>}
            { error && <p className="text-danger">{error}</p>}
            <Elements stripe={stripePromise}>
                <SplitForm coupon={coupon} productId={productId} firmId={firmId} value={value} onSuccess={onPaymentSuccess}  onError={(m) => setError(m)}/>
            </Elements>
        </div>
    );
}

export default StripeCombo;