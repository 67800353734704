import React, { useState } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";
import { Button } from 'reactstrap';

const less = [
    { icon : "grid", title : "Invoice Storage", desc : "Invoices are digitized and stored in Secure Cloud for upto 5 years." },
    { icon : "edit", title : " Invoice Parsing", desc : "Invoice Recognition is a carried out by world class OCR & AI." },
    { icon : "headphones", title : "Australian Support", desc : "Our 24 x7 support team is based in NSW, Australia." },
    /*{ icon : "layers", title : "Highly Secured", desc : "All communications are SSL and Databases are backed up every hour." },
    { icon : "code", title : "Easy to Review", desc : "Reviewing & Adjusting are made easy with shortcuts and hot keys. You have an option to outsorce that too." },
    { icon : "tablet", title : "Feature Requests", desc : "Using TWAIN drivers we connect to your scanners in the network." },*/
]

const extra = [
    { icon : "tablet", title : "Manual Review", desc : "If you are busy, our team of Accountants will review your processed batch of invoices within 24 hours." },
    { icon : "layers", title : "Features on Demand", desc : "We are open to suggestions and willing to add features at a really fast phase." },
    { icon : "code", title : "Email Newsletter", desc : "We are always on top of new rules & regulations by ATO. And we would love to share that knowledge." },
]

const Process = () =>  {

    const [services, setServices] = useState(less)
    const [buttonText, setButtonText] = useState('View more')
    const [collased, setCollased] = useState(true)

    const onViewMoreOrLessClicked = () =>{
        setServices(collased ? less.concat(extra)  : less)
        setButtonText(collased ? 'View less' : 'View more')
        setCollased(!collased)
    }

        return (
            <React.Fragment>
        <section className="section bg-light" id="services">
            <Container>
            <SectionTitle
                subtitle="Services"
                title="Services We Provide"
                desc="There is no other Invoice Processing pipeline like us anywhere in the world."
            />

                <Row>
                    <ServiceBox services={services} />
                </Row>
                

                <Row className="mt-4">
                    <Col lg={12}>
                        <div className="text-center">
                            <Button onClick={e=>onViewMoreOrLessClicked()} className="btn btn-success">{buttonText}</Button>
                        </div>
                    </Col>
                </Row>
                
            </Container>
            
        </section>
            </React.Fragment>
        );
}

export default Process;