import React, { useEffect, useState }  from "react";
import Axios from "axios";
import {useParams, Redirect, useHistory} from "react-router-dom";
import Loading from 'react-fullscreen-loading';
import NavbarPage from "../home/Navbar/Navbar_Page";

const ImageRedirect = () => {

    let { code } = useParams()
    const [isLoading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)

    useEffect( async () => {
        console.log(code)
        let url = `/api/public/verifyEmail`
        try {
            let response = await Axios.post(url, {code: code});
            if(response.data && response.data.success === true) {
                setSuccess(true)
            }
        } catch (error) {
            console.log(error.response)
        } finally {
            setLoading(false)
        }
    }, []);
      
    return (<div>
                <NavbarPage navItems={[
              { id: 1, idnm: "home", navheading: "Home", link: "/"}
          ]} navClass={document.documentElement.scrollTop} imglight={false} isDashboard={false}/>
        { isLoading && <Loading loading background="#e1f5f2" loaderColor="#68cebf"/>}
            <div className="mt-5 pt-5 ml-4">
            { !isLoading && success && <div><h5 className="text-success">Success</h5><p> Verification was successful. Please <a href="/signin">Login</a>.</p></div>}
            { !isLoading && !success &&  <div><h5 className="text-danger">Failed</h5><p>Verification failed. Please contact us at support@invoicescanner.com.au</p></div>}
            </div>
        </div>)
};

export default ImageRedirect;