import React, { useState }  from "react";
import Pricing from '../../home/Pricing/pricing'
import Stepper from 'react-stepper-horizontal'
import StripeCombo from './StripeCombo'
import "./SplitForm.css"
import { Button } from "reactstrap";
import { pricings } from '../../home/Pricing/pricing'
import moment from 'moment'


const Subscription = ({firmId, coupon, subscription, onCancel}) => {

    const [heading, setHeading] = useState('')
    const [price, setPrice] = useState(0)
    const [productId, setProductId] = useState(0)
    const [value, setValue] = useState(0)

    const onProductSelected = (productId, heading, price, value) => {
        setCurrentStep(1)
        setHeading(heading)
        setPrice(price)
        setProductId(productId)
        setValue(value)
    }

    const onStepClicked = (step) => {
        if(step < currentStep && currentStep != 2){
            setCurrentStep(step)
        }
    }

    const onPaymentSuccess = () => {
        setCurrentStep(2)
    }

    const [currentStep, setCurrentStep] = useState(0)

    return (
        <div id="sub-main-div">
            { 
                ( subscription  && (subscription.status === 'active' || subscription.status === 'cancelled') && pricings[subscription.product]) && 
                    <div>
                        { subscription.status === 'active' && <h3>{ `You have subscribed to ${pricings[subscription.product].title } ${pricings[subscription.product].group} Plan`}</h3>}
                        { subscription.status != 'active' && <h3>{ `You were subscribed to ${pricings[subscription.product].title } ${pricings[subscription.product].group} Plan`}</h3>}
                        { subscription.status === 'active' && <p>${subscription.value} {pricings[subscription.product].frequency}</p> }
                        { subscription.status != 'active' &&  <p>{`That plan ends on ${moment(subscription.endDate).format('DD-MMM-yyyy')}`}</p>}
                        { subscription.status === 'active' && <ul>
                            { pricings[subscription.product].features.map( f => 
                            <li>{`${f.title} : ${f.value}`}</li>
                        ) }</ul> }
                        { subscription.status === 'active' && <Button onClick={onCancel}>Stop Auto Renewing.</Button>}
                    </div>
                }
            { (!subscription  || subscription.status != 'active') && <div>
                <Stepper steps={ [
                    {title: '', onClick: (e) => { onStepClicked(0)} },
                    {title: '', onClick: (e) => { onStepClicked(1)} }, 
                    {title: '', onClick: (e) => { onStepClicked(2)}}] } activeStep={ currentStep } />
                
                { currentStep === 0 && <Pricing coupon={coupon} isInDashboard={true} onProductSelected={onProductSelected}/> }
                
                { currentStep === 1 && <div className="mt-4 center-hor"><StripeCombo heading={heading} coupon={coupon} price={price} productId={productId} value={value} firmId={firmId} onPaymentSuccess={onPaymentSuccess}/></div> }
                
                { currentStep === 2 && 
                    <div className="mt-4 center-hor">
                        <div>
                            <h3>{`Your firm has subscribed to ${heading} Plan successfully.`}</h3>
                            { coupon && <p>Your subscription details: ${value  * (100 - coupon.percentage) / 100 } {price}</p> }
                            { !coupon && <p>Your subscription details:${value} {price}</p> }
                        </div>
                    </div> 
                }
                
            </div>}
            
        </div>
    )
}

export default Subscription;