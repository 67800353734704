import React, { useEffect, useState, useRef }  from "react";
import { useHistory } from "react-router-dom";
import Loading from 'react-fullscreen-loading';
import { useDispatch, useSelector } from "react-redux";
import JSZip from 'jszip';
import Axios from "axios";
import {useParams} from "react-router-dom";
import queryString from 'query-string';
import { logoutUser } from "../../auth/UserSlice";
import { useToasts } from 'react-toast-notifications'
import { Helmet } from 'react-helmet'
import { Button } from 'reactstrap';

import NavbarPage from "../../home/Navbar/Navbar_Page";
import Iframe from 'react-iframe'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { makeStyles } from '@material-ui/core/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'

import {useCallbackRef} from 'use-callback-ref';

import "./AddFiles.css"

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    scrollable: {
        overflow: 'scroll',
        height: '100%'
    }
}));

const columns = [
    { id: 'index', label: 'No.', minWidth: 10 },
    { id: 'file', label: 'File', minWidth: 100 , isSortable: true },
    {
      id: 'delete',
      label: '',
      minWidth: 20,
      align: 'center'
    }
];

const AddFiles = (hasEmails) => {
    const userState = useSelector((state) => state.user);
    const { loggedInUser } = userState;
    const history = useHistory();
    const dispatch = useDispatch();
    const { addToast } = useToasts()
  
    const [ isLoading, setIsLoading ] = useState(false)

    const [ files, setFiles ] = useState([])
    const [ selectedFileIndex, setSelectedFileIndex ] = useState()
    const [ selectedFileUrl, setSelectedFileUrl ] = useState()

    let { locSearch } = useParams()
    const { firmId, clientId, parentGroup, vid } = queryString.parse(locSearch)


    const classes = useStyles();

    const inputFile = useCallbackRef(null, ref => ref && ref.focus());
   
    const onLoadingStateChange = (isLoading) => {
        setIsLoading(isLoading)
      };

    const loadEmails = (event) => {
        event.preventDefault();
        history.push(`/scan/emails/${locSearch}`);
    };


    const openDwtScan = (event) => {
        event.preventDefault();
        history.push(`/scan/add/${locSearch}`);
    };

    const onDeleteSelected = async (index)=>{
        let result = [...files]
        result.splice(files, 1)
        setFiles(result)
    }

    const openFileSelector = (event) => {
        event.preventDefault();
        inputFile.current.click();
    };

    const fileSelection = (e) => {
        let file = e.target.files[0];
        let newFiles = [...files]
        if (e.target.files) {
            for ( const f of e.target.files ) {
                newFiles.push( { file: f.name, content: f } )
            }
        }
        setFiles(newFiles)
    };

    const onFileSelected = (index)=>{
        setSelectedFileIndex(index)
        let file = files[index].content
        setSelectedFileUrl(URL.createObjectURL(file))
    }

    const processImages = async ()=>{
        setIsLoading(true)

        var zip = new JSZip()

        try {
          for (let index = 0; index < files.length; index++) {
            const file = files[index].content;
            let type = file.type
            let url = URL.createObjectURL(file)
            let blob = await toBlob(url)
            var extension = "pdf"
            if (type == "image/jpeg"){
                extension = "jpg"
            } else if (type == "image/png"){
                extension = "png"
            }
            zip.file(`scan_${index}.${extension}`,blob)
          }
      
          let zippedBlobs = await zip.generateAsync({type:"blob"})

          let groupId = await uploadZippedImages(zippedBlobs)

          history.push(`/scan/detail/groupId=${groupId}`);
        } catch(e){
          alert("Something went wrong")
        }

        setIsLoading(false)
    }

    const uploadZippedImages = async (zippedBlob) => {
        try {
          const formData = new FormData();
          formData.append('images',zippedBlob)
    
          if(clientId && firmId){
            formData.append('clientId',clientId)
            formData.append('accountingFirmId',firmId)
          }
    
          if(parentGroup){
            formData.append('parentGroupId',parentGroup)
          }
          console.log(formData);
          const config = {
            headers: {
                Authorization: "Bearer " + loggedInUser.token,
                'content-type': 'multipart/form-data'
            }
          }
          console.log(config)
          let response = await Axios.post("/api/image/files/upload",formData,config)
          console.log(response);
          let group = response.data.scanGroup
          if(group != undefined && group != null){
            return response.data.scanGroup.id
          } else {
            throw("Wrong data")
          }
        } catch(err){
          if(err && err.response && err.response.status == 401 ){
            dispatch(logoutUser())
          }
        }  
      }

    const toBlob = async (url) => {
        let response = await fetch(url)
        let data = await response.blob()
        return data
      }

    return (
        <React.Fragment>
          {/* Importing Navbar */}
            <Helmet>
              <title>Add Scan</title>
            </Helmet>
            <NavbarPage navItems={[
                  { id: 1, idnm: "home", navheading: "Home", link: "/"}
              ]} navClass={document.documentElement.scrollTop} imglight={false} isDashboard={true}/>
            {isLoading && <Loading loading background="#e1f5f2" loaderColor="#68cebf" />}
            
              <div className="add-files-container">
                  <div id="dwt-holder" style={{ width: "30%", margin: "0 auto" }}>
                    <input type='file' id='file' accept="image/png, image/jpeg, application/pdf" ref={inputFile} style={{display: 'none'}} onChange={fileSelection} multiple/>
                    {hasEmails && <div>
                        <Button outline color="primary" size="sm" tabIndex="3" style={{marginRight: "2%",  width: "32%" }}
                            onClick={openFileSelector}
                        >From Computer</Button>

                        <Button  outline color="primary" size="sm" tabIndex="2" style={{ width: "32%" }}
                            onClick={openDwtScan}
                        >Scan Directly</Button>
                        
                        <Button outline color="primary" size="sm" tabIndex="4" style={{ marginLeft: "2%", marginBottom: "2%", marginTop: "2%", width: "32%" }}
                            onClick={loadEmails}
                        >From Emails</Button>
                    </div>}

                    {!hasEmails && <div>

                        <Button outline color="primary" size="sm" tabIndex="3" style={{ marginRight: "4%",  width: "48%" }}
                            onClick={openFileSelector}
                        >Select File</Button>

                        <Button  outline color="primary" size="sm" tabIndex="2" style={{ margin: "2% 0", width: "48%" }}
                            onClick={openDwtScan}
                        >Scan Directly</Button>

                    </div>}
                  </div>
              </div>

              <div id="selected-files-container">
                    <div id="selected-files">
                        { files && files.length > 0 && <TableContainer className={classes.scrollable}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                {   files && files.map((row, index) => (
                                        <TableRow key={row.index} className={selectedFileIndex == index ? 'bg-selected-sc-group' : 'bg-normal-sc-group'} tabIndex={-1}  onClick={ e => onFileSelected(index)}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                    >
                                                        {
                                                            column.id == 'index' && index+1
                                                        }
                                                        {
                                                            column.id == 'file' && value
                                                        }
                                                        {
                                                            column.id === 'delete' && <FontAwesomeIcon id={`${index}_${column.id}`} icon={faTrashAlt} onClick={ e => onDeleteSelected(index) }/>
                                                        }
                                                </TableCell>)
                                            })}
                                        </TableRow>
                                    ))
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>}
                        { files && files.length > 0 && <Button color="primary" size="sm" tabIndex="3" style={{ margin: "2% 0" }} onClick={processImages } >Process Files</Button> }
                    </div>
                    { files && files.length > 0 && <div id="file-viewer">
                        { selectedFileUrl && <Iframe url={selectedFileUrl}
                            id="add-file-iframe"
                            display="initial"
                            position="relative"/> 
                        }
                    </div>
                    }
              </div>
        </React.Fragment>
      );
}

export default AddFiles;