import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { signinUser, resetSigninState } from "../UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import Loading from 'react-fullscreen-loading';

import { Helmet } from 'react-helmet' 

import NavbarPage from "../../home/Navbar/Navbar_Page";
import {sendEvent} from '../../Utils'


const SigninContainer = styled.div`
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  align-items: center;
`;

const FormTitle = styled.div`
  font-size: 30px;
  padding-bottom: 10px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  
`;

const ErrorSection = styled.div`
  color: red;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '285px',
    },
  },
}));

const flexContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};


const SignIn = () => {
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });

  let { flag } = useParams()


  const [isLoading, setLoading] = useState(false)

  const classes = useStyles();

  const userState = useSelector((state) => state.user);
  const { error } = userState.signinState;
  const { loggedInUser, signinState } = userState;

  const handleUserInfoChange = (e) => {
    const { id, value } = e.target;
    setUserInfo((currentState) => ({
      ...currentState,
      [id]: value,
    }));
  };

  const dispatch = useDispatch();

  const handleSubmit = () => {
    setLoading(true)
    sendEvent('login')
    dispatch(signinUser(userInfo));
  };

  const history = useHistory();

  useEffect(() => {
    if (loggedInUser) {
      let ext = flag ? `/${flag}` : ``
      history.push(`/dashboard${ext}`);
    }
    if(signinState.error){
      setLoading(false)
    }
  }, [loggedInUser, history, signinState]);

  const findError = (sectionName) => {
    const errorObj = error.find((err) => err.param === sectionName);
    return errorObj ? errorObj.msg : null;
  };

  const handleResetState = () => {
    dispatch(resetSigninState());
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
        {/* Importing Navbar */}
        <NavbarPage navItems={[
                { id: 1, idnm: "home", navheading: "Home", link:"/" }
            ]} navClass={document.documentElement.scrollTop} imglight={false} isSignIn={true}/>
        {isLoading && <Loading loading background="#e1f5f2" loaderColor="#68cebf" />}
        <SigninContainer>
          <FormTitle>Sign-in</FormTitle>
          {error && <ErrorSection>{findError("userNotFound")}</ErrorSection>}
          <form className={classes.root} noValidate autoComplete="off" style={flexContainer}>

            <TextField id="email" label="Email" variant="outlined" onChange={handleUserInfoChange} />
            <TextField id="password" label="password" type="password" variant="outlined" onChange={handleUserInfoChange} />
            <Button size="large" className="mt-3" variant="contained" color="primary" onClick={handleSubmit}>Sign-in</Button>
          </form>
          <div className="mt-5">
            Don't have an account?{" "}
            <StyledLink to="/register" onClick={handleResetState}>
              Register Here
            </StyledLink>
          </div>
        </SigninContainer>

    </React.Fragment>
    );

};

export default SignIn;