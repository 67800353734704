import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import FeatureBox from "./feature-box";

//Import Images
import feature1 from "../assets/images/features/feature_1.png";
import feature2 from "../assets/images/features/feature_2.png";
import feature3 from "../assets/images/features/feature_3.png";
import feature4 from "../assets/images/features/feature_4.png";


import "./feature.css"

class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {
            smallFeatures1 : [
                { title : "Hand Written Reciepts" },
                { title : "Long Receipts" },
            ],
            smallFeatures2 : [
                { title : "Cloud Storage" },
                { title : "Bleeding Edge AI" },
            ],
            smallFeatures3 : [
                { title : "300 invoices in 10m" },
                { title : "24h Manual Verification" },
            ],
            smallFeatures4 : [
                { title : "ATO Audit friendly" },
                { title : "XERO, MYOB compatible" },
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
        <section className="section" id="features">
            <Container>
            <SectionTitle
                subtitle="Features"
                title="Key features of the product"
                desc="Designed by Accountants for other Accountants & Bookkeepers."
            />               

                <Row id="feature-holder_a">
                    <Col lg={5} className="feature_data_holder">
                        <div>
                            <FeatureBox 
                            icon="bar-chart-2" 
                            title="Small Business Friendly" 
                            smallFeatures={this.state.smallFeatures1} 
                            desc="Designed with Small Suppliers and their hand written receipts in mind. If you have Clients who dump a bag of receipts at the end of a quarter, this is the perfect product for you. All you need to do is schedule a pick up and we will do the rest." 
                            link="/blog/for-smbs" />
                        </div>
                    </Col>

                    <Col lg={5} sm={8} className="ml-lg-auto feature_image_holder">
                        <Card id="feature_image_a" className="border border-light shadow-none mt-5 mt-lg-0">
                            <CardHeader className="border-0 bg-transparent">
                                <div>
                                    <i className="mdi mdi-circle text-danger mr-1"></i>
                                    <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                                    <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                                </div>
                            </CardHeader>
                            <CardBody className="bg-light">
                                <div className="box-shadow">
                                    <img src={feature1} alt="" className="img-fluid mx-auto d-block"/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
                <Row className="mt-5 pt-5" id="feature-holder_b">
                    <Col lg={5} sm={8} className="feature_image_holder">
                        <Card className="border border-light shadow-none">
                            <CardHeader className=" border-0 bg-transparent">
                                <div>
                                    <i className="mdi mdi-circle text-danger mr-1"></i>
                                    <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                                    <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                                </div>
                            </CardHeader>
                            <CardBody className="bg-light">
                                <div className="box-shadow">
                                    <img src={feature2} alt="" className="img-fluid mx-auto d-block"/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={5} className="ml-lg-auto feature_data_holder">
                        <div id="feature_data_b" className="mt-4 mt-lg-0">
                        <FeatureBox 
                        icon="pie-chart" 
                        title="Powered by Google." 
                        smallFeatures={this.state.smallFeatures2} 
                        desc="Google is the leader when it comes to AI and Machine Learning. They are expensive, but reliable. 95% of our revenue is spent on their tools to bring you the best in the market." 
                        link="/blog/google-ai" />
                        </div>
                    </Col>
                </Row>

                <Row className="mt-5 pt-5" id="feature-holder_c">
                    <Col lg={5} className="feature_data_holder">
                        <div>
                            <FeatureBox 
                            icon="bar-chart-2" 
                            title="Easy Review Process" 
                            smallFeatures={this.state.smallFeatures3} 
                            desc="Our Software can be highly accurate when it comes to extracting information from invoices. But, you might still want to manually review. So, we have made the reviewing process very intuitive and easy. You will be able to go through 300 receiepts in 10 mins or less." 
                            link="/blog/how-to-review" />
                        </div>
                    </Col>

                    <Col lg={5} sm={8} className="ml-lg-auto feature_image_holder">
                        <Card id="feature_image_c" className="border border-light shadow-none mt-5 mt-lg-0">
                            <CardHeader className="border-0 bg-transparent">
                                <div>
                                    <i className="mdi mdi-circle text-danger mr-1"></i>
                                    <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                                    <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                                </div>
                            </CardHeader>
                            <CardBody className="bg-light">
                                <div className="box-shadow">
                                    <img src={feature3} alt="" className="img-fluid mx-auto d-block"/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row id="feature-holder_d" className="mt-5 pt-5">
                    <Col lg={5} sm={8} className="feature_image_holder">
                        <Card className="border border-light shadow-none">
                            <CardHeader className=" border-0 bg-transparent">
                                <div>
                                    <i className="mdi mdi-circle text-danger mr-1"></i>
                                    <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                                    <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                                </div>
                            </CardHeader>
                            <CardBody className="bg-light">
                                <div className="box-shadow">
                                    <img src={feature4} alt="" className="img-fluid mx-auto d-block"/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={5} className="ml-lg-auto feature_data_holder">
                        <div id="feature_data_d" className="mt-4 mt-lg-0">
                        <FeatureBox 
                        icon="pie-chart" 
                        title="Export everything as Excel" 
                        smallFeatures={this.state.smallFeatures4} 
                        desc="If you want to upload the data to another Accounting Software or In case of an Audit, you can easily find the relevant Invoices and download." 
                        link="/blog/export-as-excel" />
                        </div>
                    </Col>
                </Row>
                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default Features;