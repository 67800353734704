import React from "react";
import styled from "styled-components";
import { logoutUser } from "../auth/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const GridContainer = styled.div`
display: grid;
grid-template-areas:
  "nav"
  "main";
grid-template-columns: 30% 70%;
grid-template-rows: 1fr;
margin: auto;
margin-top: 10px;
border-bottom: solid black 1px;
width:90%; 
`;

const NavContainer = styled.div`
  grid-area: "nav";
`;
const MainContainer = styled.div`
  grid-area: "main";
`;

const Title = styled.div`
  font-size: 30px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const HeaderItem = styled.div`
  padding-right: 50px;
  :last-child {
    padding: 0;
  }
  font-size: 22px;
`;

const SignoutButton = styled.button`
  background-color: #9da631;
  border: none;
  border-radius: 2px;
  padding: 10px 12px 10px 12px;
  color: white;
  :hover {
    cursor: pointer;
  }
`;

const StyledLink = styled(Link)`
  margin-right: 50px;
  text-decoration: none;
  color: #9da631;
  font-weight: bold;
`;

const NavigationBar = () => {
  const userState = useSelector((state) => state.user);
  const { loggedInUser } = userState;
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutUser());
  };
  return (
    <GridContainer>
      <NavContainer>
        <StyledLink to="/"> <Title>Invoice Scanner</Title> </StyledLink>
      </NavContainer>
      <MainContainer>
        <HeaderContainer>
        {!loggedInUser && <StyledLink to="/signin"> SignIn </StyledLink>}
          {loggedInUser && <HeaderItem>Hi {loggedInUser.user_name}</HeaderItem>}
          {loggedInUser && <StyledLink to="/dashboard"> Dashboard </StyledLink> }
          <HeaderItem>
            {loggedInUser && (
              <SignoutButton onClick={() => handleLogout()}>
                Sign Out
              </SignoutButton>
            )}
          </HeaderItem>
        </HeaderContainer>
      </MainContainer>
    </GridContainer>
  );
};

export default NavigationBar;