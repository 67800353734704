import React, { Component, useRef } from 'react';
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    NavLink
} from "reactstrap";

//Importing Modal
import ModalSection from './common/ModalSection';

import ScrollspyNav from "./Navbar/scrollSpy";
import "./Home.css" 

//Import Images
import homeImg from "./assets/images/home-img.png";





const Section = () => { 

    const inputRef = React.createRef();

    const callModal = () => {
        console.log(inputRef)
        inputRef.current.openModal();
    }

        return (
            <React.Fragment>
                <section className="hero-section" id="home">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6}>
                                <div className="hero-wrapper mb-4">
                                    <p className="font-16 text-uppercase">Speed up your Data Entry Today</p>
                                    <h1 className="hero-title mb-4">Make your Bookkeeping Faster & Unique with <span className="text-primary">Invoice Scanner</span></h1>

                                    <ol>
                                        <li><p className='mb-0'>We pickup invoices from you or your clients.</p></li>
                                        <li><p className='mb-0'>We scan them, digitize them and process them.</p></li>
                                        <li><p className='mb-0'>We return the originals and store the digital copies for 5 years.</p></li>
                                        <li><p>You login, download the GST worksheet and prepare the BAS.</p></li>
                                    </ol><p>It is that simple.</p>

                                    <ScrollspyNav
                                        scrollTargetIds={['pricing','services']}
                                        scrollDuration="200"
                                        headerBackground="true"
                                        activeNavClass="active"
                                        className="navbar-collapse"
                                    >
                                        <div className="mt-4 inline-blocker">
                                            <Link onClick={e => callModal()} to="#" className="btn btn-primary mt-2 mr-2"><i className="mdi mdi-play small-play-icon-circle play play-iconbar"></i>Watch in Action</Link>
                                            <NavLink href={"#" + `pricing`} className="btn btn-success mt-2 mr-2 "> Join Free</NavLink>
                                        </div>
                                    </ScrollspyNav>
                                    
                                </div>

                            </Col>

                            <Col lg={6} sm={8}>
                                <div className="home-img mt-5 mt-lg-0">
                                    <img src={homeImg} alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                        {/* Render ModalSection Component for Modal */}
                        <ModalSection ref={inputRef} channel='youtube' videoId='13lZRBnz3WY' />
                    </Container>
                </section>
            </React.Fragment>
        );
}

//<Link onClick={e => callModal()} to="#" className="btn btn-primary mt-2 mr-2"><i className="mdi mdi-play small-play-icon-circle play play-iconbar"></i>Watch in Action</Link>


//<Link onClick={e => callModal()} to="#" className="video-play-icon text-dark"><i className="mdi mdi-play play-icon-circle play play-iconbar"></i> WATCH VIDEO</Link>
//<NavLink href={"#" + `pricing`} className="btn btn-primary mt-2 mr-2 "> {`Watch in Action`}</NavLink>


export default Section;