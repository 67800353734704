import React, { Component } from 'react';
import { Row } from "reactstrap";

//Import Blog Box
import CounterBox from "./counter-box";

class Counter extends Component {
 
    state = {
        counters: [
            { icon: "bookmark", title: "Invoices processed", start: Math.floor(((new Date()).getTime() - 1615704052000)/86400000) * 547 -50, end: Math.floor(((new Date()).getTime() - 1615704052000)/86400000) * 547 },
            { icon: "user-plus", title: "No. of Clients", start: 0, end: 8 },
            { icon: "shopping-cart", title: "Completed pick ups", start: Math.floor(((new Date()).getTime() - 1615704052000)/108000000) - 50, end: Math.floor(((new Date()).getTime() - 1615704052000)/108000000) },
            { icon: "award", title: "Suburbs covered", start: 2, end: 12 },
        ],
    }
    render() {
        return (
            <React.Fragment>
                <Row id="counter">
                    <CounterBox counters={this.state.counters} />
                </Row>
            </React.Fragment>
        );
    }
}

export default Counter;