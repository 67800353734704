import React, { useState } from 'react';
import { Container, Row, Col, Form, Input, Button, Label } from "reactstrap";
import Axios from "axios";

const Subscribe = () => {

    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [status, setStatus] = useState('READY')
    const [error, setError] = useState(null)

    const sendMessage = async () => {
        try {
            await Axios.post("/api/public/mail", { email: email, message: message});
            setStatus('SENT')
            setMessage('')
          } catch (err) {
            setError(err.response && err.response.data && err.response.data.length > 0 ? err.response.data[0].msg :  err.message)
            setStatus('ERROR')
            //console.log(err.response)
          }
    }


        return (
            <React.Fragment>
        <section className="section">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <div className="text-center mb-5">
                            <h5 className="text-primary text-uppercase small-title">Contact Us</h5>
                            <h4 className="mb-3">Got any Questions or Feedback?</h4>
                            <p>We will get back to you within 24hrs.</p>
                        </div>
                    </Col>

                    <Col xl={8} lg={10}>
                        <div className="text-center">
                            <div className="subscribe">
                                <Form>
                                    <Row>
                                        { status === "SENT" && <Col md={12}>
                                            <Label className="text-success">Sent Successfully!</Label>
                                        </Col>}
                                        { status === "ERROR" && <Col md={12}>
                                            <Label className="text-danger">{error ? error : 'Something went wrong'}</Label>
                                        </Col>}
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Input onChange={e => setMessage(e.target.value)} type="textarea" style={{ height: 200 }} name="text" id="message" placeholder="Write your question or feedback" value={message}/>
                                            </div>
                                        </Col>
                                        <Col md={9}>
                                            <div>
                                                <Input onChange={e => setEmail(e.target.value)} type="text" className="form-control" placeholder="Enter your E-mail address" value={email}/>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="mt-3 mt-md-0">
                                                <Button onClick={ m => sendMessage()} color="primary" block>Send</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
                
            </Container>
            
        </section>
            </React.Fragment>
        );
}

export default Subscribe;