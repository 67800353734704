import React, { useEffect, useState }  from "react";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import {useParams} from "react-router-dom";
import "./CreateBusiness.css"

import { Helmet } from 'react-helmet' 

import { logoutUser } from "../../auth/UserSlice";

import { TextField, Button, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import NavbarPage from "../../home/Navbar/Navbar_Page";


import { getBusinessCategories } from "../BusinessSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '500'
    },
  },
  pButton: {
    marginTop: '50px',
    marginBottom: '20px'
  },
  appbar: {
    marginTop: '20px'
  }
}));

const ErrorSection = styled.div`
  color: red;
`;

const PaperFormComponentContainer = styled.div`
  display: flex; 
  flex-direction: column
`;

const CreateBusiness = () => {
  const history = useHistory();
  const classes = useStyles();

  const dispatch = useDispatch();

  const userState = useSelector((state) => state.user);
  const { loggedInUser } = userState;

  const business = useSelector((state) => state.business);
  const { categoryList, loading, error } = business;
  const [ tempCategoryList, setTempCategoryList] = useState([]);

  const [ category, setCategory ] = useState('')

  const {firmId} = useParams()

  const [businessInfo, setBusinessInfo] = useState({
    firmId: firmId,
    type: 'abn',
    name: null,
    email : null,
    tfn: null,
    abn: null,
    category : null,
    address : null
  });

  const [formError, setFormError] = useState([]);

  const handleSubmit = async () => {

    if(category){
      let cat = categoryList.find( o => o.name.toLowerCase() === category.toLowerCase())
      if(cat){
        businessInfo['category'] = cat.id
      } else {
        businessInfo['category'] = category
      }
    }

    if(businessInfo['abn']){
      businessInfo['abn'] = businessInfo['abn'].replace(/\s/g,'')
    }

    try {
      await Axios.post("/api/business/create", businessInfo, {
        headers: { Authorization: "Bearer " + loggedInUser.token },
      });
      history.push("/dashboard");
    } catch (err) {
      if(err && err.response && err.response.status == 401 ){
        dispatch(logoutUser())
      }else {
        setFormError(err.response.data)
      }
    }
  };

  const findError = (errors, sectionName) => {
    const errorObj = errors.find((err) => err.param === sectionName);
    return errorObj ? errorObj.msg : null;
  };

  const handleBusinessInfoChange = (e) => {
    const { id, value } = e.target;
    setBusinessInfo((currentState) => ({
      ...currentState,
      [id]: value,
    }));
  };

  const handleAutoCompleteTyping = (e) => {
    const { id, value } = e.target;
    if(value && value.length > 0 && !categoryList.find(f => f.name.toLowerCase().startsWith(value))){
      setTempCategoryList([{id: -1, name: value}].concat(categoryList))
    }
    setCategory(value)
  };

  useEffect(() => {
    if (!loggedInUser) {
      history.push("/");
    }
    if (loggedInUser && categoryList.length === 0) {
      const userInfo = { token: loggedInUser.token };
      dispatch(getBusinessCategories(userInfo));
    }
  }, [loggedInUser, history]);

  useEffect(() => {
    if(categoryList.length > 0) {
      setBusinessInfo((currentState) => ({
        ...currentState,
        ['category']: categoryList[0].id,
      }));
      setTempCategoryList(categoryList)
    }
  }, [categoryList]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Create Business</title>
      </Helmet>
        {/* Importing Navbar */}
        <NavbarPage navItems={[
                { id: 1, idnm: "home", navheading: "Home", link:"/" }
            ]} navClass={document.documentElement.scrollTop} imglight={false} />

        <div>
          <div className="cb-container">
            {error && <ErrorSection>{findError(error, "userNotFound")}</ErrorSection>} 
            <Grid container justify="center" spacing={2} >
              <Paper className={classes.paper}>
    
                <PaperFormComponentContainer className={classes.form}>

                <Typography variant="h5" align="center" className={classes.appbar}>
                    ADD A CLIENT TO YOUR FIRM
                  </Typography>

                  {formError && <ErrorSection>{findError(formError, "name")}</ErrorSection>}

                  <TextField id="name" label="Business Name" variant="outlined" onChange={handleBusinessInfoChange} />
                  
                  {businessInfo.type === 'abn' && formError && <ErrorSection>{findError(formError, "abn")}</ErrorSection>}
                  { businessInfo.type === 'abn' && 
                    <TextField id="abn" label="ABN" variant="outlined" onChange={handleBusinessInfoChange} />
                  }
                

                  {businessInfo.type === 'tfn' &&  formError && <ErrorSection>{findError(formError, "tfn")}</ErrorSection>}
                  { businessInfo.type === 'tfn' && 
                    <TextField id="tfn" label="TFN" variant="outlined" onChange={handleBusinessInfoChange} />
                  }

                  {formError && <ErrorSection>{findError(formError, "email")}</ErrorSection>}
                  <TextField id="email" label="Business Email (optional)" variant="outlined" onChange={handleBusinessInfoChange} />
                  
                  {formError && <ErrorSection>{findError(formError, "address")}</ErrorSection>}
                  <TextField id="address" label="Business Address (optional)" variant="outlined" onChange={handleBusinessInfoChange} />
                  
                  {formError && <ErrorSection>{findError(formError, "category")}</ErrorSection>}
                  {tempCategoryList && tempCategoryList.length > 0 &&
                    <Autocomplete
                      id="combo-box-demo"
                      options={tempCategoryList}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField  onChange={handleAutoCompleteTyping} {...params} label="Business Category" variant="outlined" />}
                    />
                  }
                    <Button size="large" className={classes.pButton} variant="contained" color="primary" onClick={handleSubmit}>Add Client</Button>
                  </PaperFormComponentContainer>
              </Paper>
            </Grid>
          </div>
        </div>
    </React.Fragment>
  );


  /*
  return (
    
  );
  */
};

export default CreateBusiness;