
import React, { useEffect, useState }  from "react";
import { useHistory } from "react-router-dom";

import { Helmet } from 'react-helmet'
import NavbarPage from "../home/Navbar/Navbar_Page";

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Axios from "axios";

import "./Blog.css"


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: "#f00"
    },
    paper: {
      height: 240,
      width: 400,
      display:"inline-block"
    },
    control: {
      padding: theme.spacing(2),
    },
    random: {
        backgroundColor: "#ff0",
        textAlign : "center"
    },
    hero: {
        maxWidth: "100%",
        maxHeight: "100%"
    }
  }));

const BlogGrid = () => {

    const classes = useStyles();

    const [blogs, setBlogs] = useState([]);
    const [navClass, setNavClass] = useState("");
    const [pos, setPos] = useState(document.documentElement.scrollTop)

    const history = useHistory();

    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
        fetchBlogs()
        return function cleanup() {
          window.removeEventListener("scroll", scrollNavigation, true);
        };
      }, []);
    
      const scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > pos) {
          setNavClass("nav-sticky");
        }else {
          setNavClass("");  
        }
    };

    const fetchBlogs = async() => {
        try {
            const response = await Axios.get(`/api/public/blogs`);
            setBlogs(response.data)
          } catch (err){
            console.log(err.response)
          }  
    }

    const onClickPaper = async(path) =>{
        history.push(`/blog/${path}`);
    }

    return (
        <React.Fragment>
          <Helmet>
            <title>Blog</title>
          </Helmet>
          
          {/* Importing Navbar */}
          <NavbarPage navItems={[
                  { id: 1, idnm: "home", navheading: "Home", link:"/" }
              ]} navClass={"navbar-custom sticky sticky-dark " + navClass} imglight={false} hasBorder={true}/>
                
                <div className="shift-top pl-5 pr-5 pt-4">
                                <Grid container justify="center" justifyContent="center" spacing={2}>
                                    {blogs.map((value) => (
                                        <Grid key={value} item>
                                            <Paper className={classes.paper}  onClick={e => onClickPaper(value.path)}>
                                                <img className={classes.hero} src={value.hero}/>
                                                <div className="p-2"><h5>{value.title}</h5></div>
                                                <p className="ml-2">{value.date}</p>
                                            </Paper>
                                        </Grid>
                                    ))}
                            </Grid>
                </div>
        </React.Fragment>
    )
}


export default BlogGrid;