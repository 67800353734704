import React, { useEffect, useState }  from "react";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TextField, Button, Typography, Select, MenuItem, FormControl, InputLabel} from '@material-ui/core';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faSort } from '@fortawesome/free-solid-svg-icons'

import { makeStyles } from '@material-ui/core/styles';

import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../auth/UserSlice";

import "./ClientEmails.css"

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
    },
    scrollable: {
      overflow: 'scroll',
      height: '100%'
    }
  }));

  const columns = [
    { id: 'index', label: '', minWidth: 10 },
    { id: 'email', label: 'Email', minWidth: 40 , isSortable: true },
    { id: 'client', label: 'Client', minWidth: 60 },
    {
      id: 'delete',
      label: '',
      minWidth: 20,
      align: 'center'
    }
];

  const ClientEmails = ({firmId, clients}) => {
    const classes = useStyles();
    const [ selectedClient, setSelectedClient ] = useState(clients[0].id)
    const [ selectedRow, setSelectedRow] =  useState(0)
    const [ email, setEmail ] = useState(null)
    const [ emails, setEmails ] = useState([])

    const dispatch = useDispatch();

    const userState = useSelector((state) => state.user);
    const { loggedInUser } = userState;
  
    useEffect(() => {
        fetchEmails(firmId)
    }, []);
        
    const handleClientChange = (event) => {
        setSelectedClient(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const onRowSelected = (index) => {
        setSelectedRow(index)
    };

    const onDeleteSelected = async (index) => {
        let data = {
            "entryId" : emails[index].id
        }
        try {
            await Axios.post("/api/accountant/clientEmailAddress/delete", data, {
              headers: { Authorization: "Bearer " + loggedInUser.token },
            });
            fetchEmails()
          } catch (err) {
            if(err && err.response && err.response.status == 401 ){
              dispatch(logoutUser())
            }
          }
    };

    const onSortSelected = (id) => {
        
    };

    const addClientEmail = async () => {
        let data = {
            "email" : email,
            "clientId" : selectedClient,
            "firmId": firmId
        }
        try {
            await Axios.post("/api/accountant/addClientEmailAddress", data, {
              headers: { Authorization: "Bearer " + loggedInUser.token },
            });
            fetchEmails()
          } catch (err) {
            if(err && err.response && err.response.status == 401 ){
              dispatch(logoutUser())
            }
          }
    }

    const fetchEmails = async () => {
        try {
          const response = await Axios.get(`/api/accountant/clientEmailAddresses?firmId=${firmId}`, {
            headers: { Authorization: "Bearer " + loggedInUser.token },
          });
          setEmails(response.data)
        } catch (err){
          if(err && err.response && err.response.status == 401 ){
            dispatch(logoutUser())
          }
          console.log(err.response)
        }
      };
    

      return (
      <div>
            <div id="add_email">
                <TextField  className="email_field" label="Email" size="small" variant="outlined" onChange={handleEmailChange}  />
                <FormControl id="client_field" size="small" variant="outlined" className={classes.formControl}>
                    <InputLabel>Client</InputLabel>
                    <Select
                        size="small"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedClient}
                        onChange={handleClientChange}
                        label="Client"
                        >
                            { clients && clients.length > 0 && clients.map((value) => (
                                <MenuItem value={value.id}>{value.name}</MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <Button id="add_button"  variant="outlined" color="primary" onClick={addClientEmail}>
                    Add EMAIL
                </Button>
            </div>
            <div className="emails-table">
            <TableContainer className={classes.scrollable}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                    {column.isSortable && <FontAwesomeIcon className="ml-2" icon={faSort} onClick={ e => onSortSelected(column.id) }/>}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    {   emails && emails.map((row, index) => (
                            <TableRow key={row.index} className={selectedRow == index ? 'bg-selected-sc-group' : 'bg-normal-sc-group'} tabIndex={-1}  onClick={ e => onRowSelected(index)}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        >
                                            {
                                                (column.id == 'index' || column.id == 'email') && value
                                            }
                                            {
                                                column.id === 'client' && 
                                                <select name="client" id="client" value={row['clientId']}>
                                                    { clients && clients.length > 0 && clients.map((value) => (
                                                        <option value={value.id}>{value.name}</option>
                                                    ))}
                                                </select>
                                            }
                                            {
                                                column.id === 'delete' && <FontAwesomeIcon id={`${index}_${column.id}`} icon={faTrashAlt} onClick={ e => onDeleteSelected(index) }/>
                                            }
                                    </TableCell>)
                                })}
                            </TableRow>
                        ))
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
      </div>)
  }

  export default ClientEmails;