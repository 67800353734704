import React, { useEffect, useState }  from "react";
import Axios from "axios";
import {useParams, Redirect, useHistory} from "react-router-dom";
import Loading from 'react-fullscreen-loading';

const ImageRedirect = () => {

    let { imageId } = useParams()
    const history = useHistory();

    useEffect( async () => {
        console.log(imageId)
        let url = `/api/public/invoiceimage/${imageId}`
        try {
            let response = await Axios.get(url);
            window.location.assign(response.data);
        } catch (error) {
            return history.push("/404");
        }
    }, []);
      
    return (<Loading loading background="#e1f5f2" loaderColor="#68cebf"/>)
};

export default ImageRedirect;