import React from 'react';
import Dynamsoft from 'dwt';
import { Button, Input } from 'reactstrap';

export default class DWT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scanners: [],
            currentScanner: "Looking for devices..",
            images: [],
            scanStack: []
        };
    }
    DWObject = null;
    containerId = 'dwtcontrolContainer';
    width = "100%";
    height = "600";

    //https://www.dynamsoft.com/blog/insights/scan-settings-for-best-ocr-accuracy/
    /*
    For the usual font size (10 pts or above), usually 300 DPI is recommended. 
    For smaller font sizes 400 DPI, would be recommended.
    Color Modes: Black and White
    Among the document file types - uncompressed TIFF or PNG format.
    A medium brightness value of 50% is suitable in most cases
    */

    toImageBlob(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
            callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    componentWillUnmount(){
        if(this.DWObject){
            this.DWObject.CloseWorkingProcess()
        }
    }

    componentDidMount() {

        Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
            this.DWObject = Dynamsoft.DWT.GetWebTwain(this.containerId);
            //this.DWObject.LogLevel = 1;
            if (this.DWObject) {
                let vCount = this.DWObject.SourceCount;
                let sourceNames = [];
                for (let i = 0; i < vCount; i++)
                    sourceNames.push(this.DWObject.GetSourceNameItems(i));
                this.setState({ scanners: sourceNames });
                if(sourceNames.length > 0){
                    this.setState({ currentScanner: sourceNames[0] });
                }
                
            }
        });
        this.loadDWT();
    }
    loadDWT() {
        Dynamsoft.DWT.ProductKey = this.props.productKey;
        Dynamsoft.DWT.ResourcesPath = "../../dwt-resources";
        Dynamsoft.DWT.OnWebTwainPreExecute = () => {
            this.props.onLoadingStateChange(true)
        };
        Dynamsoft.DWT.OnWebTwainPostExecute =  () => {
            this.props.onLoadingStateChange(false)
        };
        Dynamsoft.DWT.Containers = [{ ContainerId: this.containerId, Width: this.width, Height: this.height }];
        let checkScriptLoaded = () => {
            if (Dynamsoft.Lib.detect.scriptLoaded) {
                this.modulizeInstallJS();
                Dynamsoft.DWT.Load();
            } else {
                setTimeout(() => {
                    checkScriptLoaded();
                }, 1000);
            }
        };
        checkScriptLoaded();
    }
    onSourceChange(value) {
        this.setState({ currentScanner: value });
    }

    OnAcquireImageSuccess = (() => {
        this.DWObject.CloseSource();
        if (this.DWObject.HowManyImagesInBuffer > 0) {
            var lImageUrl = []
            for (var i = 0; i < this.DWObject.HowManyImagesInBuffer; i++) {
                lImageUrl.push(this.DWObject.GetImageURL(i, 1200, 1200)) // TODO: Experiment here.
            }
            
            this.setState({ images: lImageUrl });
            console.log('On Acquire Image Success: Images found');
        } else {
            console.log('On Acquire Image Success: Image not found');
        }
    }).bind(this)
    

    OnAcquireImageFailure = ((code, error) => {
        this.props.onError(`On Acquire Failed ${code} ${error}`)
        this.DWObject.CloseSource();
        this.OnAcquireImageSuccess()
        if(this.state.scanStack.length > 0){
            if(this.state.scanStack[this.state.scanStack-1] <= this.DWObject.HowManyImagesInBuffer) {
                this.popStack()
            }
        }
    }).bind(this)

    async openSource(){
        this.DWObject.CloseSource();
        for (let i = 0; i < this.DWObject.SourceCount; i++) {
            if (this.DWObject.GetSourceNameItems(i) === this.state.currentScanner) {
                this.DWObject.SelectSourceByIndex(i);
                break;
            }
        }
        this.DWObject.OpenSource();
        //this.DWObject.IfShowUI = true;
        if(this.DWObject.ErrorCode != 0) {
            this.props.onError(this.DWObject.ErrorString)
            this.props.onLoadingStateChange(false)
            return
        }
        
        this.DWObject.PixelType = Dynamsoft.EnumDWT_PixelType.TWPT_GRAY
        this.DWObject.Resolution = 300

        if(this.DWObject.ErrorCode != 0) {
            this.props.onError(this.DWObject.ErrorString)
            this.props.onLoadingStateChange(false)
            return
        }
        this.DWObject.AcquireImage(this.OnAcquireImageSuccess, this.OnAcquireImageFailure);
    }

    acquireImage() {
        this.props.onLoadingStateChange(true)
        this.setState(prevState => ({
            scanStack: [...prevState.scanStack, this.DWObject.HowManyImagesInBuffer]
        }))
        setTimeout(() => { this.openSource() }, 10);
    }

    popStack(){
        var stackVals = [...this.state.scanStack];
        let lastValue = stackVals.pop()
        
        let imageLinks = [...this.state.images];
        imageLinks.length = lastValue

        this.setState({scanStack: stackVals, images: imageLinks});
        return lastValue
    }

    loadEmailAttachments(){
        alert("Loading emails")
    }

    loadImagesOrPDFs() {
        this.setState(prevState => ({
            scanStack: [...prevState.scanStack, this.DWObject.HowManyImagesInBuffer]
        }))
        this.DWObject.IfShowFileDialog = true;
        this.DWObject.Addon.PDF.SetResolution(200);
        this.DWObject.Addon.PDF.SetConvertMode(1/*this.Dynamsoft.DWT.EnumDWT_ConvertMode.CM_RENDERALL*/);
        this.DWObject.LoadImageEx("", 5 /*this.Dynamsoft.DWT.EnumDWT_ImageType.IT_ALL*/, () => {
            this.OnAcquireImageSuccess()
        }, (errorCode, errorString) => {
            if(this.state.scanStack.length > 0){
                if(this.state.scanStack[this.state.scanStack-1] <= this.DWObject.HowManyImagesInBuffer) {
                    this.popStack()
                }
            }
            this.props.onError(errorString)
        });
    }

    range(start, count) {
        return Array.apply(0, Array(count))
          .map((element, index) => index + start);
    }

    rescan(){
        if(this.state.scanStack.length > 0){
            let removeUpToIndex = this.popStack()
            if(removeUpToIndex == 0){
                this.DWObject.RemoveAllImages()
                this.setState({ images: [], scanStack: [] });
            }else{
                this.DWObject.SelectImages(this.range(removeUpToIndex,this.DWObject.HowManyImagesInBuffer - removeUpToIndex));
                this.DWObject.RemoveAllSelectedImages();
            }
        }
    }

    onDone(){
        this.props.done(this.state.images)
    }

    render() {
        return (
            <div id="dwt-holder" style={{ width: "30%", margin: "0 auto" }}>
                <Input type="select" style={{ width: "100%" }} tabIndex="1" value={this.state.currentScanner} onChange={(e) => this.onSourceChange(e.target.value)}>
                    {
                        this.state.scanners.length > 0 ?
                            this.state.scanners.map((_name, _index) =>
                                <option value={_name} key={_index}>{_name}</option>
                            )
                            :
                            <option value="Looking for devices..">Looking for devices..</option>
                    }
                </Input>
               
                {<div>
                    <Button  outline color="primary" size="sm" tabIndex="2" style={{ marginRight: "4%", width: "48%" }}
                        onClick={() => this.acquireImage()}
                        disabled={this.state.scanners.length > 0 ? "" : "disabled"}
                    >Scan</Button>
                    <Button outline color="primary" size="sm" tabIndex="3" style={{ margin: "2% 0", width: "48%" }}
                        onClick={() => this.loadImagesOrPDFs()}
                    >Load</Button>
                </div>}
                
                <div id={this.containerId}></div>
                { this.state.images && this.state.images.length > 0 &&<div style={{width: "100%" }}> { this.props.forVideo ? 143 : this.state.images.length  } invoice{this.state.images.length>1?'s':''} </div>}
                {this.state.images && this.state.images.length > 0 && <Button color="secondary" size="sm" tabIndex="2" style={{ marginRight: "4%", width: "48%" }}
                    onClick={() => this.rescan() }>Undo</Button>}
                {this.state.images && this.state.images.length > 0 && <Button color="primary" size="sm" tabIndex="3" style={{ margin: "2% 0", width: "48%" }}
                    onClick={() => this.onDone() }>Process Images</Button>}
            </div >
        );
    }
    modulizeInstallJS() {
        let _DWT_Reconnect = window.DWT_Reconnect;
        window.DWT_Reconnect = (...args) => _DWT_Reconnect.call({ Dynamsoft: Dynamsoft }, ...args);
        let __show_install_dialog = window._show_install_dialog;
        window._show_install_dialog = (...args) => __show_install_dialog.call({ Dynamsoft: Dynamsoft }, ...args);
        let _OnWebTwainOldPluginNotAllowedCallback = window.OnWebTwainOldPluginNotAllowedCallback;
        window.OnWebTwainOldPluginNotAllowedCallback = (...args) => _OnWebTwainOldPluginNotAllowedCallback.call({ Dynamsoft: Dynamsoft }, ...args);
        let _OnWebTwainNeedUpgradeCallback = window.OnWebTwainNeedUpgradeCallback;
        window.OnWebTwainNeedUpgradeCallback = (...args) => _OnWebTwainNeedUpgradeCallback.call({ Dynamsoft: Dynamsoft }, ...args);
        let _OnWebTwainPreExecuteCallback = window.OnWebTwainPreExecuteCallback;
        window.OnWebTwainPreExecuteCallback = (...args) => _OnWebTwainPreExecuteCallback.call({ Dynamsoft: Dynamsoft }, ...args);
        let _OnWebTwainPostExecuteCallback = window.OnWebTwainPostExecuteCallback;
        window.OnWebTwainPostExecuteCallback = (...args) => _OnWebTwainPostExecuteCallback.call({ Dynamsoft: Dynamsoft }, ...args);
        let _OnRemoteWebTwainNotFoundCallback = window.OnRemoteWebTwainNotFoundCallback;
        window.OnRemoteWebTwainNotFoundCallback = (...args) => _OnRemoteWebTwainNotFoundCallback.call({ Dynamsoft: Dynamsoft }, ...args);
        let _OnRemoteWebTwainNeedUpgradeCallback = window.OnRemoteWebTwainNeedUpgradeCallback;
        window.OnRemoteWebTwainNeedUpgradeCallback = (...args) => _OnRemoteWebTwainNeedUpgradeCallback.call({ Dynamsoft: Dynamsoft }, ...args);
        let _OnWebTWAINDllDownloadFailure = window.OnWebTWAINDllDownloadFailure;
        window.OnWebTWAINDllDownloadFailure = (...args) => _OnWebTWAINDllDownloadFailure.call({ Dynamsoft: Dynamsoft }, ...args);
    }
}