//https://developers.google.com/gtagjs/reference/event
//https://developers.google.com/gtagjs/reference/ga4-events

/*Events
login
purchase
select_item
sign_up
view_item
*/

function sendPageView(title){
  if( window && window.gtag ) {
      window.gtag('event', 'page_view', {
        page_location: window.location.href,
        page_path: window.location.pathname ,
        page_title: title
      });
  }
}

function sendScreenView(screen){
  if( window && window.gtag ) {
    window.gtag('event', 'screen_view', {
        screen_name: screen
    });
  }
}

function sendEvent(event){
  if( window && window.gtag ) {
    window.gtag('event', event);
  }
}

/*
event
Use the event command to send event data.


gtag('event', '<event_name>', {<event_params>});
<event_name> is either:

A recommended event. Each recommended event can take recommended parameters.
A custom event. A custom event is an arbitrary event name that you make up, with arbitrary (i.e. custom) parameters. For example, see how custom events are used in Google Analytics.
<event_params> is one or more parameter-value pairs. Each pair separated by a comma.

The following event command fires the recommended event screen_view with two parameters: app_name and screen_name.


gtag('event', 'screen_view', {
  'app_name': 'myAppName',
  'screen_name': 'Home'
});
*/

exports.sendPageView = sendPageView
exports.sendScreenView = sendScreenView
exports.sendEvent = sendEvent