import React, { useEffect, useState }  from "react";
import moment from 'moment'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faRedo, faUndo } from '@fortawesome/free-solid-svg-icons';

import './ScanGroupInvoiceViewer.css'

const ScanGroupInvoiceViewer = ({selectedImage, invoice, highlighterEnabled}) => {
    const data = invoice.parsedData && JSON.parse(invoice.parsedData);
    let size = data.size
    let totalRect = data.total_rect && invoice.total ===  data.total ? { 
        x: (data.total_rect.bl.x/size.w)*100, 
        y: (data.total_rect.bl.y/size.h)*100, 
        w: (data.total_rect.tr.x - data.total_rect.bl.x)*100/size.w, 
        h: (data.total_rect.tr.y - data.total_rect.bl.y)*100/size.h 
    } : null

    let invIdRect = data.invoice_id_rect && invoice.invoiceNumber ===  data.invoice_id ? { 
        x: (data.invoice_id_rect.bl.x/size.w)*100, 
        y:  (data.invoice_id_rect.bl.y/size.h)*100, 
        w: (data.invoice_id_rect.tr.x - data.invoice_id_rect.bl.x)*100/size.w, 
        h: (data.invoice_id_rect.tr.y - data.invoice_id_rect.bl.y)*100/size.h 
    } : null

    let invDaRect = data.invoice_paid_date_rect && invoice.paymentDate ===  moment(data.invoice_paid_date).toISOString() ? { 
        x: (data.invoice_paid_date_rect.bl.x/size.w)*100, 
        y:  (data.invoice_paid_date_rect.bl.y/size.h)*100, 
        w: (data.invoice_paid_date_rect.tr.x - data.invoice_paid_date_rect.bl.x)*100/size.w, 
        h: (data.invoice_paid_date_rect.tr.y - data.invoice_paid_date_rect.bl.y)*100/size.h 
    } : null

    let abnRect = data.abn_rect && invoice.supplier && invoice.supplier.abn === data.abn ? { 
        x: (data.abn_rect.bl.x/size.w)*100, 
        y:  (data.abn_rect.bl.y/size.h)*100, 
        w: (data.abn_rect.tr.x - data.abn_rect.bl.x)*100/size.w, 
        h: (data.abn_rect.tr.y - data.abn_rect.bl.y)*100/size.h 
    } : null

    let gstRect = data.gst_rect && invoice.gst === data.gst ? { 
        x: (data.gst_rect.bl.x/size.w)*100, 
        y:  (data.gst_rect.bl.y/size.h)*100, 
        w: (data.gst_rect.tr.x - data.gst_rect.bl.x)*100/size.w, 
        h: (data.gst_rect.tr.y - data.gst_rect.bl.y)*100/size.h 
    } : null

    let teSuRect = data.temp_supplier_name_rect && invoice.tempSupplierName === data.temp_supplier_name ? { 
        x: (data.temp_supplier_name_rect.bl.x/size.w)*100, 
        y:  (data.temp_supplier_name_rect.bl.y/size.h)*100, 
        w: (data.temp_supplier_name_rect.tr.x - data.temp_supplier_name_rect.bl.x)*100/size.w, 
        h: (data.temp_supplier_name_rect.tr.y - data.temp_supplier_name_rect.bl.y)*100/size.h 
    } : null

    const onLoad = (e)=>{
        let arr = [{ id:"total-highlighter", rect: totalRect } , { id:"gst-highlighter", rect: gstRect } , { id:"date-highlighter", rect: invDaRect}].filter(f => f.rect)
        arr.sort((a,b) => {
            return (a.rect.y+a.rect.h) - (b.rect.y+b.rect.h)
        })
        if(arr.length > 0){
            document.getElementById(arr[arr.length-1].id).scrollIntoView({block: "end"})
        }
    }

    const [rotation, setRotation] = useState(0)

    const onRotate = ()=> {
        setRotation((rotation+90)%360)
    }

    return (
    <div id="image-annot-container" className="two-sc-group">
        <div id="image-holder">
        { selectedImage && <img style={{transform: `rotate(${rotation}deg)`}} onLoad={onLoad} src={selectedImage} width="100%" height="auto"/>}
        { highlighterEnabled && totalRect && <div className="img-abs" style={{backgroundColor: '#ccff00', width: `${totalRect.w}%`, height: `${totalRect.h}%`, top: `${totalRect.y}%`, left: `${totalRect.x}%`}}></div>}
        { highlighterEnabled && totalRect && <div  id="total-highlighter"className="rect-abs" style={
            { 
                top: `${totalRect.y+totalRect.h+1}%`, 
                right: `${100 - totalRect.x +2}%`
            }
        }><b>Total: {data.total}</b></div>}

        { highlighterEnabled && invIdRect && <div className="img-abs" style={{backgroundColor: '#ccff00', width: `${invIdRect.w}%`, height: `${invIdRect.h}%`, top: `${invIdRect.y}%`, left: `${invIdRect.x}%`}}></div>}
        { highlighterEnabled && invIdRect && <div className="rect-abs" style={
            { 
                top: `${invIdRect.y+invIdRect.h+1}%`, 
                right: `${100 - invIdRect.x - invIdRect.w }%`
            }
        }><b>Inv No: {data.invoice_id}</b></div>}


        { highlighterEnabled && invDaRect && <div className="img-abs" style={{backgroundColor: '#ccff00', width: `${invDaRect.w}%`, height: `${invDaRect.h}%`, top: `${invDaRect.y}%`, left: `${invDaRect.x}%`}}></div>}
        { highlighterEnabled && invDaRect && <div id="date-highlighter" className="rect-abs" style={
            {
                top: `${invDaRect.y+invDaRect.h+1}%`, 
                right: `${100 - invDaRect.x- invDaRect.w}%`
            }
        }><b>Date: {moment(data.invoice_paid_date).format("DD-MMM-yyyy")}</b></div>}


        { highlighterEnabled && abnRect && <div className="img-abs" style={{backgroundColor: '#ccff00', width: `${abnRect.w}%`, height: `${abnRect.h}%`, top: `${abnRect.y}%`, left: `${abnRect.x}%`}}></div>}
        { highlighterEnabled && abnRect && <div className="rect-abs" style={
            {
                top: `${abnRect.y+abnRect.h+1}%`, 
                left: `${abnRect.x}%`
            }
        }><b>ABN: {data.abn}</b></div>}


        { highlighterEnabled && gstRect && <div id="gst-highlighter" className="img-abs" style={{backgroundColor: '#ccff00', width: `${gstRect.w}%`, height: `${gstRect.h}%`, top: `${gstRect.y}%`, left: `${gstRect.x}%`}}></div>}
        { highlighterEnabled && gstRect && <div className="rect-abs" style={
            {
                top: `${gstRect.y-gstRect.h-1}%`, 
                right: `${100-gstRect.x+1}%`
            }
        }><b>GST: {data.gst}</b></div>}


        { highlighterEnabled && teSuRect && <div className="img-abs" style={{backgroundColor: '#ccff00', width: `${teSuRect.w}%`, height: `${teSuRect.h}%`, top: `${teSuRect.y}%`, left: `${teSuRect.x}%`}}></div>}
        { highlighterEnabled && teSuRect && <div className="rect-abs" style={
            {
                top: `${teSuRect.y+teSuRect.h+1}%`, 
                left: `${teSuRect.x}%`
            }
        }><b>Supplier: {data.temp_supplier_name}</b></div>}
        

        { highlighterEnabled && <div className="div-horizontal"><FontAwesomeIcon className="mr-2" icon={faUndo} onClick={ e => onRotate(-90) }/><FontAwesomeIcon icon={faRedo} onClick={ e => onRotate(90) }/></div> }      

        </div>
   </div>
  )
}

export default ScanGroupInvoiceViewer;
